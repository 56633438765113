<template>
    <div class="modal fade " id="filtro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="background-color: #292994;">
                <div class="modal-header" style="border-color:#292994 !important">
                    <div class="offset-md-4">
                        <h5 class="modal-title title-font" id="exampleModalLabel" style="color:white;">Filtro da Tabela
                            Operação SGF</h5>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="padding:none">
                    <div class="col custom-background-blue-light card-replicar-trip" style="text-align:left">
                        <div class="form-check form-filter">
                            <div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox1"
                                        value="option1" data-column="col1" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox1">TERMINAL</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox2"
                                        value="option1" data-column="col2" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox2">LINHA</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox3"
                                        value="option1" data-column="col3" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox3">VIAGEM</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox4"
                                        value="option1" data-column="col4" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox4">TIPO</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox5"
                                        value="option1" data-column="col5" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox5">POSIÇÃO</label>
                                </div>
                            </div>
                            <div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox6"
                                        value="option1" data-column="col6" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox6">VEICULO
                                        PREV</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox7"
                                        value="option1" data-column="col7" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox7">VEICULO
                                        REAL</label>
                                </div>

                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox8"
                                        value="option1" data-column="col8" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox8">MOTORISTA
                                        PREV</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro ">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox9"
                                        value="option1" data-column="col9" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox9">MOTORISTA
                                        REAL</label>
                                </div>

                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox10"
                                        value="option1" data-column="col10" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox10">COBRADOR
                                        PREV</label>
                                </div>
                            </div>
                            <div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox11"
                                        value="option1" data-column="col11" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox11">COBRADOR
                                        REAL</label>
                                </div>

                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox12"
                                        value="option1" data-column="col12" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox12">SENTIDO</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox13"
                                        value="option1" data-column="col13" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox13">INÍCIO.
                                        PREV.</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox14"
                                        value="option1" data-column="col14" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox14">INÍCIO.
                                        REAL</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox15"
                                        value="option1" data-column="col15" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox15">FIM.
                                        PREV</label>
                                </div>
                            </div>
                            <div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox16"
                                        value="option1" data-column="col16" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox16">FIM.
                                        REAL.</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox17"
                                        value="option1" data-column="col17" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox17">TEMPO DE
                                        VIAGEM PREVISTA</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox18"
                                        value="option1" data-column="col18" checked>
                                    <label class="form-check-label label-filter text-weight" for="inlineCheckbox18">TEMPO DE
                                        VIAGEM REALIZADA</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox19"
                                        value="option1" data-column="col19" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox19">PROGRESSO</label>
                                </div>
                                <div class="form-check form-check-inline form-check-filtro">
                                    <input class="form-check-input checkbox-filter form-check-input-filtro" type="checkbox" id="inlineCheckbox20"
                                        value="option1" data-column="col20" checked>
                                    <label class="form-check-label label-filter text-weight"
                                        for="inlineCheckbox20">VNR</label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer" style="padding:0 !important">
                    <div style="margin: auto">
                        <button @click="filterColumns()" type="button"
                            class="btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen"
                            data-bs-dismiss="modal" aria-label="Close">Aplicar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './../../../assets/css/operacoes/filtro.css';
import http from '../../../services/http.js';
import { Tooltip } from 'bootstrap'

export default {
    name: 'filtro',
    data: () => {
        return {
            hiddenColumns: [],
        }
    },
    mounted() {
        this.emitter.on('FiltersColumns', (data) => {
            console.log(data)
            this.HiddenColumnsSocket(data)
        })
    },
    methods: {
        filterColumns() {
            this.hiddenColumns = [];
            $("table td, table th").show();
            $("input[type='checkbox']:not(:checked)").each((index, element) => {
                var column = $(element).attr("data-column");
                this.hiddenColumns.push(column);
                $("td[data-column='" + column + "'], th[data-column='" + column + "'], th[data-column='" + column + "']").hide();
            });
            this.hiddenColumns = this.hiddenColumns.filter(function (element) { return element !== undefined; });
            this.emitter.emit('socketFiltersColumns', this.hiddenColumns);
        },
        HiddenColumnsSocket(hiddenColumns) {
            $("table td, table th").show();
            for (var i = 0; i < hiddenColumns.length; i++) {
                var column = hiddenColumns[i];
                $("td[data-column='" + column + "'], th[data-column='" + column + "'], th[data-column='" + column + "']").hide();
            }
        }
    }
}
</script>