<template>
    <div class="modal fade" id="viagensLinhas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        style="z-index: 9990;">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content custom-background-blue-light shadow-light">
                <div class="modal-header" align="center" style="border: none; display: block;">
                    <div style="text-align: center;">
                        <h5 class="modal-title title-font custom-color-text-green" id="exampleModalLabel">Edição de Escala
                        </h5>
                        <div class="btn-group text-center">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-expanded="false" style="background: none; color: #fff">{{
                                    first_line }}</button>
                            <ul class="dropdown-menu text-center" aria-labelledby="dropdownMenuButton">
                                <li v-for="line in lines" :key="line.linha_id">
                                    <a v-if="first_line != line.nome_linha" class="dropdown-item"
                                        @click="showLine(line.nome_linha)">{{ line.nome_linha }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal-body justify-content-center text-center" id="style-scroll"
                    style="padding: none; background-color: #17175f">
                    <table class="table text-white table-viagensLinha"
                        style="width: 100%; display: table; table-layout: fixed;">
                        <thead class="table-head-viagens-linha ">
                            <th>Posição</th>
                            <th>Viagem</th>
                            <th>Sentido</th>
                            <th>Atividade</th>
                            <th>Atendimento</th>
                            <th>Hora Saída</th>
                            <th>Hora Chegada</th>
                            <th>Veículo</th>
                            <th>Motorista</th>
                            <th>Cobrador</th>
                        </thead>
                        <tbody class="tbody-viagens-linha" v-for="trip in filter_trips" :key="trip.id"
                            :class="{ 'Active': showTrip[trip.showId] }">

                            <div style="display: grid; width: 100%;">
                                <tr class="tr-viagens-linha" style="display: inline-flex;">
                                    <td class="td-viagenslinhas">
                                        <div class="card-position-viagem-linha">
                                            {{ trip.posicao }}
                                        </div>
                                    </td>
                                    <td class="td-viagenslinhas">
                                        <div style="display: inline-flex;">
                                            <div style="margin-left: -30%;">
                                                <button type="button" class="btn" @click="showRown(trip)"
                                                    style="display: inline-flex; margin-right: -40%; margin-top: 20%;">

                                                    <svg width="10" height="12" viewBox="0 0 13 15" fill="none"
                                                        v-if="!showTrip[trip.showId]" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.7502 1.68945L11.2854 0.224609L6.50024 4.96094L1.71509 0.22461L0.250243 1.68945L6.50024 7.93945L12.7502 1.68945ZM12.7502 8.52539L11.2854 7.06055L6.50024 11.8457L1.71509 7.06055L0.250244 8.52539L6.50024 14.7754L12.7502 8.52539Z"
                                                            fill="white" />
                                                    </svg>
                                                    <svg width="10" height="12" viewBox="0 0 13 15" fill="none"
                                                        v-if="showTrip[trip.showId]" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M12.7502 1.68945L11.2854 0.224609L6.50024 4.96094L1.71509 0.22461L0.250243 1.68945L6.50024 7.93945L12.7502 1.68945ZM12.7502 8.52539L11.2854 7.06055L6.50024 11.8457L1.71509 7.06055L0.250244 8.52539L6.50024 14.7754L12.7502 8.52539Z"
                                                            fill="#A2D624" />
                                                    </svg>

                                                </button>
                                            </div>
                                            <div style="margin-left: -5px;">
                                                <button type="button" class="btn" @click="ShowModalIncluirViagem(trip)">
                                                    <svg width="16" height="16" viewBox="0 0 21 21" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M11.5256 5.27539L9.47485 5.27539L9.47485 9.47461L5.27563 9.47461L5.27563 11.5254L9.47485 11.5254L9.47485 15.7246L11.5256 15.7246L11.5256 11.5254L15.7249 11.5254L15.7249 9.47461L11.5256 9.47461L11.5256 5.27539ZM3.17603 17.873C1.12524 15.8223 0.0998531 13.3646 0.099853 10.5C0.0998529 7.63542 1.12524 5.19401 3.17602 3.17578C5.19425 1.125 7.63566 0.0996098 10.5002 0.0996097C13.3648 0.0996095 15.8225 1.125 17.8733 3.17578C19.8915 5.19401 20.9006 7.63542 20.9006 10.5C20.9006 13.3646 19.8915 15.8223 17.8733 17.873C15.8225 19.8913 13.3648 20.9004 10.5002 20.9004C7.63566 20.9004 5.19425 19.8913 3.17603 17.873Z"
                                                            fill="white" />
                                                    </svg>
                                                </button>
                                            </div>

                                            <div style="margin-left: -10%;">
                                                <button type="button" class="btn" @click="deletePosition(trip)">
                                                    <svg width="15" height="19" viewBox="0 0 15 19" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M14.7756 1.15039L14.7756 3.25L0.224853 3.25L0.224853 1.15039L3.83813 1.15039L4.91235 0.125L10.0881 0.125L11.1624 1.15039L14.7756 1.15039ZM1.25024 16.7754L1.25024 4.27539L13.7502 4.27539L13.7502 16.7754C13.7502 17.3288 13.5387 17.8171 13.1155 18.2402C12.6923 18.6634 12.204 18.875 11.6506 18.875H3.34985C2.79647 18.875 2.30819 18.6634 1.88501 18.2402C1.46183 17.8171 1.25024 17.3288 1.25024 16.7754Z"
                                                            fill="white" />
                                                    </svg>

                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="td-viagenslinhas-top"></td>
                                    <td class="td-viagenslinhas-top"></td>
                                    <td class="td-viagenslinhas-top"></td>
                                    <td class="td-viagenslinhas-top"></td>
                                    <td class="td-viagenslinhas-top"></td>
                                    <div class="td-viagenslinhas-top" style="margin-right: 0.5%;">
                                        <v-select class="card-drivers-viagem-linha" v-model="assets_id[trip.showId]"
                                            :options="vehicles" label="alias" menu-props="{ offset: true, left: true }"
                                            @update:modelValue="updateDriversVehicles(trip, trip.showId, 1)">
                                        </v-select>
                                    </div>
                                    <div class="td-viagenslinhas-top" style="margin-right: 0.5%;">
                                        <v-select class="card-drivers-viagem-linha" v-model="drivers_id[trip.showId]"
                                            :options="drivers" label="alias" menu-props="{ offset: true, left: true }"
                                            @update:modelValue="updateDriversVehicles(trip, trip.showId, 2)">
                                        </v-select>
                                    </div>
                                    <div class="td-viagenslinhas-top">
                                        <v-select class="card-drivers-viagem-linha" v-model="cobradores_id[trip.showId]"
                                            :options="cobradores" label="alias" menu-props="{ offset: true, left: true }"
                                            @update:modelValue="updateDriversVehicles(trip, trip.showId, 3)">
                                        </v-select>
                                    </div>
                                </tr>
                            </div>
                            <div v-for="(aux, index) in trip.trips" :key="aux"
                                :class="{ 'Desative': !showTrip[trip.showId], 'Last': index === trip.trips.length - 1 }">
                                <div v-for="(attr, atributo, index) in aux.trips" v-bind:key="attr" class="trip-item">
                                    <div v-if="showTrip[trip.showId]"
                                        style="display: grid; width: 102.5%;margin-left: -1%; margin-top: -1%;"
                                        v-bind:class="{ 'last-item': index === aux.trips.length - 1 }">
                                        <tr style="vertical-align: middle; display: inline-table;" class="tr-viagens-linha">
                                            <td class="td-viagenslinhas" style="width: 10%;">
                                                <div style="width: 120%;">
                                                    <button v-if="!updateItinerary[attr[0].id]" type="button" class="btn"
                                                        @click="updateItinerary[attr[0].id] = !updateItinerary[attr[0].id]">
                                                        <svg width="14" height="15" viewBox="0 0 19 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M0.500243 3.98438L11.5159 3.98438L11.5159 6L0.500243 6L0.500243 3.98438ZM0.500243 2.01563L11.5159 2.01563L11.5159 0L0.500243 0L0.500243 2.01563ZM0.500243 9.98438H7.48462L7.48462 8.01563H0.500243L0.500243 9.98438ZM15.5002 6.89063L16.2034 6.14063C16.4221 5.95313 16.6565 5.85938 16.9065 5.85938C17.1877 5.85938 17.4221 5.95313 17.6096 6.14063L18.3596 6.89063C18.5471 7.07813 18.6409 7.3125 18.6409 7.59375C18.6409 7.84375 18.5471 8.07813 18.3596 8.29688L17.6096 9L15.5002 6.89063ZM14.7971 7.59375L9.50024 12.8906L9.50024 15H11.6096L16.9065 9.70313L14.7971 7.59375Z"
                                                                fill="white" />
                                                        </svg>
                                                    </button>
                                                    <button v-else class="btn" @click="editItinerary(attr)">
                                                        <svg width="14" height="15" viewBox="0 0 19 15" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M0.500243 3.98438L11.5159 3.98438L11.5159 6L0.500243 6L0.500243 3.98438ZM0.500243 2.01563L11.5159 2.01563L11.5159 0L0.500243 0L0.500243 2.01563ZM0.500243 9.98438H7.48462L7.48462 8.01563H0.500243L0.500243 9.98438ZM15.5002 6.89063L16.2034 6.14063C16.4221 5.95313 16.6565 5.85938 16.9065 5.85938C17.1877 5.85938 17.4221 5.95313 17.6096 6.14063L18.3596 6.89063C18.5471 7.07813 18.6409 7.3125 18.6409 7.59375C18.6409 7.84375 18.5471 8.07813 18.3596 8.29688L17.6096 9L15.5002 6.89063ZM14.7971 7.59375L9.50024 12.8906L9.50024 15H11.6096L16.9065 9.70313L14.7971 7.59375Z"
                                                                fill="#A2D624" />
                                                        </svg>
                                                    </button>
                                                    <button type="button" class="btn" @click="showModalAlterarLinha(attr)">
                                                        <svg width="16" height="15" viewBox="0 0 21 20" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M16.1721 7.51563L9.09399 14.5938L4.82837 10.375L6.28149 8.96875L9.09399 11.7813L14.7659 6.10938L16.1721 7.51563ZM2.48462 10C2.48462 8.84375 2.71899 7.76563 3.18774 6.76563C3.68774 5.73438 4.32837 4.85938 5.10962 4.14063L7.50024 6.48438L7.50024 0.484375L1.50024 0.484375L3.70337 2.6875C2.73462 3.59375 1.95337 4.6875 1.35962 5.96875C0.797118 7.21875 0.515868 8.5625 0.515868 10C0.515868 11.3125 0.734618 12.5469 1.17212 13.7031C1.64087 14.8281 2.28149 15.8438 3.09399 16.75C3.90649 17.625 4.85962 18.3438 5.95337 18.9062C7.04712 19.4688 8.23462 19.8125 9.51587 19.9375L9.51587 17.9219C8.51587 17.7969 7.59399 17.5156 6.75024 17.0781C5.90649 16.6094 5.15649 16.0156 4.50024 15.2969C3.87524 14.5781 3.37524 13.7813 3.00024 12.9063C2.65649 12 2.48462 11.0313 2.48462 10ZM20.4846 10C20.4846 8.6875 20.2502 7.46875 19.7815 6.34375C19.344 5.1875 18.719 4.17188 17.9065 3.29688C17.094 2.39063 16.1409 1.65625 15.0471 1.09375C13.9534 0.53125 12.7659 0.1875 11.4846 0.0625L11.4846 2.07813C12.4846 2.20313 13.4065 2.5 14.2502 2.96875C15.094 3.40625 15.8284 3.98438 16.4534 4.70313C17.1096 5.42188 17.6096 6.23438 17.9534 7.14063C18.3284 8.01563 18.5159 8.96875 18.5159 10C18.5159 11.1563 18.2659 12.25 17.7659 13.2813C17.2971 14.2813 16.6721 15.1406 15.8909 15.8594L13.5002 13.5156L13.5002 19.5156H19.5002L17.2971 17.3125C18.2659 16.4063 19.0315 15.3281 19.594 14.0781C20.1877 12.7969 20.4846 11.4375 20.4846 10Z"
                                                                fill="white" />
                                                        </svg>


                                                    </button>
                                                </div>
                                                <div style="width: 120%;">
                                                    <button type="button" class="btn"
                                                        @click="showModalAdicionarEvento(attr)">
                                                        <svg width="14" height="16" viewBox="0 0 19 21" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M16.4846 18.9844L16.4846 8.01563L2.51587 8.01563L2.51587 18.9844L16.4846 18.9844ZM13.4846 0.984375L15.5002 0.984375L15.5002 3H16.4846C17.0159 3 17.4846 3.20313 17.8909 3.60938C18.2971 4.01563 18.5002 4.48438 18.5002 5.01563L18.5002 18.9844C18.5002 19.5156 18.2971 19.9844 17.8909 20.3906C17.4846 20.7969 17.0159 21 16.4846 21L2.51587 21C1.95337 21 1.46899 20.8125 1.06274 20.4375C0.687744 20.0312 0.500244 19.5469 0.500244 18.9844L0.500243 5.01563C0.500243 4.48438 0.687743 4.01563 1.06274 3.60938C1.46899 3.20313 1.95337 3 2.51587 3H3.50024L3.50024 0.984375L5.51587 0.984375L5.51587 3L13.4846 3L13.4846 0.984375ZM14.5159 12L14.5159 17.0156H9.50024L9.50024 12L14.5159 12Z"
                                                                fill="white" />
                                                        </svg>
                                                    </button>
                                                    <button v-if="attr.length < 2" type="button" class="btn"
                                                        @click="deleteTrip(attr)">
                                                        <svg width="12" height="16" viewBox="0 0 15 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M14.7756 1.15039L14.7756 3.25L0.224853 3.25L0.224853 1.15039L3.83813 1.15039L4.91235 0.125L10.0881 0.125L11.1624 1.15039L14.7756 1.15039ZM1.25024 16.7754L1.25024 4.27539L13.7502 4.27539L13.7502 16.7754C13.7502 17.3288 13.5387 17.8171 13.1155 18.2402C12.6923 18.6634 12.204 18.875 11.6506 18.875H3.34985C2.79647 18.875 2.30819 18.6634 1.88501 18.2402C1.46183 17.8171 1.25024 17.3288 1.25024 16.7754Z"
                                                                fill="white" />
                                                        </svg>
                                                    </button>
                                                    <button v-else type="button" class="btn"
                                                        @click="toggleMenu(attr[0].id)">
                                                        <svg width="12" height="16" viewBox="0 0 15 19" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M14.7756 1.15039L14.7756 3.25L0.224853 3.25L0.224853 1.15039L3.83813 1.15039L4.91235 0.125L10.0881 0.125L11.1624 1.15039L14.7756 1.15039ZM1.25024 16.7754L1.25024 4.27539L13.7502 4.27539L13.7502 16.7754C13.7502 17.3288 13.5387 17.8171 13.1155 18.2402C12.6923 18.6634 12.204 18.875 11.6506 18.875H3.34985C2.79647 18.875 2.30819 18.6634 1.88501 18.2402C1.46183 17.8171 1.25024 17.3288 1.25024 16.7754Z"
                                                                fill="white" />
                                                        </svg>
                                                    </button>

                                                    <ul v-if="showMenu[attr[0].id]" class="menuDeleteFather">
                                                        <li v-for="option in menuOptions" class="menuDelete"
                                                            :key="option.id"
                                                            @click="handleOptionClick(option, attr, attr[0].id)">
                                                            {{ option.label }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <td style="font-size: 32px; font-weight: bold; width: 10%;"
                                                v-if="atributo == 'viagemregular' || atributo == 'viagemextra'"> {{
                                                    attr[0].viagem }} </td>
                                            <td style="font-size: 32px; font-weight: bold; width: 10%;" v-else></td>
                                            <div v-for="dado in attr" :key="dado.id" class="trip-item">
                                                <div
                                                    style="display: flex; align-items: center; justify-content: center; width: 100%; margin-top: 1%;">
                                                    <td class="td-viagenslinhas" style="margin-left: -1.9%;"> {{
                                                        dado.tipo_percurso }}
                                                    </td>
                                                    <td class="td-viagenslinhas"> {{ dado.atividade }} </td>
                                                    <td v-if="!updateItinerary[attr[0].id]"
                                                        style="width: 16.5%; overflow: hidden; text-overflow: ellipsis;padding-left: 15px; height: 15px; text-align: start;">
                                                        {{ dado.atendimento }}</td>
                                                    <td class="td-viagenslinhas" v-else>
                                                        <select name="" id="" class="form-select form-select-viagens-linha"
                                                            v-model="selectedItinerary[dado.id]" v-if="dado.sentido == 'i'">
                                                            <option :value="itinerario.id"
                                                                v-for="itinerario in itinerariosIda" :key="itinerario.id">{{
                                                                    itinerario.nome }}
                                                            </option>
                                                        </select>
                                                        <select name="" id="" class="form-select form-select-viagens-linha"
                                                            v-model="selectedItinerary[dado.id]" v-else>
                                                            <option :value="itinerario.id"
                                                                v-for="itinerario in itinerariosVolta" :key="itinerario.id">
                                                                {{ itinerario.nome }}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td class="td-viagenslinhas">
                                                        {{ new Date(dado.previsao_hora_saida).toLocaleTimeString('pt-BR', {
                                                            hour: '2-digit',
                                                            minute: '2-digit'
                                                        }) }}</td>
                                                    <td class="td-viagenslinhas">
                                                        {{ new Date(dado.previsao_hora_chegada).toLocaleTimeString('pt-BR',
                                                            {
                                                                hour: '2-digit',
                                                                minute: '2-digit'
                                                            }) }}
                                                    </td>
                                                    <div class="td-viagenslinhas-top" style="margin-right: 0.5%;">
                                                        <v-select class="card-drivers-viagem-linha"
                                                            v-model="selectedVehicle[dado.id]" :options="vehicles"
                                                            label="alias"
                                                            @focusout="ShowModalReplicar(dado.id, dado.quadro_horario_id, dado.viagem, dado.posicao, dado.data, 'asset', dado.previsao_hora_saida, dado.data)"
                                                            menu-props="{ offset: true, left: true }">
                                                        </v-select>
                                                    </div>
                                                    <div class="td-viagenslinhas-top" style="margin-right: 0.5%;">
                                                        <v-select class="card-drivers-viagem-linha"
                                                            v-model="selectedDriver[dado.id]" :options="drivers"
                                                            label="alias"
                                                            @focusout="ShowModalReplicar(dado.id, dado.quadro_horario_id, dado.viagem, dado.posicao, dado.data, 'driver', dado.previsao_hora_saida, dado.data)"
                                                            menu-props="{ offset: true, left: true }">
                                                        </v-select>
                                                    </div>
                                                    <div class="td-viagenslinhas-top" style="margin-right: -0.7%;">
                                                        <v-select class="card-drivers-viagem-linha"
                                                            v-model="selectedCobrador[dado.id]" :options="cobradores"
                                                            label="alias"
                                                            @focusout="ShowModalReplicar(dado.id, dado.quadro_horario_id, dado.viagem, dado.posicao, dado.data, 'cobrador', dado.previsao_hora_saida, dado.data)"
                                                            menu-props="{ offset: true, left: true }">
                                                        </v-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </tr>
                                    </div>
                                </div>
                            </div>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer" style="padding: 0 !important">
                    <div style="margin: auto">
                        <button @click="filterColumns()" type="button"
                            class="btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen"
                            data-bs-dismiss="modal" aria-label="Close">Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "../../../../assets/css/operacoes/edicao-escalas/ViagensLinha.css";
// import './../../../assets/css/operacoes/edicao-escalas/filtro-linhas.css';
import http from "../../../../services/http.js";
import { Tooltip } from "bootstrap";

export default {
    name: "viagensLinhas",
    data: () => {
        return {
            data: null,
            orgid: null,
            linesObject: [],
            data: null,
            lines: [],
            trips: [],
            filter_trips: [],
            nome_lines: [],
            first_line: null,
            updateItinerary: [],
            itinerarios: [],
            itinerariosIda: [],
            itinerariosVolta: [],
            selectedItinerary: [],
            drivers: [],
            vehicles: [],
            showMenu: [],
            selectedVehicle: [],
            selectedDriver: [],
            selectedCobrador: [],
            contadorAux: 0,
            menuOptions: [
                { id: 1, label: "Ida" },
                { id: 2, label: "Volta" },
                { id: 3, label: "Ambos" },
            ],
            showTrip: [],
            linha_id: null,
            drivers_id: [],
            assets_id: [],
            cobradores_id: [],
            padrao: false
        };
    },
    mounted() {
        this.emitter.on("ViagensLinhas", (data) => {
            if (data[4]) this.padrao = data[4];
            this.linesObject = data[3];
            this.data = data[2];
            this.orgid = data[1];
            this.lines = data[0].nome_linhas;
            this.trips = data[0].dados;
            this.first_line = this.lines[0].nome_linha;
            this.filter_trips = [];
            this.filterTrips();
            this.setDados(data[0])
            this.showTrip = [];
            this.getDrivesAndVehicles();
            this.filterDriversAndVehicles();
            $("#viagensLinhas").modal("show");
        });

        this.emitter.on("updateViagensLinhas", (data) => { this.getLines() });
    },
    methods: {
        setDados(data) {
            this.itinerarios = [];
            this.itinerariosIda = [];
            this.itinerariosVolta = [];
            this.linha_id = data.nome_linhas[0].linha_id;
            http.get('operation/itinerary', {
                params: {
                    orgid: this.orgid,
                    linha_id: this.linha_id
                }
            }).then(response => {
                this.itinerarios = response.data.dados;
                this.itinerariosIda = [],
                    this.itinerariosVolta = [],
                    this.itinerarios.forEach(itinerario => {
                        const destino = itinerario.sentido === "i" ? this.itinerariosIda : this.itinerariosVolta;
                        destino.push(itinerario);
                    });
            }).catch(error => {
                console.error(error)
            });
        },
        showLine(nome_linha) {
            this.first_line = nome_linha;
            this.filter_trips = [];
            this.filterTrips();
        },
        filterTrips() {
            let cont = 1;
            this.filter_trips = [];
            for (const posicao in this.trips) {
                if (this.trips.hasOwnProperty(posicao)) {
                    const tripsArray = this.trips[posicao];
                    const filteredTrips = tripsArray.filter((e) => {
                        return this.first_line === e.nome_linha;
                    }).map((e) => {
                        Object.keys(e.trips).forEach(key => {
                            const value = e.trips[key];
                            if (Array.isArray(value)) {
                                value.forEach(item => {
                                    const { asset_id, driver_id, cobrador_id, id } = item;
                                    if (this.vehicles && this.vehicles.length > 0) {
                                        const vehiclesWithIds = this.vehicles.filter(asset => asset.id != null);
                                        const selectedVehicle = vehiclesWithIds.find(asset => asset.id === asset_id);
                                        selectedVehicle && (this.selectedVehicle[item.id] = selectedVehicle);
                                    }

                                    if (this.drivers && this.drivers.length > 0) {
                                        const driversWithIds = this.drivers.filter(driver => driver.id != null);
                                        const selectedDriver = driversWithIds.find(driver => driver.id === driver_id);
                                        selectedDriver && (this.selectedDriver[item.id] = selectedDriver);
                                    }
                                    cobrador_id != null && (this.selectedCobrador[id] = cobrador_id);
                                });
                            }
                        });
                        return e;
                    });
                    if (filteredTrips.length > 0) {
                        const groupedTrips = {
                            showId: cont - 1,
                            posicao: posicao,
                            drivers_ids: Array.from(new Set(filteredTrips.flatMap(data => data.drivers_id))),
                            assets_ids: Array.from(new Set(filteredTrips.flatMap(data => data.assets_id))),
                            cobradores_ids: Array.from(new Set(filteredTrips.flatMap(data => data.cobradores_id))),
                            trips: filteredTrips
                        };
                        cont += 1;
                        this.filter_trips.push(groupedTrips);
                    }
                }
            }
            for (let i = 0; i < this.filter_trips.length; i++) { this.filter_trips[i].show = false; }
            this.filterDriversAndVehicles();
        },

        showRown(trip) {
            this.showTrip[trip.showId] = !this.showTrip[trip.showId];
        },
        showModalAlterarLinha(data) {
            this.emitter.emit("alterarViagem", [data, this.orgid, this.padrao]);
        },
        showModalAdicionarEvento(data) {
            const driver = this.drivers.find(driver => driver.id == data[0].driver_id);
            const asset = this.vehicles.find(asset => asset.id == data[0].asset_id);
            this.emitter.emit("eventoescala", [data, this.orgid, driver, asset]); 
        },
        editItinerary(data) {
            let listaObjetos = [];
            let ids = [];
            for (let i = 0; i < data.length; i++) {
                let objeto = {
                    trip_id: data[i].id,
                    posicao: data[i].posicao,
                    asset_id: data[i].asset_id,
                    driver_id: data[i].driver_id,
                    cobrador_id: data[i].cobrador_id,
                    id_itinerario: this.selectedItinerary[data[i].id],
                };
                listaObjetos.push(objeto);
                ids.push(data[i].id);
            }
            http.post('operation/edit/trips', { orgid: this.orgid, trips: listaObjetos })
                .then(response => {
                    this.$toast.success(`Salvo com sucesso!`);
                    this.getLines(ids);
                }).catch(error => console.error(error));
            this.updateItinerary[data[0].id] = !this.updateItinerary[data[0].id];
        },
        getLines() {

            if (this.padrao == true) {
                http.get('scales/selected/lines/standard', {
                    params: {
                        orgid: this.orgid,
                        data: this.data,
                        lines: this.linesObject
                    }
                }).then(response => {
                    this.trips = response.data.dados;
                    this.filterTrips();
                }).catch(error => console.error(error));
            } else {
                http.get('operation/selected/lines', {
                    params: {
                        orgid: this.orgid,
                        data: this.data,
                        lines: this.linesObject
                    }
                }).then(response => {
                    this.trips = response.data.dados;
                    this.filterTrips();
                }).catch(error => console.error(error));
            }


        },
        deleteTrip(data) {
            let trips = [];
            if (data.length > 1) {
                for (let i = 0; i < data.length; i++) {
                    trips.push(data[i].id,);
                }
            } else {
                trips[0] = data[0].id;
            }
            http.post('operation/remove/trips', { orgid: this.orgid, trips: trips })
                .then(response => {
                    this.$toast.success(`Excluido com sucesso!`);
                    this.getLines();
                }).catch(error => console.error(error));
        },
        deletePosition(data) {
            const trips = data.trips.flatMap(data => data.trip_ids);
            http.post('operation/remove/trips', { orgid: this.orgid, trips: trips })
                .then(response => {
                    this.$toast.success(`Posição excluida com sucesso!`);
                    this.getLines();
                }).catch(error => console.error(error));
        },
        getDrivesAndVehicles() {
            http.get('operation/filters', {
                params: {
                    orgid: this.orgid
                }
            }).then(res => {
                this.vehicles = res.data.vehicles;
                this.drivers = res.data.drivers;
            }).catch(err => console.log(err));
        },
        ShowModalIncluirViagem(data) {
            this.emitter.emit('incluirViagemEscala', [data, this.orgid, this.data, this.drivers, this.vehicles]);
        },
        toggleMenu(id) {
            this.showMenu[id] = !this.showMenu[id];
        },
        handleOptionClick(option, trip, id) {
            this.showMenu[id] = !this.showMenu[id];
            if (option.id === 3) {
                this.deleteTrip(trip);
            }
            if (option.id === 2) {
                const tripObj = { trips: [] };
                trip.forEach(e => {
                    if (e.sentido === "v") {
                        tripObj.trips.push(e);
                    }
                });
                this.deleteTrip(tripObj.trips);
            }
            if (option.id === 1) {
                const tripObj = { trips: [] };
                trip.forEach(e => {
                    if (e.sentido === "i") {
                        tripObj.trips.push(e);
                    }
                });
                this.deleteTrip(tripObj.trips);
            }
        },
        ShowModalReplicar(item_id, id_quadro_horario, viagem, posicao, data, value, start_time, data_trip) {
            start_time = moment(start_time).format('HH:mm');
            var asset_id = null;
            var registration_number = this.selectedVehicle[item_id];
            if (registration_number != null) {
                this.vehicles.forEach(e => {
                    if (registration_number.alias == e.alias) asset_id = e.id
                    if (registration_number == e.alias) asset_id = e.id
                });
            }

            var driver_id = null;
            var employee_number_driver = this.selectedDriver[item_id];
            if (employee_number_driver != null) {
                this.drivers.forEach(e => {
                    if (employee_number_driver.alias == e.alias) driver_id = e.id
                    if (employee_number_driver == e.alias) driver_id = e.id
                });
            }

            var nodriver_id = null;
            var employee_number_nodriver = this.selectedCobrador[item_id];
            if (employee_number_nodriver != null) {
                this.drivers.forEach(e => {
                    if (employee_number_nodriver == e.alias && e.tipo == 'cobrador') nodriver_id = e.id
                    if (employee_number_nodriver == e.alias) nodriver_id = e.id
                });
            }

            var old_asset_id = null;
            if (asset_id == null) {
                this.filter_trips.forEach(e => {
                    if (item_id == e.item_id) old_asset_id = e.old_asset_id;
                });

                if (old_asset_id != null) {
                    this.vehicles.forEach(e => {
                        if (e.alias == old_asset_id) old_asset_id = e.id;
                    })
                }
            }

            var data = {
                id_empresa: this.orgid,
                id_quadro_horario: id_quadro_horario,
                number_trip: viagem,
                position: posicao,
                data: data,
                asset_id: asset_id != null ? asset_id : '',
                old_asset_id: old_asset_id,
                employee_number_driver: driver_id != null ? driver_id : '',
                employee_number_nodriver: nodriver_id != null ? nodriver_id : '',
                start_time: start_time,
                data: data_trip,
                escala: true
            }
            this.showModalReplicarConfirm(data);
        },
        showModalReplicarConfirm(data) {
            if (this.orgid != null) this.emitter.emit('replicarConfirm', data);
        },
        filterDriversAndVehicles() {
            this.assets_id = [];
            this.drivers_id = [];
            this.cobradores_id = [];
            this.filter_trips.forEach(posicao => {
                if (posicao.assets_ids.length == 1) {
                    if (this.vehicles && this.vehicles.length > 0) {
                        const vehiclesWithIds = this.vehicles.filter(asset => asset.id != null);
                        this.assets_id[posicao.showId] = vehiclesWithIds.find(asset => asset.id === posicao.assets_ids[0]);
                    }
                } else {
                    this.assets_id[posicao.showId] = '';
                }
                if (posicao.drivers_ids.length == 1) {
                    if (this.drivers && this.drivers.length > 0) {
                        const driversWithIds = this.drivers.filter(asset => asset.id != null);
                        this.drivers_id[posicao.showId] = driversWithIds.find(drivers => drivers.id === posicao.drivers_ids[0]);
                    }
                } else {
                    this.drivers_id[posicao.showId] = '';
                }
                if (posicao.cobradores_ids.length == 1) {
                    this.cobradores_id[posicao.showId] = posicao.cobradores_ids[0];
                } else {
                    this.drivers_id[posicao.showId] = '';
                }
            });
        },
        updateDriversVehicles(trip, id, value) {
            let listaObjetos = [];
            let tripIds = [].concat(...trip.trips.map(trip => trip.trip_ids));
            if (value === 1) {
                tripIds.forEach(trip_id => {
                    let objeto = {
                        trip_id: trip_id,
                        asset_id: this.assets_id[id].id,
                    };
                    listaObjetos.push(objeto);
                });
            }
            if (value === 2) {
                tripIds.forEach(trip_id => {
                    let objeto = {
                        trip_id: trip_id,
                        driver_id: this.drivers_id[id].id,
                    };
                    listaObjetos.push(objeto);
                });
            }
            if (value === 3) {
                tripIds.forEach(trip_id => {
                    let objeto = {
                        trip_id: trip_id,
                        cobrador_id: this.cobradores_id[id].id,
                    };
                    listaObjetos.push(objeto);
                });
            }

            http.post('operation/edit/trips', { orgid: this.orgid, trips: listaObjetos })
                .then(response => {
                    this.$toast.success(`Alterações salvas com sucesso!`);
                    this.emitter.emit('updateViagensLinhas');
                }).catch(error => {
                    console.error(error)
                    this.$toast.error(`Dados não salvos.`);
                });
        },
    },
    computed: {
        vehiclesWithIds() {
            return this.vehicles.filter(asset => asset.id != null)
        },
        driversWithIds() {
            return this.drivers.filter(driver => driver.id != null)
        }
    },
    watch: {
        vehiclesWithIds: {
            handler(vehicles) {
                this.filterTrips()
            }
        },
        driversWithIds: {
            handler(drivers) {
                this.filterTrips()
            }
        }
    },
};
</script>