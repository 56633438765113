<template>
    <div class="modal fade " id="cadastroeedicaodetrechos" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" style="z-index: 9999">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content modal-edicao-cadastro-trecho">
                <div class="modal-header text-center custom-background-blue-light modal-header-edicao-cadastro-trecho">
                    <div class="text-left">
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M31.969 24.375V7.96875C31.969 6.53125 31.594 5.21875 30.844 4.03125C30.1565 2.78125 29.219 1.8125 28.0315 1.125C26.844 0.375 25.5002 0 24.0002 0C22.5002 0 21.1565 0.375 19.969 1.125C18.7815 1.8125 17.8127 2.78125 17.0627 4.03125C16.3752 5.21875 16.0315 6.53125 16.0315 7.96875V28.0312C16.0315 29.0938 15.6252 30.0312 14.8127 30.8438C14.0627 31.5938 13.1252 31.9688 12.0002 31.9688C10.8752 31.9688 9.90649 31.5938 9.09399 30.8438C8.34399 30.0312 7.96899 29.0938 7.96899 28.0312V11.625C9.15649 11.1875 10.1252 10.4688 10.8752 9.46875C11.6252 8.46875 12.0002 7.3125 12.0002 6C12.0002 4.875 11.719 3.875 11.1565 3C10.6565 2.0625 9.93774 1.34375 9.00024 0.84375C8.12524 0.28125 7.12524 0 6.00024 0C4.87524 0 3.84399 0.28125 2.90649 0.84375C2.03149 1.34375 1.31274 2.0625 0.750244 3C0.250244 3.875 0.000244141 4.875 0.000244141 6C0.000244141 7.3125 0.375244 8.46875 1.12524 9.46875C1.87524 10.4688 2.84399 11.1875 4.03149 11.625V28.0312C4.03149 29.4688 4.37524 30.8125 5.06274 32.0625C5.81274 33.25 6.78149 34.2188 7.96899 34.9688C9.15649 35.6562 10.5002 36 12.0002 36C13.5002 36 14.844 35.6562 16.0315 34.9688C17.219 34.2188 18.1565 33.25 18.844 32.0625C19.594 30.8125 19.969 29.4688 19.969 28.0312V7.96875C19.969 6.90625 20.344 6 21.094 5.25C21.9065 4.4375 22.8752 4.03125 24.0002 4.03125C25.1252 4.03125 26.0627 4.4375 26.8127 5.25C27.6252 6 28.0315 6.90625 28.0315 7.96875V24.375C26.844 24.75 25.8752 25.4688 25.1252 26.5312C24.3752 27.5312 24.0002 28.6875 24.0002 30C24.0002 31.125 24.2502 32.1562 24.7502 33.0938C25.3127 33.9688 26.0315 34.6875 26.9065 35.25C27.844 35.75 28.8752 36 30.0002 36C31.1252 36 32.1252 35.75 33.0002 35.25C33.9377 34.6875 34.6565 33.9688 35.1565 33.0938C35.719 32.1562 36.0002 31.125 36.0002 30C36.0002 28.6875 35.6252 27.5312 34.8752 26.5312C34.1252 25.5312 33.1565 24.8125 31.969 24.375Z"
                                fill="white" />
                        </svg>

                    </div>
                    <div class="w-100">
                        <h5 class="h-title-header-edicao-cadastro-trecho">Cadastro de Trechos</h5>
                        <p class="p-title-header-edicao-cadastro-trecho" v-if="this.comprimento">Comprimento de <strong>{{this.comprimento}}Km</strong></p>
                    </div>
                </div>
                <div class="modal-body custom-background-blue-dark modal-body-edicao-cadastro-trecho">
                    <div class="container-map-trechos">
                        <GMapMap :center="center" :zoom="zoom" map-type-id="terrain" class="map-from-trechos"
                            ref="myMapRef">
                            <GMapPolyline v-if="directions" :path="directions" :clickable="false" ref="mapPolygon"
                                :options="{ strokeColor: 'green' }" />
                        </GMapMap>
                    </div>
                    <div class="row w-100 container-inputs-group-edicao-cadastro-trecho">
                        <div class="col-6 text-left">
                            <h5 class="h5-title-inputs-edicao-cadastro-trecho">Informações do trecho:</h5>
                            <div class="input-group mb-3 container-inputs-edicao-cadastro-trecho">
                                <input type="text" class="form-control input-edicao-cadastro-trecho"
                                    placeholder="Nome do Trecho:" aria-describedby="button-addon2" v-model="selectedName">
                            </div>
                            <div class="input-group mb-3 container-inputs-edicao-cadastro-trecho">
                                <v-select class="select-edicao-cadastro-trecho" placeholder="Ponto Inicial:"
                                    :options="pontosFirst" align="start" label="nome"
                                    style="max-width: 88%; border-top-left-radius: 5px; border-bottom-left-radius: 5px; height: 40px;"
                                    aria-describedby="button-addon2" v-model="selectedFirstPoint"
                                    @update:modelValue="getItinerarysByPoints()" @input="onInputFirstPoint">
                                </v-select>
                                <button class="btn btn-inputs-edicao-cadastro-trecho" type="button" id="button-addon2">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            <div class="input-group mb-3 container-inputs-edicao-cadastro-trecho">
                                <v-select class="select-edicao-cadastro-trecho" placeholder="Ponto Final:"
                                    :options="pontosFinal" align="start" label="nome"
                                    style="max-width: 88%; border-top-left-radius: 5px; border-bottom-left-radius: 5px; height: 40px;"
                                    aria-describedby="button-addon2" v-model="selectedFinalPoint"
                                    @update:modelValue="getItinerarysByPoints()" @input="onInputFinalPoint">
                                </v-select>
                                <button class="btn btn-inputs-edicao-cadastro-trecho" type="button" id="button-addon2">
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-6">
                            <h5 class="h5-title-inputs-edicao-cadastro-trecho">Itinerários: </h5>
                            <div class="w-100 container-itinerarios-edicao-cadastro-trecho">
                                <div class="card-group-itinerarios-edicao-cadastro-trecho"
                                    id="style-scroll-edicao-cadastro-trecho">
                                    <div class="cards-itinerarios-edicao-cadastro-trecho"
                                        v-for="itinerario, index in itinerarios" :key="itinerario">
                                        <div class="form-check checkbox-itinerarios-edicao-cadastro-trecho">
                                            <input class="form-check-input checkbox-input-edicao-cadastro-trecho"
                                                type="checkbox" value="" :id=itinerario.id
                                                @change="setRouteItinerary(itinerario)">
                                            <label class="form-check-label label-input-edicao-cadastro-trecho"
                                                :for="'checkbox-' + index">
                                                {{ itinerario.nome }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="modal-footer custom-background-blue-light justify-content-center text-center d-flex align-items-center justify-content-center">
                    <button type="button" @click="saveTrecho"
                        class="btn-footer-edicao-cadastro-trecho btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen">
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import '../../../../assets/css/operacoes/cadastro-de-trechos/Cadastro-e-edicao-de-trechos.css';
import http from '../../../../services/http.js';
import { ref, onMounted } from "vue";
const myMapRef = ref();
const mapPolygon = ref();
import haversineDistance from 'haversine-distance';

export default {

    name: 'filtroLinhas',
    data: () => {
        return {
            orgid: null,
            tipoModal: null,
            firstSelection: true,
            allUnselected: true,
            center: { lat: -8.032592566235502, lng: -34.92029196674506 },
            directions: null,
            zoom: 13,
            itinerarios: [],
            pontos: [],
            pontosTrecho: [],
            selectedFirstPoint: null,
            selectedFinalPoint: null,
            selectedName: null,
            trechoId: null,
            comprimento: null,
            inputFirstPoints: null,
            inputFinalPoints: null,
            pontosFirst: [],
            pontosFinal: [],
            selectedItinerarios: [],
        };
    },

    mounted() {
        this.emitter.on('cadastroeedicaodetrechos', (data) => {
            this.orgid = data[0];
            this.tipoModal = data[1];
            this.cleanModal()
            if (this.tipoModal == `edit`) { this.setModalFromEdit(data[2]) }
            $('#cadastroeedicaodetrechos').modal('show');
        })
    },
    methods: {
        setRouteItinerary(data) {
            const index = this.selectedItinerarios.indexOf(data)
            if (index === -1) {
                this.selectedItinerarios.push(data)
            } else {
                this.selectedItinerarios.splice(index, 1)
            }
            this.selectedItinerarios.forEach((itinerario, index) => {
                itinerario.ordem = index + 1;
            })
            if (this.allUnselected) {
                this.firstSelection = true;
            }
            this.allUnselected = false;
            if (this.firstSelection) {
                this.directions = null;
                this.getPointsFirstItinerary(data);
                this.firstSelection = false;
            }
            const allUnselected = document.querySelectorAll('input[type="checkbox"]:checked').length === 0;
            if (allUnselected) {
                this.resetRouteItinerary();
            }
        },
        resetRouteItinerary() {
            this.directions = null;
            this.allUnselected = true;
            this.firstSelection = true;
        },

        getPointsFirstItinerary(itinerario) {
            http.get('itinerarios/rota-entre-pontos', { params: { orgid: this.orgid, itinerario_id: itinerario.id, ponto_inicial_id: this.selectedFirstPoint.id, ponto_final_id: this.selectedFinalPoint.id } })
                .then(res => {
                    this.directions = res.data.map(objeto => ({
                        lat: Number(objeto.lat),
                        lng: Number(objeto.lng)
                    }));;
                    this.center = {
                        lat: this.directions[this.directions.length / 2].lat,
                        lng: this.directions[this.directions.length / 2].lng
                    }
                    this.comprimento = (this.directions.slice(0, -1).reduce((total, current, index) => {
                        const next = this.directions[index + 1];
                        return (total + haversineDistance([current.lat, current.lng], [next.lat, next.lng]));
                    }, 0) / 1000).toFixed(2);
                    itinerario.comprimento = this.comprimento;
                }).catch(err => console.error(err));
        },
        saveTrecho() {
            if (this.orgid != null && this.selectedName  != null &&  this.selectedFirstPoint.id  != null &&  this.selectedFinalPoint.id  != null && this.selectedItinerarios){
            if (this.tipoModal === 'save') {
                http.post('trechos',
                    {
                        orgid: this.orgid,
                        nome: this.selectedName,
                        ponto_inicial_id: this.selectedFirstPoint.id,
                        ponto_final_id: this.selectedFinalPoint.id,
                        itinerarios: this.selectedItinerarios,
                    })
                    .then(res => {
                        this.emitter.emit('reloadTrecho')
                        $('#cadastroeedicaodetrechos').modal('hide');

                    }).catch(err => console.error(err));
            } else {
                http.put('trechos/' + this.techoId,
                    {
                            orgid: this.orgid,
                            nome: this.selectedName,
                            ponto_inicial_id: this.selectedFirstPoint.id,
                            ponto_final_id: this.selectedFinalPoint.id,
                            itinerarios: this.selectedItinerarios,
                    })
                    .then(res => {
                        this.emitter.emit('reloadTrecho')
                        $('#cadastroeedicaodetrechos').modal('hide');
                    }).catch(err => console.error(err));
            }
        }
        },
        setModalFromEdit(data) {
            this.selectedFirstPoint = data.ponto_inicial;
            this.selectedFinalPoint = data.ponto_final;
            this.selectedName = data.nome;
            this.techoId = data.id;
            this.selectedItinerarios = data.itinerarios;
            this.getItinerarysByPoints(data.itinerarios);
            this.getPointsFirstItinerary(data.itinerarios[0]);

        },
        cleanModal() {
            this.selectedFirstPoint = null;
            this.selectedFinalPoint = null;
            this.selectedItinerarios = [];
            this.itinerarios = null,
            this.selectedName = null;
            this.directions = null;
            this.techoId = null;
        },
        getPoints(type) {
            var termo;
            if (type == 'first') { termo = this.inputFirstPoints }
            else { termo = this.inputFinalPoints }
            http.get('pontosparada/buscar', { params: { orgid: this.orgid, termo: termo } })
                .then(res => {
                    if (type == 'first') { this.pontosFirst = res.data; }
                    else { this.pontosFinal = res.data; }
                }).catch(err => console.error(err));
        },
        onInputFirstPoint(value) {
            this.inputFirstPoints = value.target.value;
            if (this.inputFirstPoints.length == 3) { this.getPoints('first') }
        },
        onInputFinalPoint(value) {
            this.inputFinalPoints = value.target.value;
            if (this.inputFinalPoints.length == 3) { this.getPoints('final') }
        },
        getItinerarysByPoints(data) {
            if (this.selectedFinalPoint != null && this.selectedFirstPoint != null && this.selectedFinalPoint != '' && this.selectedFirstPoint != '') {
                const checkboxes = document.querySelectorAll('input[type="checkbox"]');
                checkboxes.forEach((checkbox) => {
                    checkbox.checked = false;
                });
                http.get('itinerarios/entre-pontos/', { params: { orgid: this.orgid, ponto_inicial_id: this.selectedFirstPoint.id, ponto_final_id: this.selectedFinalPoint.id } })
                    .then(res => {
                        this.itinerarios = res.data;
                        if (this.tipoModal == 'edit') { (setTimeout(() => { this.checkCheckboxesEdit(data) }, 1000)) }
                    }).catch(err => console.error(err));
            }
        },
        checkCheckboxesEdit(data) {
            const allCheckboxes = document.querySelectorAll('.checkbox-input-edicao-cadastro-trecho[type="checkbox"]');
            allCheckboxes.forEach(checkbox => {
                if (data.some(itinerario => itinerario.id == checkbox.id)) {
                    checkbox.checked = true;
                }
            });
        }
    }
}
</script>