<template>
    <div class="modal fade modal-custom" id="replicarConfirm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 9999"> 
        <div class="modal-dialog  modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="border: 0.1px solid #292994; background: #292994;">
                <div class="modal-header">
                    <!-- <div class="col-md-10 offset-md-1">
                        <h5 class="modal-title title-font" id="exampleModalLabel">Salvar Alteração</h5>
                    </div> -->
                    <button type="button"  data-bs-dismiss="modal" aria-label="Close" class="btn-close">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.49219 23.5078C8.88281 25.8516 11.7188 27.0234 15 27.0234C18.2812 27.0234 21.0938 25.8516 23.4375 23.5078C25.8281 21.1172 27.0234 18.2812 27.0234 15C27.0234 11.7187 25.8281 8.90625 23.4375 6.5625C21.0938 4.17188 18.2812 2.97656 15 2.97656C11.7188 2.97656 8.88281 4.17188 6.49219 6.5625C4.14844 8.90625 2.97656 11.7187 2.97656 15C2.97656 18.2812 4.14844 21.1172 6.49219 23.5078ZM4.38281 4.45312C7.33594 1.5 10.875 0.0234375 15 0.0234375C19.125 0.0234375 22.6406 1.5 25.5469 4.45312C28.5 7.35937 29.9766 10.875 29.9766 15C29.9766 19.125 28.5 22.6641 25.5469 25.6172C22.6406 28.5234 19.125 29.9766 15 29.9766C10.875 29.9766 7.33594 28.5234 4.38281 25.6172C1.47656 22.6641 0.0234375 19.125 0.0234375 15C0.0234375 10.875 1.47656 7.35937 4.38281 4.45312ZM18.8672 9.02344L20.9766 11.1328L17.1094 15L20.9766 18.8672L18.8672 20.9766L15 17.1094L11.1328 20.9766L9.02344 18.8672L12.8906 15L9.02344 11.1328L11.1328 9.02344L15 12.8906L18.8672 9.02344Z" fill="white"/>
                        </svg>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-10 offset-md-1">
                            <h5 class="modal-title title-font" id="exampleModalLabel">Salvar Alteração</h5>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-10 offset-md-1">
                            <p>Deseja salvar ou replicar viagem?</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10 offset-md-1">
                            <button type="button" class="btn btn-confirm" @click="replicarSave()">Replicar</button>
                            <button type="button" class="btn btn-confirm" @click="salvar()">Salvar</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import  './../../../assets/css/operacoes/replicar-confirm.css';
    import http from '../../../services/http.js';
    // import { Tooltip } from 'bootstrap'

    export default {
        name: 'replicarConfirm',
        data: () => {
            return {
                id_empresa: null,
                req: {
                    id_empresa: '',
                    id_quadro_horario: '',
                    number_trip: '',
                    position: '',
                    data: '',
                    asset_id: '', 
                    old_asset_id: '',
                    employee_number_driver: '', 
                    employee_number_nodriver: '',
                    start_time: '',
                    data: ''
                },
                trip_edited: {
                    id_quadro_horario: '',
                    asset_id: '',
                    cobrador_id: '',
                    driver_id: '',
                    item_id: '',
                    old_asset_id: '',
                    old_cobrador_id: '',
                    old_driver_id: '',
                    start_time: '',
                },
                replicar_control: false,
                escala: false
            }
        },
        mounted() {
            this.emitter.on('replicarConfirm', data => {
                this.escala = data.escala;
                this.req = data;
                $('#replicarConfirm').modal('show');
            })
        },
        methods: {
            salvar() {
                var data = this.req;
                http.post('/operation/update/trips', data)
                    .then(res => {
                        if (res.data.status == true) {
                            var update_trips = res.data.dados;  
                            
                            var processar = res.data.processar;

                            this.$toast.success(`Salvo com sucesso!`);                        
                            // depois de salvar enviando os dados para realizar o update na table
                            this.emitter.emit('updateTrips', [update_trips, processar]);                             
                           
                            if(this.escala){this.emitter.emit('updateViagensLinhas');}                           

                            $('#replicarConfirm').modal('hide');

                            if (this.replicar_control) {
                                // realizando ação para envio de consulta no replicar
                                // this.trip_edited.asset_id = update_trips[0].asset_id;
                                // this.trip_edited.cobrador_id = update_trips[0].cobrador_id;
                                // this.trip_edited.old_driver_id = update_trips[0].old_driver_id;
                                
                                this.trip_edited.orgid = this.req.id_empresa;
                                // this.trip_edited.old_asset_id = this.req.old_asset_id != null ? this.req.old_asset_id : update_trips[0].old_asset_id; 
                                this.trip_edited.old_asset_id = update_trips[0].old_asset_id != null ? update_trips[0].old_asset_id : this.req.old_asset_id; // usado na consulta
                                // this.trip_edited.old_asset_id = update_trips[0].old_asset_id; // usado na consulta
                                this.trip_edited.start_time = this.req.start_time;
                                this.trip_edited.data = this.req.data;
                                this.trip_edited.id_quadro_horario = this.req.id_quadro_horario;
                                this.trip_edited.item_id = update_trips[0].item_id;
                                // usado em update
                                this.trip_edited.asset_id = update_trips[0].asset_id; 
                                this.trip_edited.driver_id = update_trips[0].driver_id; 
                                this.trip_edited.cobrador_id = update_trips[0].cobrador_id; 

                                this.showModalReplicarList();
                                this.replicar_control = false;
                            } //else this.$swal('Salvo com sucesso.');

                            
                        }else this.$swal('Não foi possível salvar.');
                    }).catch(err => console.log(err));
            },
            replicarSave() {
                // console.log("testing");
                this.replicar_control = true;                

                $('#replicarConfirm').modal('hide');

                // salvar a viagem editada
                this.salvar();
            },
            showModalReplicarList() {
                var data = this.trip_edited;
                // console.log("trip edited confirm", data);
                // disparando ação para modal de replicar
                if (this.trip_edited.start_time != '') this.emitter.emit('replicarList', [data, this.escala]); 
            }
        }
    }
</script>