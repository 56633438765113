import axios from 'axios'

let api_prod = 'https://api.operacoes.mobs2system.com/api/';
let api_dev = 'https://api.operacoes.mobs2system.com/api/';
let api_dev_local = 'http://localhost:8000/api/';

let redirectIfNotLogged_prod = 'https://m2.mobs2system.com';
let redirectIfNotLogged_local = 'http://localhost:8080'

const http = axios.create({ baseURL: api_dev })

http.interceptors.request.use((config) => {
  var token = localStorage.getItem('token')

  if (token) {
    // config.headers.Authorization = `Bearer ${token}`
    let jwtData = token.split('.')[1];
    let decodedJwtJson = window.atob(jwtData);
    let decodedJwt = JSON.parse(decodedJwtJson);
  
    console.log(Date.now() >= decodedJwt.exp * 1000);
  
    if (!(Date.now() >= decodedJwt.exp * 1000)) {
      config.headers.Authorization = `Bearer ${token}`
    }
  } else localStorage.removeItem('token')  

  return config
}, (err) => {
  return Promise.reject(err)
})

http.interceptors.response.use((response) => {
  return response
}, (error) => {

  console.log("check error", http)

  if (error.response.status === 401) {
    window.location.href = redirectIfNotLogged_local
  }

  return Promise.reject(error)
})

export default http