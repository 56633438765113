<template>
  <div class="modal fade modal-custom" id="terminal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    style="color: #fff">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="justify-content-center text-center" align="center" style="margin-left: 43.5%">
            <h5 class="modal-title" id="exampleModalLabel">Terminal</h5>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="overflow: hidden">
          <div class="responsive-modal-vehicles" style="max-height: 580px; overflow: auto; overflow-x: hidden; margin-top: -2%;" id="style-scroll-terminal">
            <div class="justify-content-center text-center" v-for="terminal in terminais" :key="terminal.id_ponto"
              style="width: 90%; margin-left: 5%">
              <div class="justify-content-center text-center" style="display: flex; margin-top: 6%">
                <hr class="line-terminal-left" />
                <h5>{{ terminal.nome }}</h5>
                <hr class="line-terminal-right" />
              </div>
              <div :class="{ 'primeira-fileira': index < numColunas }" v-for="veiculo in locations"
                :key="veiculo.id_ponto" @click="veiculo.showMsg = !veiculo.showMsg" style="display: inline-block">
                <div class="card-terminal" v-if="terminal.id_ponto == veiculo.id_ponto">
                  <div class="mensagem" :class="{
                      msgBlue: veiculo.previsao_hora_saida != '',
                      msgGreen: veiculo.previsao_hora_saida == '',
                    }" v-if="veiculo.showMsg">
                    <div v-if="!veiculo.previsao_hora_saida">
                      <p style="margin: 0"><strong>Ultima Posição:</strong></p>
                      <p style="margin: 0">{{ veiculo.data }} {{ veiculo.time }}</p>
                      <p style="margin: 0">Sem Linha</p>
                    </div>
                    <div v-if="veiculo.previsao_hora_saida">
                      <p style="margin: 0"><strong>Ultima Posição:</strong></p>
                      <p style="margin: 0">{{ veiculo.data }} {{ veiculo.time }}</p>
                      <p style="margin: 0">Linha: {{ veiculo.codigo_linha }}</p>
                    </div>
                  </div>
                  <svg width="20" height="24" viewBox="0 0 34 38" xmlns="http://www.w3.org/2000/svg"
                    v-if="veiculo.previsao_hora_saida == ''">
                    <path
                      d="M29 18.0313V8.00003H5.00003V18.0313H29ZM23.8438 29.1875C24.4063 29.75 25.125 30.0313 26 30.0313C26.875 30.0313 27.5938 29.75 28.1563 29.1875C28.7188 28.5625 29 27.8438 29 27.0313C29 26.2188 28.7188 25.5313 28.1563 24.9688C27.5938 24.3438 26.875 24.0313 26 24.0313C25.125 24.0313 24.4063 24.3438 23.8438 24.9688C23.2813 25.5313 23 26.2188 23 27.0313C23 27.8438 23.2813 28.5625 23.8438 29.1875ZM5.84378 29.1875C6.40628 29.75 7.12503 30.0313 8.00003 30.0313C8.87503 30.0313 9.59378 29.75 10.1563 29.1875C10.7188 28.5625 11 27.8438 11 27.0313C11 26.2188 10.7188 25.5313 10.1563 24.9688C9.59378 24.3438 8.87503 24.0313 8.00003 24.0313C7.12503 24.0313 6.40628 24.3438 5.84378 24.9688C5.28128 25.5313 5.00003 26.2188 5.00003 27.0313C5.00003 27.8438 5.28128 28.5625 5.84378 29.1875ZM0.968781 27.9688V8.00003C0.968781 4.81253 2.34378 2.68753 5.09378 1.62503C7.84378 0.562531 11.8125 0.0312805 17 0.0312805C22.1875 0.0312805 26.1563 0.562531 28.9063 1.62503C31.6563 2.68753 33.0313 4.81253 33.0313 8.00003V27.9688C33.0313 29.7188 32.3438 31.2188 30.9688 32.4688V36.0313C30.9688 36.5938 30.7813 37.0625 30.4063 37.4375C30.0313 37.8125 29.5625 38 29 38H27.0313C26.4688 38 25.9688 37.8125 25.5313 37.4375C25.1563 37.0625 24.9688 36.5938 24.9688 36.0313V33.9688H9.03128V36.0313C9.03128 36.5938 8.81253 37.0625 8.37503 37.4375C8.00003 37.8125 7.53128 38 6.96878 38H5.00003C4.43753 38 3.96878 37.8125 3.59378 37.4375C3.21878 37.0625 3.03128 36.5938 3.03128 36.0313V32.4688C1.65628 31.2188 0.968781 29.7188 0.968781 27.9688Z"
                      fill="#028D4D" />
                  </svg>
                  <svg width="20" height="24" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                    v-if="veiculo.previsao_hora_saida != ''">
                    <path
                      d="M12 0.855469C10.5833 0.855469 9.1888 0.921875 7.81641 1.05469C6.48828 1.1875 5.27083 1.45312 4.16406 1.85156C3.10156 2.20573 2.23828 2.75911 1.57422 3.51172C0.954427 4.26432 0.644531 5.26042 0.644531 6.5V19.9805C0.644531 20.6445 0.777344 21.2865 1.04297 21.9062C1.30859 22.4818 1.66276 22.9909 2.10547 23.4336V26.3555C2.10547 26.7096 2.23828 27.0417 2.50391 27.3516C2.76953 27.6172 3.10156 27.75 3.5 27.75H4.89453C5.29297 27.75 5.625 27.6172 5.89062 27.3516C6.20052 27.0417 6.35547 26.7096 6.35547 26.3555V24.8945H17.6445V26.3555C17.6445 26.7096 17.7773 27.0417 18.043 27.3516C18.3529 27.6172 18.707 27.75 19.1055 27.75H20.5C20.8984 27.75 21.2305 27.6172 21.4961 27.3516C21.7617 27.0417 21.8945 26.7096 21.8945 26.3555V23.4336C22.3372 22.9909 22.6914 22.4818 22.957 21.9062C23.2227 21.2865 23.3555 20.6445 23.3555 19.9805V6.5C23.3555 5.26042 23.0456 4.26432 22.4258 3.51172C21.8503 2.75911 21.0312 2.20573 19.9688 1.85156C18.9505 1.45312 17.7552 1.1875 16.3828 1.05469C15.0104 0.921875 13.5495 0.855469 12 0.855469ZM7.01953 20.6445C6.44401 20.6445 5.9349 20.4453 5.49219 20.0469C5.09375 19.6484 4.89453 19.1393 4.89453 18.5195C4.89453 17.944 5.09375 17.457 5.49219 17.0586C5.9349 16.6159 6.44401 16.3945 7.01953 16.3945C7.63932 16.3945 8.14844 16.6159 8.54688 17.0586C8.94531 17.457 9.14453 17.944 9.14453 18.5195C9.14453 19.1393 8.94531 19.6484 8.54688 20.0469C8.14844 20.4453 7.63932 20.6445 7.01953 20.6445ZM16.9805 20.6445C16.3607 20.6445 15.8516 20.4453 15.4531 20.0469C15.0547 19.6484 14.8555 19.1393 14.8555 18.5195C14.8555 17.944 15.0547 17.457 15.4531 17.0586C15.8516 16.6159 16.3607 16.3945 16.9805 16.3945C17.556 16.3945 18.043 16.6159 18.4414 17.0586C18.8841 17.457 19.1055 17.944 19.1055 18.5195C19.1055 19.1393 18.8841 19.6484 18.4414 20.0469C18.043 20.4453 17.556 20.6445 16.9805 20.6445ZM20.5 12.1445H3.5V7.89453H20.5V12.1445Z"
                      fill="#00A3FF" />
                  </svg>
                  <span class="text-font" :class="{
                      'viagem-prevista': veiculo.previsao_hora_saida != '',
                      'viagem-nao-prevista': veiculo.previsao_hora_saida == '',
                    }">
                    {{ veiculo.registration_number }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import "./../../../assets/css/operacoes/terminal.css";
import http from "../../../services/http.js";
import { Tooltip } from "bootstrap";

export default {
  name: "terminal",
  data: () => {
    return {
      id_empresa: null,
      locations: [],
      terminais: [],
    };
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });

    this.emitter.on("changeOfCompany", (data) => {
      this.id_empresa = data;
    });

    this.emitter.on("terminal", (data) => {
      this.id_empresa = data.orgid;
      // console.log("saida da modal terminal", data)
      $("#terminal").modal("show");
      this.getLocation();
    });
  },
  methods: {
    getLocation() {
      this.locations = [];
      this.terminais = [];
      http
        .get("terminal/location/" + this.id_empresa)
        .then((res) => {
          this.locations = res.data.dados;

          var filtroTerminal = [];

          this.locations.forEach(e => {
            if (!filtroTerminal.includes(e.id_ponto)) filtroTerminal.push(e.id_ponto);
          });

          // console.log("filtroTerminal: ", filtroTerminal);

          this.locations.forEach(e => {

            for (var i = 0; i < filtroTerminal.length; i++) {
              if (filtroTerminal[i] == e.id_ponto) {
                this.terminais.push({ id_ponto: e.id_ponto, nome: e.nome });
                filtroTerminal.splice(i, 1);
              }
            }

          })

          // this.locations.forEach((e) => {
          //   if (this.terminais.length == 0)
          //     this.terminais.push({ id_ponto: e.id_ponto, nome: e.nome });
          //   else {
          //     var check = false;
          //     for (var i = 0; i < this.terminal.length; i++) {
          //       if (e.id_ponto != this.terminais[i].id_ponto) check = true;
          //     }
          //     if (check)
          //       this.terminais.push({ id_ponto: e.id_ponto, nome: e.nome });
          //   }
          // });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // ordenarVeiculos(veiculos) {
    //   const veiculosComViagemPrevista = veiculos.filter(
    //     (veiculo) => veiculo.viagemPrevista
    //   );
    //   const veiculosSemViagemPrevista = veiculos.filter(
    //     (veiculo) => !veiculo.viagemPrevista
    //   );
    //   veiculosSemViagemPrevista.sort((a, b) => a.id - b.id);
    //   veiculosComViagemPrevista.sort((a, b) => {
    //     if (a.id < b.id) return -1;
    //     if (a.id > b.id) return 1;
    //     return 0;
    //   });
    //   return [...veiculosSemViagemPrevista, ...veiculosComViagemPrevista];
    // },
  },
};
</script>
