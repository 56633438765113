<template>
    <div class="modal fade " id="cumprimentodetrechoslist" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" style="z-index: 9998">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content custom-background-blue-light modal-list-trechos">
                <div class="modal-header modal-header-list-trechos shadow-light">
                    <div class="row w-100">
                        <div class="col-7 text-center d-flex div-tittle-modal-trecho">
                            <h5 class="modal-title title-font tittle-list-trechos" id="exampleModalLabel">
                                Cumprimento de Trechos
                            </h5>
                        </div>
                        <div class="col-5 text-end">
                            <button type="button"
                                class="btn-trechos-list-header btn-atrasado-trechos btn text-weight shadow-light"
                                @click="filterTrips('atrasado')">
                                <svg width="20" height="26" viewBox="0 0 32 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.3945 17.8555V12.1445H14.6055V17.8555H17.3945ZM17.3945 23.5V20.6445H14.6055V23.5H17.3945ZM0.394531 27.75L16 0.855469L31.6055 27.75H0.394531Z"
                                        fill="#E4EBF3" />
                                </svg>
                            </button>
                            <button type="button"
                                class="btn-trechos-list-header btn-pontual-trechos btn text-weight shadow-light"
                                @click="filterTrips('pontual')">
                                <svg width="20" height="26" viewBox="0 0 32 29" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M31.6055 13.1445V16C31.6055 16.3542 31.5391 16.7083 31.4062 17.0625L27.0898 27.0234C26.6471 28.1745 25.7839 28.75 24.5 28.75H11.75C10.9974 28.75 10.3333 28.4622 9.75781 27.8867C9.18229 27.3112 8.89453 26.6471 8.89453 25.8945V11.75C8.89453 10.9974 9.18229 10.3333 9.75781 9.75781L19.0547 0.394531L20.582 1.92188C20.9805 2.32031 21.1797 2.80729 21.1797 3.38281V3.84766L19.7852 10.3555H28.75C29.5026 10.3555 30.1667 10.6432 30.7422 11.2188C31.3177 11.75 31.6055 12.3919 31.6055 13.1445ZM0.394531 28.75V11.75H6.10547V28.75H0.394531Z"
                                        fill="#E4EBF3" />
                                </svg>

                            </button>
                            <button type="button"
                                class="btn-trechos-list-header btn-adiantado-trechos btn text-weight shadow-light"
                                @click="filterTrips('adiantado')">
                                <svg width="20" height="26" viewBox="0 0 16 26" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.3197 25.75H4.85876L6.3197 15.8555H1.33923C0.6309 15.8555 0.453817 15.5456 0.807983 14.9258C0.940796 14.7044 0.962931 14.638 0.87439 14.7266C3.1322 10.7865 5.87699 5.96094 9.10876 0.25H10.5697L9.10876 10.1445H14.0892C14.709 10.1445 14.9082 10.4544 14.6869 11.0742L6.3197 25.75Z"
                                        fill="#E4EBF3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-body custom-background-blue-dark justify-content-center text-center d-block align-items-center justify-content-center modal-body-trechos"
                    align="center">
                    <div class="cards-trechos-list" id="style-trechos">
                        <div class="card card-list-trechos shadow-light"
                            v-bind:class="{ 'border-situacao-atrasado-veiculo-trecho': dado.situacao == 'atrasado', 'border-situacao-pontual-veiculo-trecho': dado.situacao == 'pontual', 'border-situacao-adiantado-veiculo-trecho': dado.situacao == 'adiantado' }"
                            v-for="dado in filteredTrips" :key="dado">
                            <div class="card-body card-body-list-trechos">
                                <h5 class="card-title card-title-trecho text-center">{{ dado.nome }}</h5>
                                <p class="p-title-trechos-list">{{ dado.tempoEsperado }}. - <strong
                                        v-bind:class="{ 'color-situacao-atrasado-veiculo-trecho': dado.situacao == 'atrasado', 'color-situacao-pontual-veiculo-trecho': dado.situacao == 'pontual', 'color-situacao-adiantado-veiculo-trecho': dado.situacao == 'adiantado' }">
                                        {{ dado.tempoReal }}</strong>.</p>
                                <div class="d-flex justify-content-between div-p-trechos">
                                    <div class="text-start">
                                        <p class="p-title-trechos-list">Av. Recife</p>
                                        <p class="p-second-trechos-list">Velocidade Média</p>
                                    </div>
                                    <div class="text-end">
                                        <p class="p-title-trechos-list">Av. Pedro II</p>
                                        <p class="p-second-trechos-list">{{ dado.velocidadeMediaEsperada }} - <strong
                                                v-bind:class="{ 'color-situacao-atrasado-veiculo-trecho': dado.situacao == 'atrasado', 'color-situacao-pontual-veiculo-trecho': dado.situacao == 'pontual', 'color-situacao-adiantado-veiculo-trecho': dado.situacao == 'adiantado' }">{{
                                                    dado.velocidadeMediaReal }}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="modal-footer custom-background-blue-light justify-content-center text-center d-flex align-items-center justify-content-center">
                    <button type="button"
                        class="btn-trechos-list btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen">
                        Concluir
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../../../assets/css/operacoes/cadastro-de-trechos/cumprimento-de-trechos-list.css';
import http from '../../../../services/http.js';
export default {
    name: 'filtroLinhas',
    data: () => {
        return {
            orgid: null,
            mocks: [
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '40 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '30km/h', velocidadeMediaReal: '17km/h', situacao: 'atrasado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '19 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '44km/h', velocidadeMediaReal: '45km/h', situacao: 'pontual' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '10 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '33km/h', velocidadeMediaReal: '40km/h', situacao: 'adiantado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '40 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '30km/h', velocidadeMediaReal: '17km/h', situacao: 'atrasado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '19 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '44km/h', velocidadeMediaReal: '45km/h', situacao: 'pontual' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '10 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '33km/h', velocidadeMediaReal: '40km/h', situacao: 'adiantado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '40 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '30km/h', velocidadeMediaReal: '17km/h', situacao: 'atrasado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '19 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '44km/h', velocidadeMediaReal: '45km/h', situacao: 'pontual' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '10 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '33km/h', velocidadeMediaReal: '40km/h', situacao: 'adiantado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '40 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '30km/h', velocidadeMediaReal: '17km/h', situacao: 'atrasado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '19 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '44km/h', velocidadeMediaReal: '45km/h', situacao: 'pontual' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '10 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '33km/h', velocidadeMediaReal: '40km/h', situacao: 'adiantado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '40 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '30km/h', velocidadeMediaReal: '17km/h', situacao: 'atrasado' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '19 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '44km/h', velocidadeMediaReal: '45km/h', situacao: 'pontual' },
                { nome: 'Avenida Principal PE - PB', tempoEsperado: '20 mim', tempoReal: '10 mim', pontoInicial: 'Av. Recife', pontoFinal: 'Av. Pedro II', velocidadeMediaEsperada: '33km/h', velocidadeMediaReal: '40km/h', situacao: 'adiantado' }
            ],
            filteredTrips: [],
            situacaoAux: ''
        }
    },
    mounted() {
        this.emitter.on('cumprimentodetrechoslist', (data) => {
            this.orgid = data;
            console.log(this.orgid)
            this.filteredTrips = this.mocks;
            $('#cumprimentodetrechoslist').modal('show');
        })
    },
    methods: {
        filterTrips(situacao) {
            if (this.situacaoAux != situacao) {
                this.situacaoAux = situacao;
                this.filteredTrips = [];
                this.filteredTrips = this.mocks.filter(trip => trip.situacao === situacao);
                console.log(this.filteredTrips)
            } else {
                this.situacaoAux = '';
                this.filteredTrips = this.mocks;
            }
        }
    }
}
</script>