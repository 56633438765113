<template>
    <div class="custom-sidebar" style="height:100%;">
        <painelDeEventos />
        <div class="sidebar sidebar-superior sidebar-color-blue size-sidebar-top">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <select name="" id="company" class="form-control form-select form-control-sidebar selecao-empresa" @change="selectCompany()"
                        v-model="selectedCompany" style="color: black">
                        <option value="null" selected>Selecione a Empresa</option>
                        <option v-for="organization in organizations" v-bind:key="organization.id"
                            :value="organization.id">{{ organization.name }}</option>

                        <!-- <option 
                                v-for="organization in organizations"
                                v-bind:key="organization.id" :value="organization.id"
                                v-bind:selected="$index === 0 ? 'selected' : false"
                                >{{ organization.name }}</option> -->

                        <!-- <option 
                                v-for="(organization, index) in organizations"
                                v-bind:key="index" :value="organization.id"
                                :selected="index === 0 ? 'selected' : ''"
                                v-if="initial_value != organization.name"
                                >{{ organization.name }}</option> -->
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 offset-md-1 div-input-calendar" style="margin-top: 1%">
                    <!-- <i class="fa fa-calendar" aria-hidden="true"></i> -->
                    <input id="date" @change="selectDate($event)" type="date" class="form-control input-field form-control-sidebar selecao-empresa"
                        placeholder="" v-model="selectedDate" />
                    <!-- <input id="date" @change="selectDate($event)" type="date" class="form-control custom-date input-field" placeholder="" /> -->
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <span class="text-white date-now text-font custom-font-size-12"> <strong>Última Atualização</strong>
                    </span>
                    <span style="margin-top: 1px; " class="text-white date-now text-font custom-font-size-18">{{
                        date_time_now
                    }}</span>
                </div>
            </div>
            <br>

            <div class="row">
                <div class="col-md-8 range-timer size-range-timer">
                    <label for="range_weight" class="text-white custom-font-size-18">Timer: </label>
                    <input @change="rangeTime($emit)" id="range" type="range" name="weight" class="slider" min="30"
                        max="150" step="30" v-model="range_time">
                    <div
                        style="background: #A1D624; width: 50px; height: 22px; display: inline-block; color: #fff; border-radius: 3px;">
                        <span class="slider_label text-white"></span>
                        <span class="slider_label text-white" v-if="!show_range_time">{{ range_time }}</span>
                    </div>
                </div>
            </div>
            <br>
            <hr class="line-blue">

            <div class="row">
                <div class="col-md-12" style="margin-top: -5%;">
                    <button class="btn-sidebar" @click="showMenuTrecho = !showMenuTrecho" style="margin-right: 2%;">
                        <svg width="16" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.64 32L28 32C32.42 32 36 28.42 36 24C36 19.58 32.42 16 28 16L8 16C5.8 16 4 14.2 4 12C4 9.8 5.8 8 8 8L24.36 8C25.2 10.32 27.4 12 30 12C33.32 12 36 9.32 36 6C36 2.68 33.32 -1.17147e-07 30 -2.62268e-07C27.4 -3.75918e-07 25.2 1.68 24.36 4L8 4C3.58 4 -3.31332e-07 7.58 -5.24537e-07 12C-7.17741e-07 16.42 3.58 20 8 20L28 20C30.2 20 32 21.8 32 24C32 26.2 30.2 28 28 28L11.64 28C11.1681 26.6681 10.2407 25.5458 9.02153 24.8313C7.80238 24.1169 6.36997 23.8563 4.97731 24.0956C3.58466 24.3349 2.32138 25.0586 1.41063 26.139C0.499872 27.2194 0.000243884 28.5869 -1.31134e-06 30C-1.45646e-06 33.32 2.68 36 6 36C8.6 36 10.8 34.32 11.64 32ZM28 6C28 4.9 28.9 4 30 4C31.1 4 32 4.9 32 6C32 7.1 31.1 8 30 8C28.9 8 28 7.1 28 6ZM4 30C4 28.9 4.9 28 6 28C7.1 28 8 28.9 8 30C8 31.1 7.1 32 6 32C4.9 32 4 31.1 4 30Z"
                                fill="#292994" />
                        </svg>
                    </button>
                    <ul v-if="showMenuTrecho" class="menuTrechosFather">
                        <li v-for="option in menuTrechosOptions" class="menuDelete"
                            :key="option.id"
                            @click="ShowModalConfigurarEVisualizarTrechos(option.id)">
                            {{ option.label }}
                        </li>
                    </ul>
                    <button class="btn-sidebar" style="margin-left: 2%;">
                        <svg width="16" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.5 0H10.5L0 10.5V25.5L10.5 36H25.5L36 25.5V10.5L25.5 0ZM31.9688 23.8125L23.8125 31.9688H12.1875L4.03125 23.8125V12.1875L12.1875 4.03125H23.8125L31.9688 12.1875V23.8125ZM16.0312 7.96875H19.9688V19.9688H16.0312V7.96875ZM16.0312 24H19.9688V28.0312H16.0312V24Z"
                                fill="#292994" />
                        </svg>
                    </button>
                </div>
            </div>

        </div>
        
        <div class="col-md-12 sidebar sidebar-inferior sidebar-color-blue-escuro size-sidebar-bottom " >
            
            <!-- <div class="row"> -->
                <!-- <div class="row">
                    <div class="col-md-12">
                        <div class="dropdown">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Dropdown button
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div>
                </div> -->
            <div class="row justify-content-center">

                <div class="col-md-12 d-flex justify-content-center">
                    <div class="btn-group">

                        <div class="dropdown">
                            <button class="btn-sidebar-2 filter " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <svg width="16" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M24.0001 12V1.90735e-05H28.0313V4.03127H36.0001V7.96877H28.0313V12H24.0001ZM36.0001 19.9688H16.0313V16.0313H36.0001V19.9688ZM7.96881 12H12.0001V24H7.96881V19.9688H6.10352e-05V16.0313H7.96881V12ZM19.9688 36H16.0313V24H19.9688V28.0313H36.0001V31.9688H19.9688V36ZM6.10352e-05 4.03127H19.9688V7.96877H6.10352e-05V4.03127ZM6.10352e-05 28.0313H12.0001V31.9688H6.10352e-05V28.0313Z"
                                        fill="#17175F" />
                                </svg>
                                Filtro
                            </button>
                            <div class="dropdown-menu dropdown-menu-custom" aria-labelledby="dropdownMenuButton1" align="center" style="position: relative !important; ">
                                <!-- <div id="triangulo" style="margin-top: -20px !important"></div>						  	 -->
                                <table class="table " >
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input 
                                                    id="aberto"
                                                    name="tipo"
                                                    style="float: left !important; margin-top: -3px"
                                                    class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                                    @click="alertas('aberto')" /> 
                                                    <span class="text-white">Alertas em Aberto</span>
                                            </td>
                                            <td>
                                                <input 
                                                    id="fechado"
                                                    name="tipo"
                                                    style="float: left !important; margin-top: -3px"
                                                    class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                                    @click="alertas('fechado')" /> 
                                                    <span class="text-white">Alertas Fechados</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul class="list-group font-responsive-event">
                                    <li class="list-group-item text-white custom-list font-responsive-event " >
                                        Desvio de Itinerário
                                        <input 
                                            style="float: right !important; margin-top: -3px"
                                            class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                            @click="alertas('')" /> 
                                    </li>
                                    <li class="list-group-item text-white custom-list">
                                        Partidas não Realizadas
                                        <input 
                                            style="float: right !important; margin-top: -3px"
                                            class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                            @click="alertas('')" /> 
                                    </li>
                                    <li class="list-group-item text-white custom-list">
                                        Viagem não Concluída
                                        <input 
                                            style="float: right !important; margin-top: -3px"
                                            class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                            @click="alertas('')" /> 
                                    </li>
                                    <li class="list-group-item text-white custom-list">
                                        Pânico
                                        <input 
                                            style="float: right !important; margin-top: -3px"
                                            class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                            @click="alertas('')" /> 
                                    </li>
                                    <li class="list-group-item text-white custom-list">
                                        Acidente / Queda
                                        <input 
                                            style="float: right !important; margin-top: -3px"
                                            class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                            @click="alertas('')" /> 
                                    </li>
                                    <li class="list-group-item text-white custom-list">
                                        Comboio
                                        <input 
                                            style="float: right !important; margin-top: -3px"
                                            class="form-check-input check-input form-check-input-sidebar" type="checkbox" value=""
                                            @click="alertas('')" /> 
                                    </li>
                                </ul>
                            </div>
                        </div>

                            

                    </div>
                </div>
            </div>
            <div class="row size-events" style="margin-top: 10px; overflow-y: auto; overflow-x: hidden;">
                <div class="col-md-12" align="center">
                    <ul class="list-group">
                        <li v-for="event in events" :key="event.id">
                            <div class="filter-info" align="left" style="padding: 5px !important" @click="showModalPainelDeEVentos(event)">
                                <p class="text-white filter-info-p text-font custom-text">{{ event.tipo }}
                                    <input v-if="event.status != 'fechado'" class="form-check-input check-input" type="checkbox" value=""
                                        :id="'flexCheckDefault'+event.id" @click="checkChecked(event.id)"/>

                                    <input v-if="event.status == 'fechado'" class="form-check-input check-input" type="checkbox" value=""
                                        :id="'flexCheckDefault'+event.id" :checked="true" @click="checkChecked(event.id)"/>
                                    
                                </p>
                                <p class="text-white filter-info-p text-font custom-text"> Linha: {{ event.codigo }}  Veículo: {{ event.registration_number }}</p>
                                <p class="text-white filter-info-p text-font custom-text"> {{ event.startdatetime }} </p>
                            </div>
                        </li>

                    </ul>

                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import './../../assets/css/operacoes/sidebar.css';
import http from '../../services/http.js';

import painelDeEventos from './modals/PainelDeEventos';

export default {
    name: 'SideBar',
    components: {
        painelDeEventos,
    },
    created() {
        this.selectedDate = moment().format('YYYY-MM-DD');
        // this.selectedDate = new Date().toISOString().substr(0, 10);
    },
    data: () => {
        return {
            user: {
                email: null,
                id: null,
                group_id: null
            },
            date_time_now: null,
            range_time: null,
            show_range_time: false,
            organizations: null,
            initial_value: null,
            selectedDate: null,
            events: [],
            filterEvents: [],
            objectMap: [],
            filterPolylines: [],
            pontos: [],
            center: { lat: 0, lng: 0 },
            objectEvents: {
                evento: null,
                tipo: null,
                veiculo: null,
                linha: null,
                motorista: null,
            },
            showMenuTrecho: false,
            menuTrechosOptions: [
                { id: 1, label: "Visualizar Trechos" },
                { id: 2, label: "Configurar Trechos" }
            ],
        }
    },
    mounted() {
        // localStorage.setItem('empresa', 42);
        this.getDateTimeNow();
        this.valueOfRange();
        this.emitter.on('dataUser', data => {

            // console.log("check")

            this.user = data;
            this.getOrganizations();

            var empresas = data.group_id;
            var group_id = empresas.split(',');
            this.selectedCompany = localStorage.getItem('empresa');
            // console.log("group_id", group_id)
            // this.selectedCompany = group_id[57]; // inserir a seleção dinâmica aqui
            // this.selectedCompany = group_id[6]; // inserir a seleção dinâmica aqui
            this.selectCompany();
        })

        $('#date').val(moment().format('YYYY-MM-DD'));

        // setTimeout(() => {
        //     this.emitter.emit('dataevent', this.selectedDate);
        // }, 100);

        this.emitter.on('sockectDateTimeNow', data => {
            this.date_time_now = data;
            // this.getDateTimeNow();
        })

        this.emitter.on('ExpressCompany', data => {
            // console.log("ExpressCompany teste")
            this.selectedCompany = data;
        })

        this.emitter.on('filtrosEventos', data => {
            this.objectEvents = data; 
            this.getEvents();           
        })

        this.emitter.on('atualizaSidebarEvents', data => {
            var event_id = data;
            this.atualizaCheckBox(event_id);
        })

        if (this.date_time_now != null && this.range_time != null) this.emitter.emit('incluirViagemDateTime', [this.date_time_now, this.range_time]);

    },
    methods: {
        // selectRangeTime(event) {
        //     this.emitter.emit('changeOfRangeTime', event.target.value);
        // },
        getDateTimeNow() {
            this.date_time_now = moment().format('DD/MM/YYYY HH:mm:ss');
        },
        valueOfRange() {
            this.range_time = 30;
            var novo = null;
            $('.slider').on('input change', function () {
                $(this).next($('.slider_label')).html(this.value);
                this.range_time = this.value;
                if (!this.show_range_time) this.show_range_time = true;
            });
            $('.slider_label').each(function () {
                let value = $(this).prev().attr('value');
                $(this).html(value);
            });
            $('#range').val(this.range_time);
        },
        selectDate(event) {
            this.emitter.emit('changeOfDate', event.target.value)
            // this.emitter.emit('dataevent', this.selectedDate)
            this.getEvents();
        },
        rangeTime(event) {
            this.emitter.emit('changeOfRangeTime', this.range_time);
        },
        getOrganizations() {
            http.get('/operation/organizations', {
                params: {
                    orgid: this.user.orgid,
                    group_id: this.user.group_id
                }
            }).then(res => {
                this.organizations = res.data.organization;
            }).catch(err => console.log(err));
        },
        selectCompany() {
            this.emitter.emit('changeOfCompany', this.selectedCompany);
            this.getEvents();
        },
        getEvents() {
            if (this.selectedCompany == null || this.selectedCompany == undefined)
                this.selectedCompany = localStorage.getItem('empresa');                

            var data = $('#date').val();
            http.get('events', {
                params: {
                    data: data,
                    orgid: this.selectedCompany,
                    evento: this.objectEvents.evento,
                    tipo: this.objectEvents.tipo,
                    veiculo: this.objectEvents.veiculo,
                    linha: this.objectEvents.linha,
                    motorista: this.objectEvents.motorista,
                }
            }).then(response => {
                this.events = response.data.dados;
                this.filterEvents = response.data.dados;

                this.events.forEach(e => {
                    e.startdatetime = moment(e.startdatetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
                });

            }).catch(error => console.log(error));
        },
        showModalPainelDeEVentos(item) {

            // console.log(item);
            // this.emitter.emit('showModalPainelEventos', [item, this.selectedCompany]);

            http.get('events/itinerary/line', {
                params: {
                    orgid: this.selectedCompany,
                    linha_id: item.linhaid,
                    item_id: item.id,
                    data: item.startdatetime,
                    itinerario_id: item.itinerario_id
                }
            }).then(response => {
                var num = 0;
                var lat = 0;
                var lng = 0;
                response.data.itinerarios.map((item) => {
                    item.pontos.map((item2) => {
                        this.filterPolylines.push({
                            lat: parseFloat(item2.lat),
                            lng: parseFloat(item2.lng),
                        });
                    })
                });

                response.data.pontos.map((item) => {
                    this.pontos.push({
                        lat: parseFloat(item.lat),
                        lng: parseFloat(item.lng),
                    });
                    if (num == 0) {
                        // dado usado para centralizar o mapa
                        lat = parseFloat(item.lat);
                        lng = parseFloat(item.lng);
                        this.center.lat = lat;
                        this.center.lng = lng;
                        num += 1;
                    }
                });

                var motorista = response.data.motorista[0].employee_number;
                console.log('motorista', motorista);

                this.emitter.emit('showModalPainelEventos', [this.selectedCompany, item, this.filterPolylines, this.pontos, this.center, this.user.id, motorista]);

            }).catch(error => console.log(error));
        },
        checkChecked(id_evento) {
            this.events.forEach(e => {
                if (e.id == id_evento && e.status != 'fechado') {
                    $('#flexCheckDefault'+id_evento).prop("checked", false);
                }
                if (e.id == id_evento && e.status == 'fechado') {
                    $('#flexCheckDefault'+id_evento).prop("checked", true);
                }
            });
        },
        alertas(tipo) {
            if (tipo != '') {
                if (tipo == 'fechado' && $('#fechado').is(":checked")) {
                    console.log("passou fechado")
                    this.events = this.filterEvents.filter(e => {
                        return e.status == 'fechado' || e.status == 'Fechado'
                    });
                } else {
                    this.events = this.filterEvents;
                }
            }

            if (tipo == 'fechado') {
                if ($('#fechado').is(":checked")) {
                     $('#aberto').prop('checked', false);
                }else {
                    $('#fechado').prop('checked', false);
                }
            }
            if (tipo == 'aberto') {
                if ($('#aberto').is(":checked")) {
                    $('#fechado').prop('checked', false);
                }else {
                    $('#aberto').prop('checked', false);
                }
            }
          
        },
        atualizaCheckBox(event_id) {
            $('#flexCheckDefault'+event_id).prop('checked', true);
            this.events.forEach(e => {
                if (e.id == event_id) e.status = 'fechado';
            });
            console.log("event_id ", event_id)

        },
        ShowModalConfigurarEVisualizarTrechos(option){
            this.showMenuTrecho = !this.showMenuTrecho;
            if (this.selectedCompany != null && this.selectedCompany != 'null') {
                if (option == 1) {
                    this.emitter.emit('cumprimentodetrechoslist', this.selectedCompany)
                }else{
                    this.emitter.emit('cadastrodetrechos', this.selectedCompany)
                }
            }
        }
    },
}
</script>