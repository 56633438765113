<template>
    <div class="modal fade " id="incluirViagemEscala" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 9999">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="background-color: #292994; margin-left: 10%; width: 75% !important; border-radius: 20px;">
                <div class="modal-header" style="border-color:#292994 !important; padding-left: 55px;">
                    <div class="offset-md-4">
                        <h5 class="modal-title title-font" id="exampleModalLabel" style="color:white;text-align: center;">
                            Incluir Viagem
                        </h5>
                    </div>
                </div>
                <div class="modal-body modal-body-incluirviagen" style="padding-left:30px">
                    <div style="color: #fff; width: 90%; margin-left: 20px; margin-top: -3%;">
                        <div class="top-viagem">

                            <div>
                                <select :disabled="1 > 0" name="" id="" class="select_incluir shadow-light"
                                    v-model="selectedOptionLinha">
                                    <option :value="linha.id" v-for="linha in linhas" :key="linha.id"
                                        v-bind:selected="linha.id === selectedOptionLinha">
                                        {{ linha.codigo }}
                                    </option>
                                </select>
                            </div>

                            <div class="input-group" style="width: 140px !important;">
                                <input type="text" class="form-control select_incluir shadow-light" style="text-align: center;"
                                    :disabled="1 > 0" aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-default" placeholder="Posição"
                                    v-model="selectedOptionPosicao">
                            </div>
                            <div>
                                <v-select :disabled="emptyOption(atividades)" class="select_incluir shadow-light"
                                    id="select-viagem" label="alias" placeholder="Atividade" :options="atividades"
                                    v-model="selectedOptionAtividade" @focusout="condicional">
                                </v-select>
                            </div>
                        </div>

                        <div class="div1 responsive-div-incluir"
                            v-if="this.vif === 'saidagaragem' || this.vif === 'viagemregular' || this.vif === 'viagemextra'"
                            style="margin-top:20px">
                            <div>
                                <h7 class="header-with-line-ida">IDA</h7>
                            </div>
                            <div class="container-viagem">
                                <div class="top-viagem">
                                    <div>
                                        <v-select :disabled="emptyOption(itinerariosIda)"
                                            class="select_incluir shadow-light" id="select-viagem" label="nome"
                                            placeholder="Itinerario" :options="itinerariosIda"
                                            v-model="selectedIdaItinerario" @focusout="changeDiv1('itinerariosIda')">
                                        </v-select>
                                    </div>

                                    <div v-if="selectedOptionAtividade.alias != 'saidagaragem'">
                                        <v-select :disabled="isDisabledPointsGo" class="select_incluir shadow-light"
                                            id="select-viagem" label="nome" placeholder="Ponto Inicio" :options="pontosIda"
                                            v-model="selectedIdaPontoInicio" @focusout="changeDiv1()">
                                        </v-select>
                                    </div>

                                    <div v-if="selectedOptionAtividade.alias == 'saidagaragem'">
                                        <v-select :disabled="isDisabledPointsGo" class="select_incluir shadow-light"
                                            id="select-viagem" label="nome" placeholder="Ponto Inicio"
                                            :options="pontosGaragem" v-model="selectedIdaPontoInicio"
                                            @focusout="changeDiv1()">
                                        </v-select>
                                    </div>

                                    <div>
                                        <v-select :disabled="isDisabledPointsGo" class="select_incluir shadow-light"
                                            id="select-viagem" label="nome" placeholder="Ponto Fim" :options="pontosIda"
                                            v-model="selectedIdaPontoFim" @focusout="changeDiv1()">
                                        </v-select>
                                    </div>
                                </div>
                                <div class="middle-viagem" margin-top:20px>
                                    <div>
                                        <v-select :disabled="emptyOption(veiculos)" class="select_incluir shadow-light"
                                            id="select-viagem" label="alias" placeholder="Veiculo" :options="veiculos"
                                            v-model="selectedIdaVeiculo" @focusout="changeDiv1()">
                                        </v-select>
                                    </div>

                                    <div>
                                        <v-select :disabled="emptyOption(motoristas)" class="select_incluir shadow-light"
                                            id="select-viagem" label="alias" placeholder="Motorista" :options="motoristas"
                                            v-model="selectedIdaMotorista" @focusout="changeDiv1()">
                                        </v-select>
                                    </div>

                                    <div>
                                        <v-select :disabled="emptyOption(cobradores)" class="select_incluir shadow-light"
                                            id="select-viagem" label="alias" placeholder="Cobrador" :options="cobradores"
                                            v-model="selectedIdaCobrador" @focusout="changeDiv1()">
                                        </v-select>
                                    </div>
                                </div>
                                <div class="bottom-viagem">
                                    <div class="input-group" style="width: 135px !important; margin-right: 50px;">
                                        <input type="time" class="form-control select_incluir  shadow-light"
                                            style="color: #292994; text-align: center;" aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-default" placeholder="00:00"
                                            v-model="hora_saida_ida" @change="changeDiv1">
                                    </div>

                                    <div class="input-group" style="width: 135px !important;">
                                        <input type="time" class="form-control select_incluir  shadow-light"
                                            style="color: #292994; text-align: center;" aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-default" placeholder="00:00"
                                            v-model="hora_chegada_ida" @change="changeDiv1">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="div2 responsive-div-incluir"
                            v-if="this.vif === 'retornogaragem' || this.vif === 'viagemregular' || this.vif === 'viagemextra'"
                            style="margin-top:20px">
                            <div style="text-align:center">
                                <h7 class="header-with-line-volta">VOLTA</h7>
                            </div>

                            <div class="container-viagem">
                                <div class="top-viagem">
                                    <div>
                                        <v-select :disabled="emptyOption(itinerariosVolta)"
                                            class="select_incluir shadow-light" id="select-viagem" label="nome"
                                            placeholder="Itinerario" :options="itinerariosVolta"
                                            v-model="selectedVoltaItinerario" @focusout="changeDiv2('itinerariosVolta')">
                                        </v-select>
                                    </div>

                                    <div>
                                        <v-select :disabled="isDisabledPointsBack" class="select_incluir shadow-light"
                                            id="select-viagem" label="nome" placeholder="Ponto Inicio"
                                            :options="pontosVolta" v-model="selectedVoltaPontoInicio"
                                            @focusout="changeDiv2()">
                                        </v-select>
                                    </div>

                                    <div v-if="selectedOptionAtividade.alias != 'retornogaragem'">
                                        <v-select :disabled="isDisabledPointsBack" class="select_incluir shadow-light"
                                            id="select-viagem" label="nome" placeholder="Ponto Fim" :options="pontosVolta"
                                            v-model="selectedVoltaPontoFim" @focusout="changeDiv2()">
                                        </v-select>
                                    </div>

                                    <div v-if="selectedOptionAtividade.alias == 'retornogaragem'">
                                        <v-select :disabled="isDisabledPointsBack" class="select_incluir shadow-light"
                                            id="select-viagem" label="nome" placeholder="Ponto Fim" :options="pontosGaragem"
                                            v-model="selectedVoltaPontoFim" @focusout="changeDiv2()">
                                        </v-select>
                                    </div>
                                </div>
                                <div class="middle-viagem">
                                    <div>
                                        <v-select :disabled="emptyOption(veiculos)" class="select_incluir shadow-light"
                                            id="select-viagem" label="alias" placeholder="Veiculo" :options="veiculos"
                                            v-model="selectedVoltaVeiculo" @focusout="changeDiv2()">
                                        </v-select>
                                    </div>

                                    <div>
                                        <v-select :disabled="emptyOption(motoristas)" class="select_incluir shadow-light"
                                            id="select-viagem" label="alias" placeholder="Motorista" :options="motoristas"
                                            v-model="selectedVoltaMotorista" @focusout="changeDiv2()">
                                        </v-select>
                                    </div>

                                    <div>
                                        <v-select :disabled="emptyOption(cobradores)" class="select_incluir shadow-light"
                                            id="select-viagem" label="alias" placeholder="Cobrador" :options="cobradores"
                                            v-model="selectedVoltaCobrador" @focusout="changeDiv2()">
                                        </v-select>
                                    </div>
                                </div>
                                <div class="bottom-viagem">
                                    <div class="input-groupt" style="width: 140px !important;  margin-right: 50px;">
                                        <input type="time" class="form-control select_incluir  shadow-ligh"
                                            style="color: #292994; text-align: center;" aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-default" placeholder="00:00"
                                            v-model="hora_saida_volta" @change="changeDiv2">
                                    </div>

                                    <div class="input-group" style="width: 140px !important;">
                                        <input type="time" class="form-control select_incluir  shadow-light"
                                            style="color: #292994; text-align: center;" aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-default" placeholder="00:00"
                                            v-model="hora_chegada_volta" @change="changeDiv2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="padding:0 !important">
                    <div style="margin: auto">
                        <button @click="submitForm()" type="button"
                            class="btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen">Aplicar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../../../assets/css/operacoes/incluir-viagem.css';
import http from "../../../../services/http.js";

export default {
    name: 'incluirViagemEscala',
    data: () => {
        return {
            veiculos: [],
            motoristas: [],
            id_empresa: null,
            linhas: [],
            atividades: [],
            itinerarios: [],
            itinerariosIda: [],
            itinerariosVolta: [],
            pontosIda: [],
            pontosVolta: [],
            isDisabledPointsGo: false,
            isDisabledPointsBack: false,
            selectedIdaPontoInicio: null,
            selectedIdaPontoFim: null,
            cobradores: '',

            selectedOptionAtividade: '',
            beforeAtividade: '',
            selectedOptionLinha: '',
            selectedOptionPosicao: '',

            div1Filled: false,
            selectedIdaItinerario: '',
            selectedIdaPontoInicio: '',
            selectedIdaPontoFim: '',
            // selectedOptionItinerario: '',
            // selectedOptionPontoInicio: '',
            // selectedOptionPontoFim: '',
            selectedIdaVeiculo: '',
            selectedIdaMotorista: '',
            selectedIdaCobrador: '',
            hora_saida_ida: '',
            hora_chegada_ida: '',

            div2Filled: false,
            selectedVoltaItinerario: '',
            selectedVoltaPontoInicio: '',
            selectedVoltaPontoFim: '',
            // selectedOptionItinerario2: '',
            // selectedOptionPontoInicio2: '',
            // selectedOptionPontoFim2: '',
            selectedVoltaVeiculo: '',
            selectedVoltaMotorista: '',
            selectedVoltaCobrador: '',
            hora_saida_volta: '',
            chegada_volta: '',

            date: '',
            vif: '',
            date_time_now: '',
            range_time: '',
            pontosGaragem: []

        }
    },
    mounted() {
        this.emitter.on("incluirViagemEscala", (data) => {
            this.emptyModal();
            this.selectedOptionLinha = data[0].trips[0].linha_id;
            this.selectedOptionPosicao = data[0].posicao;
            this.id_empresa = data[1]
            this.date = data[2]
            this.motoristas = data[3];
            this.veiculos = data[4];
            this.setItinerary();
            this.setData();
            $("#incluirViagemEscala").modal("show");
        });

        this.isDisabledPointsGo = true;
        this.isDisabledPointsBack = true;

        this.emitter.on('incluirViagemDateTime', data => {
            this.date_time_now = moment(data[0], 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            this.range_time = data[1];
        })

    },
    methods: {
        setData() {
            http.get('operation/actives', { params: { orgid: this.id_empresa } })
                .then((res) => {
                    this.atividades = res.data.dados;
                }).catch((err) => console.error(err));


            http.get('operation/lines', { params: { orgid: this.id_empresa } })
                .then((res) => {
                    this.linhas = res.data.dados;
                }).catch((err) => console.error(err));

            this.emptyModal();
        },
        setItinerary(){
            http.get('operation/itinerary', {
                params: {
                    orgid: this.id_empresa,
                    linha_id: this.selectedOptionLinha
                }
            }).then(response => {
                this.itinerarios = response.data.dados;
                this.itinerariosIda = [],
                    this.itinerariosVolta = [],
                    this.itinerarios.forEach(itinerario => {
                        const destino = itinerario.sentido === "i" ? this.itinerariosIda : this.itinerariosVolta;
                        destino.push(itinerario);
                    });
            }).catch(error => { console.error(error) });
        },
        changeLinha() {
            this.selectedIdaItinerario = '';
            this.selectedVoltaItinerario = '';
            this.selectedIdaPontoInicio = '';
            this.selectedIdaPontoFim = '';
            this.selectedVoltaPontoInicio = '';
            this.selectedVoltaPontoFim = '';

            this.itinerarios = [];
            this.itinerariosIda = [];
            this.itinerariosVolta = [];

            this.pontosIda = [];
            this.pontosGaragem = [];
            this.pontosVolta = [];

        },
        salvarViagem() {

            // REALIZANDO FORMATAÇÃO PARA ENVIO DE OUTROS CAMPOS NECESSÁRIOS
            var data = this.date;
            // var data = moment().format('YYYY-MM-DD')
            var previsao_hora_saida_ida = moment(data + ' ' + this.hora_saida_ida, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            var previsao_hora_chegada_ida = moment(data + ' ' + this.hora_chegada_ida, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

            var previsao_hora_saida_volta = moment(data + ' ' + this.hora_saida_volta, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            var previsao_hora_chegada_volta = moment(data + ' ' + this.hora_chegada_volta, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');

            // this.pontosIda.forEach(i => {
            //     if (i.nome == )
            // });

            var objectViagem = {
                orgid: this.id_empresa,
                linha: this.selectedOptionLinha.id,
                posicao: this.selectedOptionPosicao,
                atividade: this.selectedOptionAtividade.alias,

                itinerarioIda: this.selectedIdaItinerario.id,

                pontoInicioIda: this.selectedIdaPontoInicio.id,
                pontoFimIda: this.selectedIdaPontoFim.id,

                ida_nome_ponto_saida: this.selectedIdaPontoInicio.nome,
                ida_nome_ponto_chegada: this.selectedIdaPontoFim.nome,
                veiculoIda: this.selectedIdaVeiculo != null ? this.selectedIdaVeiculo.id : null,
                motoristaIda: this.selectedIdaMotorista != null ? this.selectedIdaMotorista.id : null,
                cobradorIda: this.selectedIdaCobrador,
                hora_saida_ida: this.hora_saida_ida,
                hora_chegada_ida: this.hora_chegada_ida,
                previsao_hora_saida_ida: previsao_hora_saida_ida,
                previsao_hora_chegada_ida: previsao_hora_chegada_ida,

                itinerarioVolta: this.selectedVoltaItinerario.id,
                pontoInicioVolta: this.selectedVoltaPontoInicio.id,
                pontoFimVolta: this.selectedVoltaPontoFim.id,

                volta_nome_ponto_saida: this.selectedVoltaPontoInicio.nome,
                volta_nome_ponto_chegada: this.selectedVoltaPontoFim.nome,
                veiculoVolta: this.selectedVoltaVeiculo.id,
                motoristaVolta: this.selectedVoltaMotorista.id,
                cobradorVolta: this.selectedVoltaCobrador,
                hora_saida_volta: this.hora_saida_volta,
                hora_chegada_volta: this.hora_chegada_volta,
                previsao_hora_saida_volta: previsao_hora_saida_volta,
                previsao_hora_chegada_volta: previsao_hora_chegada_volta,
                date: this.date != null ? this.date : moment().format('YYYY-MM-DD'),
                date_time_now: this.date_time_now,
                // date_time_now: moment('2023-02-09 10:00:00').format('YYYY-MM-DD HH:mm:ss'), //moment(this.date_time_now).format('YYYY-MM-DD HH:mm:ss'),
                range: this.range_time
            };
            http.post('operation/insert/trip', objectViagem)
                .then((res) => {
                    if (res.data.status) {
                        $("#incluirViagemEscala").modal("hide");
                        this.$toast.success(`Viagem salva com sucesso!`);
                        this.emitter.emit('updateViagensLinhas');
                        this.emptyModal();
                    } else {
                        this.$toast.error(`Viagem não foi salva.`);
                    }
                }).catch(error => console.error(error));
        },
        emptyModal() {
            this.vif = '',
                this.div1Filled = false;
            this.div2Filled = false;

            this.selectedOptionAtividade = '';

            this.selectedIdaItinerario = '';
            this.selectedIdaPontoInicio = '';
            this.selectedIdaPontoFim = '';
            this.selectedIdaPontoInicio = '';
            this.selectedIdaPontoFim = '';
            this.selectedIdaVeiculo = '';
            this.selectedIdaMotorista = '';
            this.selectedIdaCobrador = '';
            this.hora_saida_ida = '';
            this.hora_chegada_ida = '';

            this.selectedVoltaItinerario = '';
            this.selectedVoltaPontoInicio = '';
            this.selectedVoltaPontoFim = '';
            this.selectedVoltaPontoInicio = '';
            this.selectedVoltaPontoFim = '';
            this.selectedVoltaVeiculo = '';
            this.selectedVoltaMotorista = '';
            this.selectedVoltaCobrador = '';
            this.hora_saida_volta = '';
            this.hora_chegada_volta = '';
        },
        condicional() {
            this.vif = this.selectedOptionAtividade.alias;

        },
        changeDiv1(nome = null) {
            if (nome == 'itinerariosIda') {
                http.get('operation/points/itinerary', {
                    params: {
                        orgid: this.id_empresa,
                        id_points: this.selectedIdaItinerario.pontos_parada_ids,
                        id_itinerario: this.selectedIdaItinerario.id,
                        tipo: this.selectedOptionAtividade.alias
                    }
                }).then((response) => {
                    this.pontosIda = response.data.dados.pontos_itinerarios;
                    if (this.pontosIda.length > 0) this.isDisabledPointsGo = false;

                    this.pontosGaragem = response.data.dados.pontos_garagem;
                    this.filterPonintsIda(this.selectedOptionAtividade.alias);

                }).catch(error => console.log(error));
            }

            this.div1Filled = true;
        },
        changeDiv2(nome = null) {
            this.div2Filled = true;

            if (nome == 'itinerariosVolta') {
                http.get('operation/points/itinerary', {
                    params: {
                        id_points: this.selectedVoltaItinerario.pontos_parada_ids,
                        orgid: this.id_empresa,
                        id_itinerario: this.selectedVoltaItinerario.id,
                        tipo: this.selectedOptionAtividade.alias
                    }
                }).then((response) => {
                    this.pontosVolta = response.data.dados.pontos_itinerarios;
                    if (this.pontosVolta.length > 0) this.isDisabledPointsBack = false;

                    this.pontosGaragem = response.data.dados.pontos_garagem;
                    this.filterPonintsVolta(this.selectedOptionAtividade.alias)
                }).catch(error => console.log(error));
            }

        },
        filterPonintsIda(tipo) {
            if (tipo == 'viagemregular' || tipo == 'viagemextra') {
                this.selectedIdaPontoInicio = this.pontosIda[0];
                this.selectedIdaPontoFim = this.pontosIda[this.pontosIda.length - 1];
            }
            if (tipo == 'saidagaragem') {
                this.selectedIdaPontoInicio = this.pontosGaragem[0];
                this.selectedIdaPontoFim = this.pontosIda[0];
            }
        },
        filterPonintsVolta(tipo) {
            if (tipo == 'viagemregular' || tipo == 'viagemextra') {
                this.selectedVoltaPontoInicio = this.pontosVolta[0];
                this.selectedVoltaPontoFim = this.pontosVolta[this.pontosVolta.length - 1];
            }
            if (tipo == 'retornogaragem') {
                this.selectedVoltaPontoInicio = this.pontosVolta[this.pontosVolta.length - 1];
                this.selectedVoltaPontoFim = this.pontosGaragem[0];
            }
        },
        submitForm() {
            if (this.div1Filled && !this.validateDiv1()) {
                return this.$toast.error(`É necessario preencher todos os dados da IDA!`);
            }

            if (this.div2Filled && !this.validateDiv2()) {
                return this.$toast.error(`É necessario preencher todos os dados da VOLTA!`);
            }
            this.salvarViagem()
        },
        validateDiv1() { return this.selectedIdaItinerario !== '' && this.selectedIdaPontoInicio !== '' && this.selectedIdaPontoFim !== '' && this.hora_saida_ida !== '' && this.hora_chegada_ida !== ''; },
        validateDiv2() { return this.selectedVoltaItinerario !== '' && this.selectedVoltaPontoInicio !== '' && this.selectedVoltaPontoFim !== '' && this.hora_saida_volta !== '' && this.hora_chegada_volta !== ''; },
        emptyOption(data) { return data.length === 0 },
        
    },
}
</script>