<template>
    <div class="modal fade " id="painelEventos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="background-color: #292994; border-radius: 20px;">
                <div class="modal-header" style="border-color: #292994 !important">
                    <h5 class="left tittlepaineleventos" style="font-weight: inherit; margin-left: -45px;">Linha: {{
                        evento.codigo }}</h5>
                    <h5 class="left tittlepaineleventos" style="margin-left: -50px; font-weight: inherit">Veiculo: {{
                        evento.registration_number }}</h5>
                    <h5 class="left tittlepaineleventos" style="margin-left: -35px; font-weight: inherit">Motorista: {{
                        motorista }}</h5>
                    <h5 class="right tittlepaineleventos">{{ startdatetime }}</h5>
                </div>


                <div class="modal-body" style="padding:none; background-color: #17175F; overflow: hidden;">
                    <!-- <div> 
                        <img class="shadow-light" :src="imgSrc" style="width: 105%; margin-left: -19px; margin-top: -32px; border-radius: 20px; height: 320px;"/>
                    </div> -->
                    <div class="row" style="z-index: 100000; width: 150%;" v-if="noLoading">

                        <!-- <GMapMap
                            ref="myMapRef"
                            :click="true"
                            @click="handleClick"
                            :center="center"
                            :zoom="10"
                            map-type-id="terrain"
                            style="width: 100vw; height: 20rem">
                            <GMapPolygon
                                :options="{
                                    clickable: false
                                    }"
                                :clickable="false"
                                ref="mapPolygon"
                                :path="itinerario_normal"
                                />
                        </GMapMap> -->

                        <GMapMap :click="true" :center="center" :zoom="14" map-type-id="terrain" class="map-from-eventos">
                            <GMapCluster>
                                <GMapPolyline :path="itinerario_normal" :editable="false"
                                    :options="{ strokeColor: 'green' }" ref="mapPolygon" @click="handleClick" />
                                <GMapPolyline :path="pontos" :editable="false" :options="{ strokeColor: 'red' }"
                                    ref="mapPolygon" @click="handleClick" />

                                <!-- <GMapCluster>
                                    <GMapMarker
                                        :key="index"
                                        v-for="(m, index) in markers"
                                        :position="m.position"
                                        :clickable="true"
                                        :draggable="true"
                                        @click="center=m.position"
                                    />
                                </GMapCluster> -->

                                <!-- <GMapCluster>
                                        <GMapMarker
                                            :key="index"
                                            v-for="(m, index) in markers"
                                            :position="m.position"
                                            :icon="'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png'"
                                            />
                                    </GMapCluster> -->


                            </GMapCluster>
                            <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
                                :draggable="true" @click="openMarker()">
                                <GMapInfoWindow :closeclick="true" @closeclick="closeInfoWindow()" :opened="showInfoWindow">
                                    <div align="left"
                                        style="width: 180px; height: 130px !important; float: left; font-size: 10px; overflow:hidden !important">
                                        <p>Data: {{ evento.startdatetime }}</p>
                                        <p>Latitude: {{ latitude }}</p>
                                        <p>Longitude: {{ longitude }}</p>
                                        <p>Endereço: {{ adress }}</p>
                                    </div>
                                </GMapInfoWindow>
                            </GMapMarker>
                        </GMapMap>

                    </div>
                    <div class="row" style="margin-top: 2%;">
                        <div class="col-6" style="margin-left: -2%;">
                            <div>
                                <p
                                    style="font-weight: bold; color: #fff; margin-left: 40px; text-align: left; margin-top: 30px; margin-top: 20px;  font-size: 17px;">
                                    Desvio de intinerário</p>
                                <p class="font-p-min-painel-responsive"
                                    style="font-weight: inherit; color: #fff; margin-left: 40px; text-align: left; margin-top: -5px;">
                                    Desvio realizado das {{ startdatetime }} às {{ enddatetime }}, Itinerário: {{
                                        evento.nome }}, Local: {{ this.desvio }} na viagem {{ evento.viagem }} com início
                                    previsto às {{ evento.previsao_hora_saida }}.
                                </p>
                                <!-- <p style="font-weight: inherit; color: #fff; margin-left: 40px; text-align: left; margin-top: -5px;">Desvio realizado ás 00:00 previsto, {{ itinerario }}, endereço do inicio do desvio, viagem prevista, hora inicio e fim do evento.</p> -->
                            </div>
                        </div>
                        <div class="col-6">
                            <div>
                                <select class="select-painel-evento shadow-light" name="" id="painelevento">
                                    <option value="">Selecione Motivo</option>
                                    <option v-for="motivo in motivos" :key="motivo.id" :value="motivo.id">
                                        {{ motivo.nome }}
                                    </option>
                                </select>
                            </div>
                            <div class="div-campoTexto-Painel-Evento">
                                <div style="display: flex;">
                                    <p style="font-weight: bold; color: #fff; text-align: left;">
                                        Descrever Justificativa</p>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg" style="margin-left: 10px; margin-top: 5px;">
                                        <path
                                            d="M15.2656 3.85938L13.7422 5.38281L10.6172 2.25781L12.1406 0.734375C12.2969 0.578125 12.4922 0.5 12.7266 0.5C12.9609 0.5 13.1562 0.578125 13.3125 0.734375L15.2656 2.6875C15.4219 2.84375 15.5 3.03906 15.5 3.27344C15.5 3.50781 15.4219 3.70312 15.2656 3.85938ZM0.5 12.375L9.71875 3.15625L12.8438 6.28125L3.625 15.5H0.5V12.375Z"
                                            fill="white" />
                                    </svg>
                                </div>
                                <textarea class="campoTexto-painel-evento" id="painel-eventojustificativa" type="text"
                                    v-model="justificativa"
                                    placeholder="Descreva a justificar e motivo para realização do evento gerado"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="text-align: center; display: inline">
                    <button type="button" class="btn btngreen"
                        style="font-weight: bold; height: 50px; width: 115px; font-size: large;" @click="savePanelEvento()">
                        Concluir </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './../../../assets/css/operacoes/PainelDeEventos.css';
import http from '../../../services/http.js';
import mapa from '@/assets/img/mapa.png';
// import map from '../../map/Map.vue';

import { ref, onMounted } from "vue";
// import {setupContainsLatLng} from '../util/is-point-within-polygon.js'

const myMapRef = ref();
const mapPolygon = ref();

export default {
    name: 'painelDeEventos',
    data() {
        return {
            imgSrc: mapa,
            justificativapainelevento: '',
            placeholder: "Descreva a justificar e motivo para realização do evento gerado",
            motivos: [],
            orgid: null,
            evento: [],
            noLoading: false,
            center: {
                lat: '',
                lng: ''
            },
            itinerario_normal: [],
            pontos: [],
            item: [],
            user_id: null,
            showInfoWindow: false,
            markers: [
                {
                    position: {
                        lat: '', lng: ''
                        // lat: -8.057655393429243, lng: -34.87229640503695
                    },
                }
            ],
            desvio: null,
            adress: null,
            latitude: null,
            longitude: null,
            startdatetime: null,
            enddatetime: null,
            motorista: null
        };
    },
    mounted() {
        // this.myMapRef = ref();

        // console.log(this.myMapRef)

        // this.myMapRef.value.$mapPromise.then(() => {
        //     setupContainsLatLng();
        // })

        this.emitter.on('showModalPainelEventos', data => {
            console.log("teste", data)
            this.orgid = data[0];
            this.evento = data[1];

            this.startdatetime = moment(this.evento.startdatetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            this.startdatetime = moment().subtract(3, this.startdatetime);
            this.startdatetime = moment(this.startdatetime).format("HH:mm:ss");

            console.log("starttime", this.startdatetime, this.evento);

            this.enddatetime = moment(this.evento.enddatetime).format("HH:mm:ss");
            // this.evento.startdatetime = moment(this.evento.startdatetime).format("HH:mm:ss");
            // this.evento.enddatetime = moment(this.evento.enddatetime).format("HH:mm:ss");
            this.evento.previsao_hora_saida = moment(this.evento.previsao_hora_saida).format("HH:mm:ss");

            this.justificativa = this.evento.justificativa;

            this.itinerario_normal = data[2];
            this.pontos = data[3];
            this.center = data[4];
            this.user_id = data[5];

            this.motorista = data[6];

            this.noLoading = true;
            this.getMotivos();
            this.getAdress(this.center.lat, this.center.lng, 'desvio');
            $('#painelEventos').modal('show');
        })
    },
    methods: {
        getMotivos() {
            http.get('v1/quadros-horarios/motivos/' + this.orgid)
                .then(response => {
                    this.motivos = response.data.dados;
                    console.log(this.motivos);
                }).catch(error => console.log(error));
        },
        savePanelEvento() {

            var motivo = $('#painelevento').val();

            if (motivo == null || this.justificativa == null) {
                this.$toast.error(`É necessário informar o motivo e a jsutificativa.`);
                return;
            }

            var data = {
                orgid: this.orgid,
                item_id: this.evento.id,
                motivo: motivo,
                justificativa: this.justificativa,
                user_id: this.user_id
            };

            http.post('events/save', data)
                .then(response => {
                    if (response.data.status) {
                        this.$toast.success(`Salvo com sucesso!`);
                        this.justificativa = '';
                        $('#painelEventos').modal('hide');
                        this.emitter.emit('atualizaSidebarEvents', this.evento.id);
                    } else this.$toast.error(`Não foi possível salvar.`);
                }).catch(error => console.log(error));


            // $('#painelEventos').modal('hide');
        },
        handleClick(event) {

            if (event.latLng?.lat) {
                this.markers[0].position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
                // console.log(event.latLng.lat(), event.latLng.lng(), this.markers)
                this.showInfoWindow = true;

                this.getAdress(event.latLng.lat(), event.latLng.lng(), 'mapa');
                // http.get('events/adress', {
                //     params: {
                //         orgid: this.orgid,
                //         lat: event.latLng.lat(),
                //         lng: event.latLng.lng(),
                //     }
                // }).then(response => {
                //     console.log(response.data.dados);
                // }).catch(error => console.log(error));
            }
        },
        openMarker(id) {
            this.showInfoWindow = true;
            // if (this.showInfoWindow) {
            //     console.log("check true", this.showInfoWindow)
            //     this.showInfoWindow = false;
            // } else {
            //     console.log("check false", this.showInfoWindow)
            //     this.showInfoWindow = true;
            // }
        },
        closeInfoWindow() {
            this.showInfoWindow = false;
            //  if (this.showInfoWindow) {
            //     console.log("check true", this.showInfoWindow)
            //     this.showInfoWindow = false;
            // } else {
            //     console.log("check false", this.showInfoWindow)
            //     this.showInfoWindow = true;
            // }
        },
        getAdress(lat, lng, tipo) {
            http.get('events/adress', {
                params: {
                    orgid: this.orgid,
                    lat: lat,
                    lng: lng,
                }
            }).then(response => {

                if (tipo == 'desvio') this.desvio = response.data.dados;
                if (tipo == 'mapa') this.adress = response.data.dados;

                this.latitude = lat;
                this.longitude = lng;

                // console.log(response.data.dados, lat, lng, tipo);
            }).catch(error => console.log(error));
        }
    },
}
</script> 