import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import { createApp } from 'vue'
import App from './App.vue'

import router from './router.js';
import mitt from 'mitt'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import Toaster from "@meforma/vue-toaster";

import VueGoogleMaps from '@fawmi/vue-google-maps';

const app = createApp(App);
const emitter = mitt() 
app.config.productionTip = false;

app.use(VueSweetalert2);
app.component('v-select', vSelect)

app.config.warnHandler = function (msg, vm, trace) {
    return null
  }

app.use(Toaster);

app.use(router);
app.config.globalProperties.emitter = emitter

app.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyCmerR3fHTFT8ExWzu_TCmLouIW4vJKBEc',
      libraries: "places"
  },
});

app.mount('#app');

// createApp(App).use(router).mount('#app')
