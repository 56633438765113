import { createRouter, createWebHistory } from 'vue-router';

import Login from './views/login/Login.vue';
import Index from './views/operacoes/Index.vue';
// import Operacoes from './views/operacoes/Operacoes.vue';

const routes = [
    // { path: '/', name: 'operacoes', component: Index },
    // { path: '/login', name: 'login', component: Login }
    { path: '/', name: 'login', component: Login },
    { path: '/operacoes', name: 'operacoes', component: Index }
    // { path: '/operacoes', name: 'operacoes', component: Operacoes }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;