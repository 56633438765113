<template>
    <div class="modal fade modal-custom" id="foraescala" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" style="background-color: #292994; border: none">
                    <div class="justify-content-center text-center" align="center" style="margin-left: 40.9%; color: #fff">
                        <h5 class="modal-title justify-content-center text-center" id="exampleModalLabel">
                            Fora de Escala
                        </h5>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="overflow: hidden; background-color: #17175f">
                    <div class="responsive-modal-vehicles" style="
                          max-height: 580px;
                          overflow: auto;
                          overflow-x: hidden;
                          margin-top: -2%;
                        " id="style-scroll-fora-de-escala">
                        <div class="justify-content-center text-center" style="width: 90%; margin-left: 5%">
                            <div class="justify-content-center text-center"
                                style="display: flex; margin-top: 5%; color: #fff">
                                <hr class="line-garage-left" />
                                <h5 style="width: 25%">Fora de Escala</h5>
                                <hr class="line-garage-right" />
                            </div>
                            <div v-for="asset in assets" :key="asset" :id="asset.id" @click="getPlace(asset)"
                                style="display: inline-block">
                                <div class="card-bus-fora-de-escala">
                                    <div class="mensagem-fora-de-escala" v-if="asset.showMsg">
                                        <div>
                                            <p style="margin: 0"><strong>Ultima Posição:</strong></p>
                                            <p style="margin: 0">
                                                {{ new Date(asset.date).toLocaleTimeString('pt-BR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit'
                                                }) }}
                                            </p>
                                            <p style=" 
                                                        margin: 0 3%;
                                                        overflow: hidden;
                                                        width: 95%;
                                                        text-overflow: ellipsis;
                                                        height: 25px;
                                                        text-align: start;
                                                        white-space: nowrap;
                                                        ">
                                                {{ asset.place }}
                                            </p>
                                        </div>
                                    </div>
                                    <svg width="20" height="24" viewBox="0 0 34 38" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M29 18.0313V8.00003H5.00003V18.0313H29ZM23.8438 29.1875C24.4063 29.75 25.125 30.0313 26 30.0313C26.875 30.0313 27.5938 29.75 28.1563 29.1875C28.7188 28.5625 29 27.8438 29 27.0313C29 26.2188 28.7188 25.5313 28.1563 24.9688C27.5938 24.3438 26.875 24.0313 26 24.0313C25.125 24.0313 24.4063 24.3438 23.8438 24.9688C23.2813 25.5313 23 26.2188 23 27.0313C23 27.8438 23.2813 28.5625 23.8438 29.1875ZM5.84378 29.1875C6.40628 29.75 7.12503 30.0313 8.00003 30.0313C8.87503 30.0313 9.59378 29.75 10.1563 29.1875C10.7188 28.5625 11 27.8438 11 27.0313C11 26.2188 10.7188 25.5313 10.1563 24.9688C9.59378 24.3438 8.87503 24.0313 8.00003 24.0313C7.12503 24.0313 6.40628 24.3438 5.84378 24.9688C5.28128 25.5313 5.00003 26.2188 5.00003 27.0313C5.00003 27.8438 5.28128 28.5625 5.84378 29.1875ZM0.968781 27.9688V8.00003C0.968781 4.81253 2.34378 2.68753 5.09378 1.62503C7.84378 0.562531 11.8125 0.0312805 17 0.0312805C22.1875 0.0312805 26.1563 0.562531 28.9063 1.62503C31.6563 2.68753 33.0313 4.81253 33.0313 8.00003V27.9688C33.0313 29.7188 32.3438 31.2188 30.9688 32.4688V36.0313C30.9688 36.5938 30.7813 37.0625 30.4063 37.4375C30.0313 37.8125 29.5625 38 29 38H27.0313C26.4688 38 25.9688 37.8125 25.5313 37.4375C25.1563 37.0625 24.9688 36.5938 24.9688 36.0313V33.9688H9.03128V36.0313C9.03128 36.5938 8.81253 37.0625 8.37503 37.4375C8.00003 37.8125 7.53128 38 6.96878 38H5.00003C4.43753 38 3.96878 37.8125 3.59378 37.4375C3.21878 37.0625 3.03128 36.5938 3.03128 36.0313V32.4688C1.65628 31.2188 0.968781 29.7188 0.968781 27.9688Z"
                                            fill="#028D4D" />
                                    </svg>

                                    <p class="text-font veiculo-fora-de-escala" style=" 
                                                        margin: 0 3%;
                                                        overflow: hidden;
                                                        width: 95% !important;
                                                        height: 25px;">
                                        {{ asset.registration_number }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="background-color: #292994"></div>
            </div>
        </div>
    </div>
</template>
<script>
import "./../../../assets/css/operacoes/foradeescala.css";
import http from "../../../services/http.js";

export default {
    name: "fora-de-escala",
    data: () => {
        return {
            id_empresa: null,
            locations: [],
            garagens: [],
            showMsg: [],
            assets: [],
        };
    },
    mounted() {
        this.emitter.on("foraescala", (data) => {
            this.id_empresa = data;
            this.setAssets();
            $("#foraescala").modal("show");
        });
    },
    methods: {
        setAssets() {
            http
                .get("operation/assets/service", { params: { orgid: this.id_empresa } })
                .then((res) => {
                    this.assets = res.data;
                    this.assets.forEach((asset) => {
                        asset.showMsg = false;
                        asset.place = '';
                    });
                })
                .catch((err) => console.error(err));
        },
        getPlace(asset) {
            asset.showMsg = !asset.showMsg;
            if (asset.showMsg && asset.place == '') {
                http.get("/events/adress", { params: { orgid: this.id_empresa, lat: asset.lat, lng: asset.lng } })
                    .then((res) => {
                        console.log(res)
                        asset.place = res.data.dados;
                    }).catch((err) => console.error(err));
            }
        },
    },
};
</script>