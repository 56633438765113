<template>
    <div class="container">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <img src="./../../assets/img/logo_mobs2.png">
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <div class="card-body">
                    <h6 class="card-title mb-3">LOGIN</h6>
                    <hr style="background: #ccc 1px solid; width: 100%">
                    <div class="row custom-padding">
                        <div class="form-group">
                            <div class="col-md-12">
                                <div class="input-group"> <input class="form-control" type="email" placeholder="E-mail"
                                        v-model="params.email"> </div>
                            </div>
                        </div>
                    </div>
                    <div class="row custom-padding">
                        <div class="form-group">
                            <div class="col-md-12">
                                <div class="input-group"> <input class="form-control" type="password"
                                        placeholder="Senha" v-model="params.password"> </div>
                            </div>
                        </div>
                    </div>
                    <div class=" d-flex flex-column text-center px-5 mt-3 mb-3">
                        <small class="agree-text">Se você não possui acesso, solicite-o abaixo.</small>
                        <a href="#" class="terms">Solicitar Acesso</a>
                    </div>
                    <button v-if="!loading" class="btn back-color-green btn-lg confirm-button text-white"
                        @click="login()">Acessar</button>
                    <span v-if="loading" class="loading-spinner"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './../../assets/css/global.css';
import http from './../../services/http.js';
import axios from 'axios'

import Swal from 'sweetalert2';

export default {
    name: 'Login',
    data: () => {
        return {
            params: {
                email: null,
                password: null,
            },
            loading: false,
        }
    },
    mounted() {
        this.redirect();
    },
    methods: {
        redirect() {
            // window.location.href = 'http://localhost:8080/operacoes';
            // window.location.href = 'https://m2.mobs2system.comlogin';
        },
        login() {
            this.loading = true
            // axios.post('https://api.operacoes.mobs2system.com/api/login', this.params)
                http.post('/login', this.params)
                .then(res => {
                    if (res.data.status == 'success') {
                        var token = res.data.authorization.token;
                        localStorage.setItem("token", token);
                        this.$router.push('/operacoes');
                    } // else this.$router.push('/login');
                    this.loading = false
                }).catch(err => {
                    this.loading = false;
                    this.$toast.error(`Email do usuário ou senha incorreto.`);
                    console.log(err);
                });
        },
    },
}
</script>

<style scoped>
.custom-padding {
    padding: 10px;
}

.loading-spinner {
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid currentColor;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>