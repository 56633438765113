<template>
    <div class="modal fade modal-custom" id="eventoescala" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" style="z-index: 9999">
        <div class="
                        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
                      ">
            <div class="modal-content" style="margin-top: 3%; margin-left: 12%; width: 75%; max-height: 120%; color: #fff;">
                <div class="modal-header modal-header-shadow" style="background-color: #292994; border: none;">
                    <h5 class="modal-title title-font-white" id="exampleModalLabel" style="margin-left: 200px">
                        Adicionar Evento
                    </h5>
                    <div style="display: inline-table; margin-left:10px" v-if="checkEdit">
                        <button @click='changeEdit()'
                            style="background-color: #292994; border: none; margin-left: 10%; padding-right: 20px;">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.2656 3.85938L13.7422 5.38281L10.6172 2.25781L12.1406 0.734375C12.2969 0.578125 12.4922 0.5 12.7266 0.5C12.9609 0.5 13.1562 0.578125 13.3125 0.734375L15.2656 2.6875C15.4219 2.84375 15.5 3.03906 15.5 3.27344C15.5 3.50781 15.4219 3.70312 15.2656 3.85938ZM0.5 12.375L9.71875 3.15625L12.8438 6.28125L3.625 15.5H0.5V12.375Z"
                                    fill="white" />
                            </svg>

                        </button>
                        <button @click='EventoDelete()' style="background-color: #292994; border: none; margin-right: -250%;">
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11.8203 1.32031V3H0.179688V1.32031H3.07031L3.92969 0.5H8.07031L8.92969 1.32031H11.8203ZM1 13.8203V3.82031H11V13.8203C11 14.263 10.8307 14.6536 10.4922 14.9922C10.1536 15.3307 9.76302 15.5 9.32031 15.5H2.67969C2.23698 15.5 1.84635 15.3307 1.50781 14.9922C1.16927 14.6536 1 14.263 1 13.8203Z"
                                    fill="white" />
                            </svg>

                        </button>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form action="">
                        <div class="row">
                            <div class="container-disabled-group">
                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Veículo</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events" v-if="veiculo">{{ veiculo.alias }}</label>
                                        <label class="label-events" v-else></label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Motorista</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events" v-if="motorista">{{ motorista.alias  }}</label>
                                        <label class="label-events" v-else></label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Cobrador</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events" v-if="cobrador">{{ cobrador }}</label>
                                        <label class="label-events" v-else></label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Itinerário</p>
                                    <div class="container-disabled shadow-light label-events"
                                        style="padding-left: 3px; width: 175px;">
                                        {{ objectEvento.atendimento }}
                                    </div>
                                </div>
                            </div>

                            <div class="container-disabled-group" style="margin-top: 20px">
                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Posição</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ objectEvento.posicao }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Linha</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ objectEvento.linha_id }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Viagem</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ objectEvento.viagem }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Saída Prevista</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ new
                                            Date(objectEvento.previsao_hora_saida).toLocaleTimeString('pt-BR', {
                                                hour:
                                                    '2-digit', minute: '2-digit'
                                            }) }}</label>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: left; margin-top: 20px">
                                <p style="font-weight: bold">Tipo</p>
                                <select :disabled="isDisable" class="select-evento" id="tipoevento"
                                    v-model="selectedTipo">
                                    <option value="">Selecione o Tipo</option>
                                    <option v-for="tipo in tipos" :key="tipo.id" :value="tipo.nome">{{ tipo.nome }}
                                    </option>
                                </select>
                            </div>
                            <div style="text-align: left; margin-top: 10px">
                                <p style="font-weight: bold">Motivo</p>
                                <select :disabled="isDisable" class="select-evento" id="tipoevento"
                                    @change="selectEvento($event)" v-model="selectedEvento">
                                    <option value="">Selecione Motivo</option>
                                    <option v-for="motivo in motives" :key="motivo.id" :value="motivo.id">{{ motivo.nome }}
                                    </option>
                                </select>
                            </div>
                            <div style="text-align: left; margin-top: 10px">
                                <p style="font-weight: bold">Sentido</p>
                                <select :disabled="isDisable" class="select-evento" name="" id="tiposentido"
                                    @change="selectSense($event)" v-model="selectedSense">
                                    <option value="">Selecione Sentido</option>
                                    <option v-for="sentido in senses" :key="sentido" :value="sentido.id">
                                        {{ sentido.alias }}
                                    </option>
                                </select>
                            </div>
                            <div class="div-campoTexto-Evento">
                                <p style="font-weight: bold">Descrever Justificativa</p>
                                <textarea :disabled="isDisable" class="campoTexto-evento" id="justificativa" type="text"
                                    v-model="justificativa" :placeholder="placeholder"></textarea>
                                <!-- <textarea class="campoTexto-evento" type="text" :value="text" @input="updateText"
                                    :placeholder="placeholder"></textarea> -->
                            </div>
                            <div class="modal-footer" style="text-align: center; display: inline">
                                <!-- <button type="button" class="btn btngreen" style="font-weight: bold"
                                    @click="Apagar()">
                                    Apagar
                                </button> -->
                                <button v-if="!editStatus" type="button" class="btn btngreen" style="font-weight: bold" @click="EventoSave()">
                                    Salvar
                                </button>
                                <button v-else type="button" class="btn btngreen" style="font-weight: bold" @click="EventoPut()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../../../assets/css/operacoes/edicao-escalas/adicionar-evento-escala.css';
import http from "../../../../services/http.js";
export default {
    name: "adicionarEventoEscala",
    data: () => {
        return {
            text: "",
            placeholder: "Descreva a justificar e motivo para realização do evento gerado",
            motives: [],
            objectEvento: [],
            selectedEvento: null,
            selectedSense: null,
            selectedTipo: null,
            justificativa: null,
            checkEdit: false,
            isDisable: false,
            idItemEdit: null,
            orgid: null,
            senses: [],
            editStatus: false,
            event_id: null,
            tipos: [],
            veiculo: '',
            motorista: ''
        };
    },
    mounted() {
        this.emitter.on("eventoescala", (data) => {
            $("#eventoescala").modal("show");
            this.orgid = data[1];
            this.motorista = data[2];
            this.veiculo = data[3];
            this.getTypes(data[0]);
        });

        this.clear();
    },
    methods: {
        updateText(event) {
            this.text = event.target.value;
        },
        setData(data) {
            this.objectEvento = data[0];
            http.get("v1/quadros-horarios/motivos/" + this.orgid)
                .then((res) => {
                    this.motives = res.data.dados;
                }).catch((err) => console.error(err));


            http.get('/operation/filters', {
                params: {
                    orgid: 152,
                }
            }).then(res => {
                this.senses = res.data.senses;
            }).catch(err => console.log(err));

            // realizando consulta para saber se modal deve ser de edição
            http.get("events/trip/" + this.objectEvento.id + "/events", {
                params: { orgid: this.orgid,}})
                .then((res) => {
                    if (!(res.data.dados.length === 0)) {
                        this.editStatus = true;
                        this.event_id = res.data.dados[0].id;
                        this.checkEdit = res.data.dados;
                        this.isDisable = true;
                        let aux = res.data.dados.length-1;
                        this.justificativa = res.data.dados[aux].descricaotratativa;
                        this.selectedEvento = res.data.dados[aux].motivo;
                        this.selectedSense = res.data.dados[aux].sentido;
                        this.selectedTipo = res.data.dados[aux].tipo;
                        console.log(this.selectedTipo)
                    }
                }).catch((err) => console.error(err));
        },
        changeEdit() {
            this.isDisable = !this.isDisable;
        },
        clear() {

            $('#tipoevento').val('')
            $('#tiposentido').val('')

            this.checkEdit = false;
            this.isDisable = false;
            this.editStatus = false;

            this.justificativa = '';
            this.selectedSense = '';
            this.selectedTipo = '';
            this.selectedEvento = '';
            
        },
        selectEvento(e) {
            this.selectedEvento = e.target.value;
        },
        selectSense(e) {
            this.selectedSense = e.target.value;
        },
        EventoSave() {

            if (this.selectedEvento == '' || this.justificativa == '' || this.selectedSense == ''|| this.selectedTipo == '') { 
                this.$toast.error(`Você precisa preencher todos os campos para salvar.`);
                return;
            }

            var object = {
                assetId: this.objectEvento.asset_id,
                driverid: this.objectEvento.driver_id,
                linhaId: this.objectEvento.linha_id,
                iditinerario: this.objectEvento.id_itinerario,
                quadroshorariositensid: this.objectEvento.id,
                quadrohorarioid: this.objectEvento.quadro_horario_id,
                descricaoevento: this.descricaoevento,
                motivo: this.selectedEvento,
                sentido: this.selectedSense,
                tipo: this.selectedTipo,
                descricaotratativa: this.justificativa,
                orgid: this.orgid
            };
            http.post('/events/trip/' + this.objectEvento.id + '/events', object)
                .then(res => {
                    $('#eventoescala').modal('hide');
                    this.$toast.success(`Salvo com sucesso!`);
                    this.isDisable = false;
                    this.clear();
                    this.objectEvento = [];
                }).catch(err => console.error(err));
        },
        EventoPut() {
            var object = {
                assetId: this.objectEventoasset_id,
                driverid: this.objectEvento.driver_id,
                linhaId: this.objectEvento.linha_id,
                iditinerario: this.objectEvento.id_itinerario,
                quadroshorariositensid: this.objectEvento.id,
                quadrohorarioid: this.objectEvento.quadro_horario_id,
                descricaoevento: this.descricaoevento,
                motivo: this.selectedEvento,
                sentido: this.selectedSense,
                tipo: this.selectedTipo,
                descricaotratativa: this.justificativa,
                orgid: this.orgid
            };

            http.put('/events/trip/' + this.objectEvento.id + '/events/' + this.event_id, object)
                .then(res => {
                    $('#eventoescala').modal('hide');
                    this.$toast.success(`Salvo com sucesso!`);
                    this.isDisable = false;
                    this.clear();
                    this.objectEvento = [];
                }).catch(err => console.error(err));
        },
        EventoDelete() {
            http.delete('/events/trip/' + this.objectEvento.id + '/events/' + this.event_id, 
            {params: { orgid: this.orgid}})
                .then(res => {
                    $('#eventoescala').modal('hide');
                    this.$toast.error(`Deletado com sucesso!`);
                    this.isDisable = false;
                    this.clear();
                    this.objectEvento = [];
                }).catch(err => console.error(err));
        },
        getTypes(data) {
            http.get('events/types', { params: { orgid: this.orgid, }})
            .then(response => {
                this.tipos = response.data.dados;
                this.setData(data);
            }).catch(error => {
                console.error(error)
            });
        }
    },
};
</script>