<template>
    <div class="modal" tabindex="-1" role="dialog" id="alterarViagem" style="z-index: 9999">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content shadow-light"
                style="margin-top: 3%; width: 100%; height: auto; border: none; border-radius: 15px;">
                <div class="modal-header header-exibir-boletim modal-color-alterar-viagem shadow-light">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <h5 class="modal-title" style="color: #fff;">Alterar Viagem</h5>
                        </div>
                    </div>
                </div>
                <div class="modal-body modal-color-alterar-viagem" align="center">
                    <div class="card card-alterar-viagem shadow-light"
                        style="margin-bottom: 5%; margin-top: -1%;width: 95%;" v-for="(dado, bloco) in viagens" :key="bloco"
                        :class="{ 'mb-0': bloco === viagens.length - 1 }">
                        <h5 class="card-header modal-color-alterar-viagem text-alterar-viagem" style="border: none;">
                            Posição {{ dado.posicao }}
                        </h5>
                        <div class="card-body modal-color-alterar-viagem">
                            <div class="row justify-content-center text-center" style="width: 95%;">
                                <div class="col-2 select-alterar">
                                    <p class="text-alterar-viagem">Veiculo</p>
                                    <v-select class="form-v-select form-select-alterar-viagem-drivers"
                                            id="vs-select-alterarviagem" :options="vehicles" label="alias"
                                            v-model="selectedVehicle[bloco]" align="center"></v-select>
                                </div>
                                <div class="col-2 select-alterar">
                                    <p class="text-alterar-viagem">Motorista</p>
                                    <v-select class="form-v-select form-select-alterar-viagem-drivers"
                                            id="vs-select-alterarviagem" :options="drivers" label="alias"
                                            v-model="selectedDriver[bloco]"></v-select>
                                </div>
                                <div class="col-2 select-alterar">
                                    <p class="text-alterar-viagem">Cobrador</p>
                                    <v-select class="form-v-select form-select-alterar-viagem-drivers"
                                            id="vs-select-alterarviagem" :options="Cobradores" label="alias"
                                            v-model="selectedCobrador[bloco]"></v-select>
                                </div>
                                <div class="col-6 justify-content-center text-center">
                                    <p class="text-alterar-viagem"
                                        style="text-align: start; padding-left: 4%; color: #fff;">Atendimento</p>
                                    <select name="" id="" class="form-select form-select-alterar-viagem"
                                        v-if="dado.sentido == 'i'" @change="setPoints(bloco, $event.target.value)"
                                        v-model="selectedItinerary[bloco]">
                                        <option :value="itinerario.id" v-for="itinerario in itinerariosIda"
                                            :key="itinerario.id">{{ itinerario.nome }}</option>
                                    </select>
                                    <select name="" id="" class="form-select form-select-alterar-viagem" v-else
                                        @change="setPoints(bloco, $event.target.value)" v-model="selectedItinerary[bloco]">
                                        <option :value="itinerario.id" v-for="itinerario in itinerariosVolta"
                                            :key="itinerario.id">{{ itinerario.nome }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 6%;">
                                <div class="col-6 d-flex align-items-center">
                                    <div style="width: 38%;">
                                        <p class="mb-0 text-alterar-viagem" style="text-align: start; color: #fff;">
                                            Ponto Inicial</p>
                                        <select v-if="atividade != 'saidagaragem'" name="" id="" class="form-select form-select-alterar-viagem"
                                            v-model="selectedPointInitial[bloco]" style="height: 36px;">
                                            <option :value="ponto.id" v-for="ponto in pontos_itinerarios[bloco]"
                                                :key="ponto.id">{{ ponto.nome }}</option>
                                        </select>
                                        <select v-else name="" id="" class="form-select form-select-alterar-viagem"
                                            v-model="selectedPointInitial[bloco]" style="height: 36px;">
                                            <option :value="ponto.id" v-for="ponto in pontosGaragem[bloco]"
                                                :key="ponto.id">{{ ponto.nome }}</option>
                                        </select>
                                    </div>
                                    <div style="width: 28%; margin-left: 3%;">
                                        <p class="mb-0 text-alterar-viagem"
                                            style="text-align: start; color: #fff; margin-top: -23%;">Saída Programada
                                        </p>
                                        <input type="time" class="form-control inputs-alterar-viagem" style="height: 36px; font-size: 0.94rem !important;"
                                            v-model="selectedSaida[bloco]">
                                    </div>
                                    <div style="width: 25%; margin-left: 3%;">
                                        <p class="mb-0 text-alterar-viagem" style="text-align: start; color: #fff;">Saída
                                            Real</p>
                                        <label class="card-saidasreais-alterar-viagem" v-if="dado.hora_saida_real">
                                            {{ new
                                                Date(dado.hora_saida_real).toLocaleTimeString('pt-BR', {
                                                    hour: '2-digit',
                                                    minute: '2-digit'
                                                }) }}
                                        </label>
                                        <label class="card-saidasreais-alterar-viagem" v-else>00:00</label>
                                    </div>
                                </div>


                                <div class="col-6 d-flex align-items-center">
                                    <div style="width: 38%;">
                                        <p class="mb-0 text-alterar-viagem" style="text-align: start; color: #fff;">
                                            Ponto
                                            Final</p>
                                        <select v-if="atividade != 'retornogaragem'" name="" id="" class="form-select form-select-alterar-viagem"
                                            style="height: 36px;" v-model="selectedPointFinal[bloco]">
                                            <option :value="ponto.id" v-for="ponto in pontos_itinerarios[bloco]"
                                                :key="ponto.id">{{
                                                    ponto.nome }}</option>
                                        </select>
                                        <select v-else name="" id="" class="form-select form-select-alterar-viagem"
                                            style="height: 36px;" v-model="selectedPointFinal[bloco]">
                                            <option :value="ponto.id" v-for="ponto in pontosGaragem[bloco]"
                                                :key="ponto.id">{{
                                                    ponto.nome }}</option>
                                        </select>
                                    </div>
                                    <div style="width: 28%; margin-left: 3%;">
                                        <p class="mb-0 text-alterar-viagem"
                                            style="text-align: start; color: #fff; margin-top: -23%;">Chegada Programada
                                        </p>
                                        <input type="time" class="form-control inputs-alterar-viagem" style="height: 36px; font-size: 0.94rem !important"
                                            v-model="selectedChegada[bloco]">
                                    </div>
                                    <div style="width: 25%; margin-left: 3%;">
                                        <p class="mb-0 text-alterar-viagem"
                                            style="text-align: start; color: #fff; margin-top: -23%;">Chegada Real</p>
                                        <label class="card-saidasreais-alterar-viagem" v-if="dado.hora_chegada_real">
                                            {{ new Date(dado.hora_chegada_real).toLocaleTimeString('pt-BR', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            }) }}
                                        </label>
                                        <label class="card-saidasreais-alterar-viagem" v-else>00:00</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer modal-color-alterar-viagem justify-content-center text-center">
                    <button @click="saveTrip" type="submit" class="btn btn-alterar-viagem-footer">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../../assets/css/operacoes/edicao-escalas/alterarViagem.css';
import http from "../../../../services/http.js";

export default {
    name: "alterarViagem",
    data: () => {
        return {
            viagens: [],
            itinerarios: [],
            itinerariosIda: [],
            itinerariosVolta: [],
            pontos_itinerarios: [],
            change_initialPoint: [],
            selectedPointInitial: [],
            selectedPointFinal: [],
            selectedItinerary: [],
            selectedChegada: [],
            selectedSaida: [],
            listaObjetos: [],
            selectedVehicle: [],
            selectedDriver: [],
            selectedCobrador: [],
            drivers: [],
            vehicles: [],
            pontosGaragem: [],
            atividade: null,
            orgid: null,
            aux: null,
            padrao: false
        };
    },
    mounted() {
        this.emitter.on("alterarViagem", (data) => {
            this.padrao = data[2];
            this.viagens = [];
            this.itinerarios = [];
            this.itinerariosIda = [];
            this.itinerariosVolta = [];
            this.pontos_itinerarios = [];
            this.change_initialPoint = [];
            this.selectedPointInitial = [];
            this.selectedPointFinal = [];
            this.selectedItinerary = [];
            this.selectedSaida = [];
            this.selectedChegada = [];
            this.pontosGaragem = [];
            this.selectedVehicle = [];
            this.selectedDriver = [];
            this.selectedCobrador = [];
            this.orgid = data[1];
            this.aux = data[0];
            this.atividade = this.aux[0].atividade;
            this.viagens = data[0];
            $('#alterarViagem').modal('show');
            this.setDados(data[0]);
        });

    },
    methods: {
        setDados(data) {
            const linha_id = data[0].linha_id;
            http.get('/operation/filters', {
                params: {
                    orgid: this.orgid
                }
            }).then(res => {
                this.vehicles = res.data.vehicles;
                this.drivers = res.data.drivers;
            }).catch(err => console.log(err));

            http.get('operation/itinerary', {
                params: {
                    orgid: this.orgid,
                    linha_id: linha_id
                }
            }).then(response => {
                this.itinerarios = response.data.dados;
                this.itinerariosIda = [],
                    this.itinerariosVolta = [],
                    this.itinerarios.forEach(itinerario => {
                        const destino = itinerario.sentido === "i" ? this.itinerariosIda : this.itinerariosVolta;
                        destino.push(itinerario);
                    });
                this.setVModels(data)
            }).catch(error => { console.error(error) });
        },
        setVModels(data) {
            let contAux = 0;
            data.forEach(e => {
                this.selectedVehicle[contAux] = this.vehicles.find(asset => asset.id === e.asset_id);;
                this.selectedDriver[contAux] = this.drivers.find(driver => driver.id === e.driver_id);;
                this.selectedCobrador[contAux] = e.cobrador_id;
                this.selectedItinerary[contAux] = e.id_itinerario;
                this.selectedPointFinal[contAux] = e.id_origem_chegada;
                this.selectedPointInitial[contAux] = e.id_origem_saida;
                this.selectedSaida[contAux] = e.previsao_hora_saida.slice(11, 16).padStart(5, '0');
                this.selectedChegada[contAux] = e.previsao_hora_chegada.slice(11, 16).padStart(5, '0');
                this.setPoints(contAux, this.selectedItinerary[contAux])
                contAux += 1;
            });
        },
        setPoints(bloco, option) {
            let itinerarioObj = this.itinerarios.find(itinerario => itinerario.id === option);
            http.get('operation/points/itinerary', {
                params: {
                    orgid: this.orgid,
                    id_points: itinerarioObj.pontos_parada_ids,
                    id_itinerario: itinerarioObj.id,
                    tipo: this.atividade
                }
            }).then(response => {
                this.pontos_itinerarios[bloco] = response.data.dados.pontos_itinerarios;
                this.pontosGaragem[bloco] = response.data.dados.pontos_garagem;
            }).catch(error => { });
        },
        saveTrip() {
            let listaObjetos = [];
            for (let i = 0; i < this.viagens.length; i++) {
                let objeto = {
                    trip_id: this.viagens[i].id,
                    posicao: this.viagens[i].posicao,
                    asset_id: this.selectedVehicle[i],
                    driver_id: this.selectedDriver[i],
                    cobrador_id: this.selectedCobrador[i],
                    previsao_hora_chegada: this.selectedChegada[i],
                    previsao_hora_saida: this.selectedSaida[i],
                    id_itinerario: this.selectedItinerary[i],
                    id_origem_chegada: this.selectedPointFinal[i],
                    id_origem_saida: this.selectedPointInitial[i]
                };
                listaObjetos.push(objeto);
            }
            console.log("this.padrao", this.padrao);
            if (this.padrao == true) {
                http.post('scales/edit/trips', { orgid: this.orgid, trips: listaObjetos })
                    .then(response => {
                        this.$toast.success(`Salvo com sucesso!`);
                        this.emitter.emit('updateViagensLinhas');
                        $('#alterarViagem').modal('hide');
                    }).catch(error => {
                        console.error(error)
                        this.$toast.error(`Dados não salvos.`);
                    });
            }else {
                http.post('operation/edit/trips', { orgid: this.orgid, trips: listaObjetos })
                    .then(response => {
                        this.$toast.success(`Salvo com sucesso!`);
                        this.emitter.emit('updateViagensLinhas');
                        $('#alterarViagem').modal('hide');
                    }).catch(error => {
                        console.error(error)
                        this.$toast.error(`Dados não salvos.`);
                    });
            }            
        },
        buttonDisabled() {
            var OK = true;
            for (let i = 0; i < this.viagens.length - 1; i++) {
                if (this.selectedChegada[i] != null && this.selectedSaida[i] != null && this.selectedItinerary[i] != null && this.selectedPointFinal[i] != null && this.selectedPointInitial[i] != null) { OK = OK && true } else { OK = OK && false }
            }
            return OK
        },

    },
};
</script>
