<template>
    <div class="modal fade " id="replay" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="background-color: #292994; border-radius: 20px;">
                <div class="modal-header" style="border-color: #292994 !important">
                    <!-- <input type="text" class="form-control" placeholder="Buscar Endereço" v-model="search_adress" @change="searchAdress($event)"> -->
                    
                    <div class="col-md-6">

                        <v-select v-if="Adress" class="form-control" placeholder="Buscar Endereço"
                            :options="Adress" align="start" label="description" id="endereco"
                            v-model="selectedAdress"
                            style="max-width: 88%; border-top-left-radius: 5px; border-bottom-left-radius: 5px; height: 40px; z-index: 100000; position: absolute;"
                            @input="searchAdress"
                            @update:modelValue="filterAdress()"
                            >
                        </v-select>



                        <!-- <input id="endereco" ref="endereco" type="text" class="form-control" placeholder="Buscar Endereço" v-model="search_adress" v-on:keyup="searchAdress()">
                                             
                        <div style="overflow-y: auto; height: 200px; z-index: 100000; position: absolute;">
                            <ul id="autocomplete" class="list-group" style="width: 400px"></ul>
                        </div> -->

                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" placeholder="Pesquisar Veículo">
                    </div>
                    
                </div>


                <div class="modal-body" style="padding:none; overflow: hidden;" v-if="noLoading">
                    <div class="col-md-4" style="background-color: #17175F; color: #fff; z-index: 10000; margin-top: 10px; position: absolute; padding: 20px">
                        Atualizando a informação
                    </div>
                    <GMapMap
                        :click="true"                            
                        :center="center"
                        :zoom="14"
                        map-type-id="terrain"
                        style="width: 100%; height: 300px">
                        <!-- <GMapAutocomplete
                            placeholder="This is a placeholder"
                            @place_changed="setPlace"
                            v-model="search_adress"
                            >
                        </GMapAutocomplete>        -->
                        <GMapCluster>
                            <GMapPolyline                                     
                                :path="itinerario_ida"
                                :editable="false"
                                :options="{ strokeColor: 'green' }"
                                ref="mapPolygon"
                                />
                            <GMapPolyline                                     
                                :path="itinerario_volta"
                                :editable="false"
                                :options="{ strokeColor: 'blue' }"
                                ref="mapPolygon" 
                                @click="handleClick"
                                />                                    
                        </GMapCluster>
                        <GMapMarker 
                            :key="index"
                            v-for="(m, index) in markers"
                            :position="m.position" :clickable="true" 
                            @click="openMarker()" >
                            <GMapInfoWindow
                                    :closeclick="true"
                                    @closeclick="closeInfoWindow()"
                                    :opened="showInfoWindow"
                                >
                                <div align="left" style="width: 180px; height: 130px !important; float: left; font-size: 10px; overflow:hidden !important">
                                    <p>Data: {{ position.Timestamp }}</p>
                                    <p>Latitude: {{ position.Latitude }}</p>
                                    <p>Longitude: {{ position.Longitude }}</p>
                                    <p>Longitude: {{ position.Odometer }}</p>
                                    <p>Endereço: {{ endereco }}</p>
                                </div>
                            </GMapInfoWindow>
                            <GMapInfoWindow
                                    :closeclick="true"
                                    @closeclick="closeInfoWindow2()"
                                    :opened="showInfoWindow2"
                                >
                                <!-- <div align="left" style="width: 180px; height: 130px !important; float: left; font-size: 10px; overflow:hidden !important">
                                    <p>Data: {{ position.Timestamp }}</p>
                                    <p>Latitude: {{ position.Latitude }}</p>
                                    <p>Longitude: {{ position.Longitude }}</p>
                                    <p>Longitude: {{ position.Odometer }}</p>
                                    <p>Endereço: {{ endereco }}</p>
                                </div> -->
                            </GMapInfoWindow>
                        </GMapMarker>
                        <GMapMarker 
                            :key="index"
                            :position="marker_endereco[0].position" :clickable="true" 
                            @click="openMarker()" >                            
                            <!-- <GMapInfoWindow
                                    :closeclick="true"
                                    @closeclick="closeInfoWindow2()"
                                    :opened="showInfoWindow2"
                                >
                                <div align="left" style="width: 180px; height: 130px !important; float: left; font-size: 10px; overflow:hidden !important">
                                    <p>Data: {{ position.Timestamp }}</p>
                                    <p>Latitude: {{ position.Latitude }}</p>
                                    <p>Longitude: {{ position.Longitude }}</p>
                                    <p>Longitude: {{ position.Odometer }}</p>
                                    <p>Endereço: {{ endereco }}</p>
                                </div>
                            </GMapInfoWindow> -->
                        </GMapMarker>
                    </GMapMap>

                    <div class="row">
                        <div class="col-md-4 offset-md-1">

                            <div class="row">
                                <div class="col-md-12">
                                    <h3 class="text-white" style="margin-top: 30px">Filtro Replay 
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18.5 9.5V0.5H21.5234V3.52344H27.5V6.47656H21.5234V9.5H18.5ZM27.5 15.4766H12.5234V12.5234H27.5V15.4766ZM6.47656 9.5H9.5V18.5H6.47656V15.4766H0.5V12.5234H6.47656V9.5ZM15.4766 27.5H12.5234V18.5H15.4766V21.5234H27.5V24.4766H15.4766V27.5ZM0.5 3.52344H15.4766V6.47656H0.5V3.52344ZM0.5 21.5234H9.5V24.4766H0.5V21.5234Z" fill="white"/>
                                        </svg>
                                    </h3>
                                </div>
                                <div class="col-md-12">
                                    <select name="" id="" class="form-control">
                                        <option value="0">Selecionar Linhas</option>
                                        <option :value="linha.id" v-for="linha in linhas" :key="linha.id" >{{ linha.nome_fantasia }}</option>
                                    </select>
                                </div>
                                <div class="col-md-12">
                                    <input type="checkbox" class="pull-right"> Mostrar Raio
                                </div>
                                <div class="col-md-12">
                                    <input type="checkbox" class="pull-right"> Mostrar Parada
                                </div>
                                <div class="col-md-12">
                                    <input type="checkbox" class="pull-right"> Mostrar Trackpoint
                                </div>
                            </div>                            

                        </div>    
                        <div class="col-md-6" style="margin-top: 30px; background: #fff; border-radius: 15px;">
                            <select name="" id="" class="form-control">
                                <option value="0">Selecione um Veículo</option>
                                <option :value="veiculo.id" v-for="veiculo in veiculos" :key="veiculo.id" >{{ veiculo.registration_number }}</option>
                            </select>              
                        </div>
                    </div>
                    
                </div>


                <div class="modal-footer" style="text-align: center; display: inline">
                    <!-- <button type="button" class="btn btngreen" style="font-weight: bold; height: 50px; width: 115px; font-size: large;" @click="savePanelEvento()"> Concluir </button>   -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './../../../../assets/css/operacoes/aovivo-replay/aovivo.css';
import http from '../../../../services/http.js';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: "AIzaSyCmerR3fHTFT8ExWzu_TCmLouIW4vJKBEc",
  version: "weekly",
//   libraries: ["places"]
});

import { ref, onMounted } from "vue";

const myMapRef = ref();
const mapPolygon = ref();


export default {
    name: 'MenuContexto',
    data() {
        return {
            orgid: null,
            date: null,
            itinerario: [],
            center: {
                lat: '', 
                lng: '',
                // lat: -8.057655393429243, lng: -34.87229640503695
            },
            class_color: null,
            showInfoWindow: false,
            showInfoWindow2: false,
            markers: [
                {
                    position: {
                        lat: '', lng: ''
                        // lat: -8.057655393429243, lng: -34.87229640503695
                    },
                }
            ],
            position: [],
            noLoading: false,
            showInfoWindow: false,
            trip: [],
            endereco: null,
            class: null,
            linhas: [],
            veiculos: [],
            itinerario_ida: [],
            itinerario_volta: [],
            change_list: [],
            search_adress: '',
            google: '',
            validateSelection: null,
            Adress: [],
            marker_endereco: [
                {
                    position: {
                        lat: '', lng: ''
                        // lat: -8.057655393429243, lng: -34.87229640503695
                    },
                }
            ]
        };
    },
    mounted() {

        this.emitter.on('replay', data => {
           this.orgid = data[0];
           this.trip = data[1];
        //    this.itinerario = data[2];
           this.itinerario_ida = data[2];
           this.itinerario_volta = data[3];
           this.center = data[4];
           this.date = data[5];
           this.position = data[6];
           this.class = data[7];

           this.class = this.class == 'v' ? 'blue' : 'green';

           var lat = null;
           var lng = null;

           lat = parseFloat(this.position.Latitude);
           lng = parseFloat(this.position.Longitude);

           this.getAdress(lat, lng, null);

        //    console.log('itinerario', this.itinerario)

        // var localizacao = { lat: parseFloat(lat), lng: parseFloat(lng) };

        //    this.markers.push(localizacao);
        //    this.markers.position.lng = lng;
           this.markers[0].position.lat = lat;
           this.markers[0].position.lng = lng;

           this.noLoading = true;

           $('#replay').modal('show');

           this.lines();
           this.vehicles();
           this.itinerarioLines();
        })

        $('#lista').on('click', function() {
            this.filterAdress(2)
        });

        // const autocomplete = new google.maps.places.Autocomplete(this.$refs["endereco"]);

        // let map = new VueGoogleMaps.gmapApi.maps.places.Autocomplete(this.$refs["endereco"]); 

        // var autocomplete;
        // autocomplete = new google.maps.places.Autocomplete(this.searchAdress, option);
        // autocomplete.addListener('place_changed', this.searchAdress());

    },
    methods: {
        handleClick(event) {  
            if (event.latLng?.lat) {
                this.markers[0].position = {lat: event.latLng.lat(), lng: event.latLng.lng()};
                this.showInfoWindow = true;

                this.getAdress(event.latLng.lat(), event.latLng.lng(), 'mapa');
            }
        },
        openMarker(id) {
            this.showInfoWindow = true;
        },
        closeInfoWindow() {
            this.showInfoWindow = false;
        },
        closeInfoWindow2() {
            this.showInfoWindow2 = false;
        },
        getAdress(lat, lng, tipo) {
            http.get('events/adress', {
                params: {
                    orgid: this.orgid,
                    lat: lat,
                    lng: lng,
                }
            }).then(response => {

                this.endereco = response.data.dados;
            }).catch(error => console.log(error));
        },
        lines() {
            http.get('aovivo/lines', {
                params: {
                    orgid: this.orgid
                }
            }).then(response => {
                this.linhas = response.data.linhas;
                console.log('linhas', response.data.linhas);
            }).catch(error => console.log(error));
        },
        vehicles() {
            http.get('aovivo/vehicles', {
                params: {
                    orgid: this.orgid
                }
            }).then(response => {
                this.veiculos = response.data.veiculos;
                console.log('veiculos', response.data.veiculos);
            }).catch(error => console.log(error));
        },
        itinerarioLines() {
            http.get('aovivo/itinerary/lines', {
                params: {
                    orgid: this.orgid,
                }
            }).then(response => {
                this.lines = response.data.linhas;
            }).catch(error => console.log(error));
        },
        changeList(linha_id) {
            // console.log('linha_id', linha_id);
            $('#linha_'+linha_id).hide();
            // $('#linha_'+linha_id).show();
        },
        searchAdress(value) {

            var busca = value.target.value;
            // console.log("teste", busca);

            this.search_adress = busca;

            this.Adress = [];

            let self = this;
            loader
            .load()
            .then((google) => {

                var geocoder;
                geocoder = new window.google.maps.Geocoder();

                var autocomplete;
                var location;
                var description;
                var listAdress = [];

                // $("#autocomplete li").remove();
                
                autocomplete = new window.google.maps.places.AutocompleteService();

                autocomplete.getPlacePredictions({ input: this.search_adress }, function (predictions, status) {                    
                    if (status == google.maps.places.PlacesServiceStatus.OK) {
                        var matchAddress = predictions[0].structured_formatting.secondary_text; 
                        // console.log('matchAddress', matchAddress, 'predictions', predictions[0].reference);

                        var num = 0;
                        // var element = document.getElementById('autocomplete');
                        // var lista = "";
                        predictions.forEach(e => {
                            if (e.structured_formatting.secondary_text != undefined) {
                                // listAdress.push({ id: num, description: e.description });
                                listAdress.push({ id: num, description: e.description, reference: predictions[0].reference });
                                
                                // lista += "<li id='lista' :value='"+num+"' class='list-group-item' v-on:click='filterAdress("+num+")'>"+e.description+"</li>";
                                num += 1;
                            }
                        });

                        // $('#autocomplete').append(lista);

                        self.Adress = listAdress;

                        // var googlePlacesService = new window.google.maps.places.PlacesService(document.getElementById("endereco"));
                        // googlePlacesService.getDetails({
                        //     reference: predictions[0].reference
                        // }, function(details, status){
                        //     if(details){
                        //         location = details.geometry.location.toString();
                        //     }
                        // });
                        // description = predictions[0].description;
                    }
                });


            }).catch(error => console.log(error));
        },
        filterAdress() {
            console.log("teste")
            // console.log("teste", this.selectedAdress.description, document.getElementById("endereco"))


            // var location;
            var lat;
            var lng;

            let self = this;
            loader
            .load()
            .then((google) => {

                
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({'address': self.selectedAdress.description}, function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        // console.log("results", results[0].geometry.location.toString(), results[0].geometry.location.lat());
                        // location = results[0].geometry.location.toString();
                        lat = results[0].geometry.location.lat();
                        lng = results[0].geometry.location.lng();

                        self.marker_endereco[0].position = {lat: lat, lng: lng};

                        self.center.lat = parseFloat(lat);
                        self.center.lng = parseFloat(lng);
                        self.showInfoWindow2 = true;

                        console.log('coords', lat, lng)
                    } else {
                        console.log("nao encontrou");
                    }
                });

                // var googlePlacesService = new window.google.maps.places.PlacesService(self.selectedAdress.description);
                // googlePlacesService.getDetails({
                //     reference: self.selectedAdress.reference
                //     // reference: predictions[0].reference
                // }, function(details, status){
                //     if(details){
                //         location = details.geometry.location.toString();
                //         arr = location.split(location, ',');
                //         console.log('arr', arr)
                //         lat = arr[0];
                //         lng = arr[1];
                //     }
                // });
                // description = predictions[0].description;

                


            }).catch(error => console.log(error));
        }
    },
}
</script>