<template>
    <div class="custom-top">
        <div class="row" align="center" style="margin-top: 20px;">
            <div class="col card card-blue border-card shadow-light cards-big-top" style="padding-top: 10px;">
                <p class="custom-top-text text-font custom-p-top">Previsto -> {{ totaltrips.day }}</p>
                <h1 class="custom-top-h1-2">{{ totaltrips.nowtime }}</h1>
                <p class="custom-top-text-2 text-font">Viagens</p>
            </div>
            <div class="col card card-green card-align-left border-card shadow-light cards-big-top" style=" margin-left: 15px;">
                <p class="custom-p-top">
                    <span class="custom-top-text text-font">Viagens Realizadas</span>
                    <span class="text-font" style="display: block; margin-top: -4px;">{{ totaltrips.realized }}</span>
                </p>
                <h1 class="custom-top-h1-2 text-font" style="margin-top: -5%;">{{ trip_realized }}%</h1>
            </div>
            <div class="card-gray card-align-left border-card card-max shadow-light" style="margin-left: 15px !important">
                <div class="row" style="margin-top: 5px">
                    <div class="sub-card card-green card-align-left-2 card-align-top border-card shadow-light">
                        <p class="custom-top-text text-font custom-p-top">Partidas Realizadas -> {{ totals_realized }}</p>
                        <h1 class="custom-top-h1-2 text-font">{{ total_partidas }}%</h1>
                    </div>
                    <div class="sub-card-minimun card-green card-align-left card-align-top border-card shadow-light">
                        <p class="custom-top-text-2 text-font custom-p-top">Pontual -> {{ realized.pontuality_quantity }}</p>
                        <h2 class="text-font custom-top-h1-3">{{ pontualy }}%</h2>
                    </div>
                    <div class="sub-card-minimun card-red card-align-left card-align-top border-card shadow-light">
                        <p class="custom-top-text-2 text-font custom-p-top">Atrasado -> {{ realized.delay_quantity }}</p>
                        <h2 class="text-font custom-top-h1-3">{{ delay }}%</h2>
                    </div>
                    <div class="sub-card-minimun card-blue card-align-left card-align-top border-card shadow-light">
                        <p class="custom-top-text-2 text-font custom-p-top">Adiantado -> {{ realized.advance_quantity }}</p>
                        <h2 class="text-font custom-top-h1-3">{{ advance }}%</h2>
                    </div>
                    <!-- <div class="sub-card-minimun card-green card-align-left card-align-top border-card">
                        <span class="text-sub-card-minimun">Adiantado -> {{ realized.advance_quantity }}</span>
                        <h5>{{ advance }}%</h5>
                    </div> -->
                </div>
            </div>
            <div class="card card-without-col-80 card-align-left border-card card-default">
                <table style="display:table-caption">
                    <div class="row" style="margin-top: 22px; margin-left: -25px; border: none;">
                        <div>
                            <Div class="form-check form-top">
                                <label class="checkbox-inline label-top">
                                    <input class="form-check-input form-check-top form-check-input-topo" for="inlineCheckbox1" type="checkbox"
                                        name="type_trip" value="" id="SG"
                                        @click="filterTypeTrip('SG')"><strong class="label-strong-top">SG</strong>
                                </label>
                            </Div>

                            <Div class="form-check form-top">
                                <label class="checkbox-inline label-top">
                                    <input class="form-check-input form-check-top form-check-input-topo" type="checkbox" name="type_trip"
                                        value="" id="RG" @click="filterTypeTrip('RG')"><strong class="label-strong-top">RG</strong>
                                </label>
                            </Div>
                        </div>
                        <div>
                            <Div class="form-check form-top">

                                <label class="checkbox-inline label-top">
                                    <input class="form-check-input form-check-top form-check-input-topo" type="checkbox" name="type_trip"
                                        value="" id="VR" @click="filterTypeTrip('VR')"><strong class="label-strong-top">VR</strong>
                                </label>
                            </Div>
                        
                            <Div class="form-check form-top">
                                <label class="checkbox-inline label-top">
                                    <input class="form-check-input form-check-top form-check-input-topo" type="checkbox" name="type_trip"
                                        value="" id="VE" @click="filterTypeTrip('VE')"><strong class="label-strong-top">VE</strong>
                                </label>
                            </Div>
                        </div>
                            <div class="form-check form-top">
                                <label class="checkbox-inline label-top" style="margin-left: 12px !important">
                                    <input class="form-check-input form-check-top form-check-input-topo" type="checkbox" value="" id="VNR"
                                        @click="filterTypeTrip('VNR')"><strong class="label-strong-top">VNR</strong>
                                </label>
                            </div>
                    </div>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
// import eventBus from '../../eventBus.js';
import './../../assets/css/operacoes/topo.css';
import http from '../../services/http.js';

import {ref, onMounted} from "vue";
// import { Loader } from '@googlemaps/js-api-loader';
import {addSeconds, differenceInMinutes, format, parseISO} from "date-fns";

// const loader = new Loader({
//   apiKey: "AIzaSyCmerR3fHTFT8ExWzu_TCmLouIW4vJKBEc",
//   version: "weekly",
// //   libraries: ["places"]
// });

// import VueGoogleMaps from '@fawmi/vue-google-maps';

// import socket from '../../services/socket-client.js';

// const directionsService = VueGoogleMaps;
// const directionsService = new window.google.maps.DirectionsService();

export default {
    name: 'Top',
    // mixins: [
    //     socket
    // ],
    data: () => {
        return {
            user: {
                email: null,
                id: null,
                group_id: null
            },
            date: moment().format('YYYY-MM-DD'),
            realized: {
                advance_quantity: null,
                pontuality_quantity: null,
                delay_quantity: null
            },
            totaltrips: {
                day: null,
                nowtime: null,
                realized: null,
                warning: null,
            },
            trip_realized: null,
            advance: null,
            pontualy: null,
            delay: null,
            totals_realized: null,
            warning: null,
            params: {
                date: '',
                orgid: '',
                rangetime: '',
                line_group: '',
                terminal: '',
                line: '',
                sense: '',
                position: '',
                vehicle: '',
                type: '',
            },
            events: [],
            comprometidas: [],
            count_compromised: 0
        }
    },
    mounted() {

        // if change of date
        this.emitter.on('changeOfDate', data => {
            // console.log("get change of date: " + data)
            this.date = data;

            this.params.date = this.date

            // this.getTotals();
        })
        // if change of company
        this.emitter.on('changeOfCompany', data => {
            this.params.date = this.date
            this.params.orgid = data
            this.getTotals(); // RETIRAR COM O SOCKET

            // COMPROMETIDAS 
            this.compromised();
        })
        this.emitter.on('newTotais', data => {
            console.log("newTotais", data)
            this.params = data;
            // this.getTotals();
        })

        this.emitter.on('socketTotals', data => {
            console.log('socketTotals received', moment().format('DD/MM/YYYY HH:mm:ss'))
            // console.log('valores para conferencia de totais ', data)
            this.totaltrips = data.totaltrips[0];                
            this.totals_realized = data.totals_realized;
            this.trip_realized = data.trip_realized;
            this.advance = data.advance;
            this.delay = data.delay;
            this.pontualy = data.pontualy;

            this.realized.pontuality_quantity = 0;
            this.realized.delay_quantity = 0;
            this.realized.advance_quantity = 0;
            if (data.realized.length == 3) this.realized.pontuality_quantity = data.realized[2].qtd;
            if (data.realized.length >= 2) this.realized.delay_quantity = data.realized[1].qtd;
            if (data.realized.length != 0) this.realized.advance_quantity = data.realized[0].qtd;

            this.total_partidas = data.total_partidas;

        })
    },
    methods: {
        getTotals() {

            // this.emitter.emit('actionTotals');
            // console.log("action totals")

            this.params.start_time = moment().format('DD/MM/YYYY HH:mm:ss')
            var params = this.params
                        
            http.get('/operation/totals', {
                params
            }).then(res => {
                
                this.totaltrips = res.data.totaltrips[0];                
                this.totals_realized = res.data.totals_realized;
                this.trip_realized = res.data.trip_realized;
                this.advance = res.data.advance;
                this.delay = res.data.delay;
                this.pontualy = res.data.pontualy;
                this.total_partidas = res.data.total_partidas;

            }).catch(err => console.log(err));
        },
        filterTypeTrip(type) { // esse método serve para realizar o filtro na table


            var typeTrips = ['SG', 'VR', 'RG', 'VE', 'VNR'];
            for (var i = 0; i < typeTrips.length; i++) {
                if (typeTrips[i] != type) $('#' + typeTrips[i]).prop("checked", false);
            }

            if ($('#' + type).is(":checked")) this.emitter.emit('filterTypeTrips', type);
            else {
                type = null;
                this.emitter.emit('filterTypeTrips', null);
            }

            this.params.type = type != null ? type : null;
            // this.getTotals();

        },
        // CÓDIGO DE COMPROMETIDAS DESATIVADO AGUARDANDO ANÁLISE
        compromised() {
            console.log('comprometidas monitoramento: ', moment().format('YYYY-MM-DD HH:mm:ss'))
            http.get('events/history/trip', {
                params: {
                    orgid: this.params.orgid,
                    data: moment().format('YYYY-MM-DD')
                }
            }).then(response => {
                // console.log(response.data.history, response.data.history.length);
                this.count_compromised = 0;
                this.comprometidas = response.data.history;

                if (this.comprometidas.length > 0) {
                    this.comprometidas.forEach(e => {
                        if (e.status == 1) {
                            this.emitter.emit('changeClassPanelTrip', e.qhi_id_after);
                            this.count_compromised += 1;
                            // console.log('teste', e.status, this.count_compromised)
                        }
                    });                    
                }


                setTimeout(() => this.compromised(), 1200000) //1000 * 60 * 20 para 20minutos
                // setTimeout(() => this.compromised(), 60000) //1000 * 60 * 1 para 1minutos

            }).catch(error => console.log(error));

        }
    },
}
</script>

<!-- <style scoped>

    .custom-padding {
        padding: 10px;
    }

</style> -->