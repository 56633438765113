<template>
    <div class="modal fade " id="replicarList" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 9999">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content">
            <div class="modal-header modal-header-shadow">
                <div class="offset-md-5" align="center">
                    <h5 class="modal-title title-font" id="exampleModalLabel" >Replicar Alteração</h5>
                    <p class="text-font">Lista de Viagem</p>
                </div>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" align="center">
                
                <!-- <button class="btn btn-table" > -->
                    <div class="col custom-background-blue-light card-replicar-trip table-selected"
                            v-for="list in listTrips" 
                            :key="list.item_id" 
                            :id="'trip'+list.item_id" 
                            @click="selectTrip(list.item_id)">
                        <table 
                            style="width: 100% !important" 
                            class="table table-background text-white custom-font-table table-repli" 
                            >
                            <thead>
                                <tr class="custom-align-table text-font">
                                    <th>Linha</th>
                                    <th>Posição</th>
                                    <th>Itinerário</th>
                                    <th>Sentido</th>
                                    <th>Saída Prev.</th>
                                </tr>
                            </thead>
                            <tbody style="border: hidden">
                                <tr>
                                    <td >
                                        <div class="table-card" align="center">
                                            <span class="text-font text-weight">
                                                {{ list.codigo }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-card">
                                            <span class="text-font text-weight">
                                                {{ list.posicao }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-card">
                                            <span class="text-font text-weight">
                                                {{ list.nome_itinerario }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-card">
                                            <span class="text-font text-weight" align="center">
                                                {{ list.sentido == 'v' ? 'VOLTA' : 'IDA' }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="table-card">
                                            <span class="text-font text-weight">
                                                {{ list.previsao_hora_saida }}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  <!-- </button>   -->
                    
            </div>
            <div class="modal-footer modal-footer-shadow">
                <div style="margin: auto">
                    <button 
                        type="button" 
                        class="btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen" 
                        data-bs-dismiss="modal" aria-label="Close"
                        >Cancelar</button>
                    <button 
                        @click="salvarUpdate()"
                        type="button" 
                        class="btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen"
                        data-bs-dismiss="modal" aria-label="Close"
                        >Confirmar</button>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import  './../../../assets/css/operacoes/replicar-list.css';
    import http from '../../../services/http.js';

    export default {
        name: 'garagem',
        data: () => {
            return {
                id_empresa: null,
                listTrips: [],
                tripEdited: {
                    orgid:'',
                    old_asset_id:'',
                    start_time:'',
                    data:'',
                    item_id:'',
                    asset_id:'',
                    driver_id:'',
                    cobrador_id:'',
                },
                listUpdateTrips: [], //control ids for update
                objectSave: {
                    orgid: '',
                    id_quadros: [],
                    asset_id: '',
                    driver_id: '',
                    cobrador_id: '',
                    start_time: '',
                },
                escala: false
            }
        },
        mounted() {

            this.emitter.on('replicarList', data => {
                this.tripEdited = data[0];
                this.escala = data[1];
                // inserindo this.objectSave
                this.objectSave.orgid = this.tripEdited.orgid;
                this.objectSave.asset_id = this.tripEdited.asset_id;
                this.objectSave.driver_id = this.tripEdited.driver_id;
                this.objectSave.cobrador_id = this.tripEdited.cobrador_id;
                this.objectSave.start_time = this.tripEdited.start_time;

                $('#replicarList').modal('show');
                this.getTripsReplicar();
            })
        },
        methods: {
            getTripsReplicar() {
                var data = this.tripEdited;
                http.get('operation/replicar/list/trips', {
                    params: {
                        orgid: this.tripEdited.orgid,
                        item_id: this.tripEdited.item_id,
                        id_quadro_horario: this.tripEdited.id_quadro_horario,
                        old_asset_id: this.tripEdited.old_asset_id != null ? this.tripEdited.old_asset_id : 'null',
                        start_time: this.tripEdited.start_time,
                        data: this.tripEdited.data,
                    }
                })
                .then(response => {
                    this.listTrips = response.data.dados;
                    // inserindo ids para lista
                    this.listTrips.forEach(trips => {
                        this.listUpdateTrips.push(trips.item_id);
                    });
                    // inserindo this.objectSave
                    this.objectSave.id_quadros = this.listUpdateTrips;
                }).catch(error => console.log(error));
            },
            selectTrip(trip_id) {
                if ($('#trip'+trip_id).hasClass('table-selected')) {
                    $('#trip'+trip_id).removeClass('table-selected');
                    var num = 0;
                    this.listTrips.forEach(trip => {
                        if (trip.item_id == trip_id) this.listUpdateTrips.splice(num, 1);
                        num += 1;
                    })
                }else {
                    $('#trip'+trip_id).addClass('table-selected');
                    this.listUpdateTrips.push(trip_id);
                }
                // console.log("number of trip", this.listUpdateTrips);
                // var element = document.getElementById(trip_id);
                
                this.objectSave.id_quadros = this.listUpdateTrips;
                // console.log("number of trip", this.objectSave);
            },
            salvarUpdate() {
                // console.log("send trips: ", this.objectSave);
                http.post('/operation/replicar/update/trips', this.objectSave)
                    .then(response => {
                        var update_trips = response.data.dados; 
                        
                        var processar = response.data.processar;
                        
                        this.$toast.success(`Dados replicados com sucesso!`);     
                        
                        // depois de salvar enviando os dados para realizar o update na table
                        this.emitter.emit('updateTrips', [update_trips, processar]);

                        if(this.escala){this.emitter.emit('updateViagensLinhas');}    
                        $('#replicarList').modal('hide');
                    }).catch(error => console.log("error", error));
            }
        }
    }
</script>