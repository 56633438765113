<template>
    <div class="modal fade " id="configuracaodetrecho" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
        style="z-index: 9998">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content custom-background-blue-light modal-configuracao-trechos">
                <div class="modal-header text-center custom-background-blue-light modal-header-configurar-trecho">
                    <div class="text-left">
                        <svg width="26" height="33" viewBox="0 0 36 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.0002 0.96875H24.0002V5H12.0002V0.96875ZM32.0627 13.8125L34.8752 10.9062C34.4377 10.4063 33.969 9.9375 33.469 9.5C33.0315 9 32.5627 8.53125 32.0627 8.09375L29.2502 11C27.6877 9.75 25.9377 8.78125 24.0002 8.09375C22.1252 7.34375 20.1252 6.96875 18.0002 6.96875C15.5002 6.96875 13.1565 7.4375 10.969 8.375C8.84399 9.3125 6.93774 10.625 5.25024 12.3125C3.62524 13.9375 2.34399 15.8438 1.40649 18.0312C0.468994 20.2188 0.000244141 22.5312 0.000244141 24.9688C0.000244141 27.4688 0.468994 29.8125 1.40649 32C2.34399 34.1875 3.62524 36.0938 5.25024 37.7188C6.93774 39.3438 8.84399 40.625 10.969 41.5625C13.1565 42.5 15.5002 42.9688 18.0002 42.9688C20.5002 42.9688 22.8127 42.5 24.9377 41.5625C27.1252 40.625 29.0315 39.3438 30.6565 37.7188C32.344 36.0938 33.6565 34.1875 34.594 32C35.5315 29.8125 36.0002 27.4688 36.0002 24.9688C36.0002 22.8438 35.6565 20.8438 34.969 18.9688C34.2815 17.0312 33.3127 15.3125 32.0627 13.8125ZM19.969 27.0312H16.0315V15.0312H19.969V27.0312Z"
                                fill="white" />
                        </svg>


                    </div>
                    <div class="w-100">
                        <h5 class="h-title-header-configurar-trecho">Configuração de Trecho</h5>
                    </div>
                    <div><button type="button" class="btn-close btn-close-configurar-trecho" data-bs-dismiss="modal"
                            aria-label="Close">X</button></div>
                </div>
                <div class="modal-body modal-body-config-trecho custom-background-light d-flex align-items-center text-center"
                    align="center">
                    <div class="title-body-config-de-trecho w-100">
                        <h5>Avenida Principal PE - PB</h5>
                    </div>
                    <div class="row w-100">
                        <div class="col-6">
                            <div class="row container-body-config-trecho">
                                <div class="col-4">
                                    <h5 class="title-body-config-trecho">Tipo Dia: </h5>
                                </div>
                                <div class="col-8">
                                    <select name="" id="" class="form-select select-config-trecho"
                                        v-model="selectedTypeDay">
                                        <option v-for="tipo_dia in tipos_dia" :value="tipo_dia.id" :key="tipo_dia.id">{{
                                            tipo_dia.nome }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-4">
                                    <h5 class="title-body-config-trecho">Hora: </h5>
                                </div>
                                <div class="col-4">
                                    <input type="time" class="form-control input-config-trecho"
                                        v-model="selectedInitialHour" aria-label="Sizing example input"
                                        aria-describedby="inputGroup-sizing-default" placeholder="00:00">
                                </div>
                                <div class="col-4">
                                    <input type="time" class="form-control input-config-trecho" v-model="selectedFinalHour"
                                        aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                        placeholder="00:00">
                                </div>
                                <div class="col-4">
                                    <h5 class="title-body-config-trecho">Tempo: </h5>
                                </div>
                                <div class="col-4">
                                    <input type="time" class="form-control input-config-trecho" v-model="selectedTime"
                                        aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                        placeholder="00:00">
                                </div>
                                <div class="col-4"></div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row container-body-config-trecho">
                                <div class="col-6">
                                    <h5 class="title-body-config-trecho">Adiantamento: </h5>
                                </div>
                                <div class="col-4">
                                    <input type="number" v-model="selectedAdvance"
                                        class="form-control input-config-trecho input-config-trecho-segundo"
                                        aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                        placeholder="-0">
                                </div>
                                <div class="col-6" style="margin-top: 12%;">
                                    <h5 class="title-body-config-trecho">Atraso: </h5>
                                </div>
                                <div class="col-4" style="margin-top: 12%;">
                                    <input type="number" v-model="selectedDelay"
                                        class="form-control input-config-trecho input-config-trecho-segundo"
                                        aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                        placeholder="0">
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button"
                        class="btn-save-config-trecho btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list  btngreen"
                        @click="saveConfigSection()">
                        Salvar
                    </button>
                    <div class="container-table-config-trecho w-100">
                        <table class="table table-config-trechos text-center">
                            <thead class="thead-config-trechos">
                                <tr class="tr-thead-config-trechos text-center">
                                    <th class="th-thead-config-trecho" scope="col">Tipo Dia</th>
                                    <th class="th-thead-config-trecho" scope="col">Hora</th>
                                    <th class="th-thead-config-trecho" scope="col">Tempo</th>
                                    <th class="th-thead-config-trecho" scope="col">Adiant.</th>
                                    <th class="th-thead-config-trecho" scope="col">Atraso</th>
                                    <th class="th-thead-config-trecho" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody class="tbody-config-trecho text-center" id="style-scroll-config-trecho">
                                <tr v-for="config in configs" :key="config" v-bind:class="{ 'TimeConflictError': TimeConflictErrorID.includes(config.id) }">
                                    <td>{{ config.tipo_dia.nome }}</td>
                                    <td>{{ formatarHora(config.hora_inicio) }}
                                        às
                                        {{ formatarHora(config.hora_fim) }}</td>
                                    <td>{{ intToHour(config.tempo_sugerido) }}</td>
                                    <td>{{ config.tolerancia_adiantamento }}</td>
                                    <td>{{ config.tolerancia_atraso }}</td>
                                    <td>
                                        <button type="button" class="btns-table-config-trecho btn text-weight shadow-light"
                                            @click="deleteConfigSection(config)">
                                            <svg width="14" height="18" viewBox="0 0 14 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.9844 0.984375V3H0.015625V0.984375H3.48438L4.51562 0H9.48438L10.5156 0.984375H13.9844ZM1 15.9844V3.98438H13V15.9844C13 16.5156 12.7969 16.9844 12.3906 17.3906C11.9844 17.7969 11.5156 18 10.9844 18H3.01562C2.48438 18 2.01562 17.7969 1.60938 17.3906C1.20312 16.9844 1 16.5156 1 15.9844Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    class="modal-footer custom-background-blue-light justify-content-center text-center d-flex align-items-center justify-content-center">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../../../assets/css/operacoes/cadastro-de-trechos/configuracao-de-trecho.css';
import http from '../../../../services/http.js';
export default {
    name: 'filtroLinhas',
    data: () => {
        return {
            orgid: null,
            configs: [],
            tipos_dia: [],
            selectedTypeDay: null,
            selectedInitialHour: null,
            selectedFinalHour: null,
            selectedTime: null,
            selectedAdvance: null,
            selectedDelay: null,
            TimeConflictErrorID: [],
        }
    },
    mounted() {
        this.emitter.on('configuracaodetrecho', (data) => {
            this.orgid = data[0];
            this.trecho = data[1];
            this.cleanModal();
            this.getSection();
            this.getTypesDay();
            this.TimeConflictErrorID = [];
            $('#configuracaodetrecho').modal('show');
        })
    },
    methods: {
        getSection() {
            this.config = [],
                http.get('trechos/' + this.trecho.id + '/faixa-horaria', { params: { orgid: this.orgid} })
                    .then(res => {
                        this.configs = res.data;
                    }).catch(err => console.error(err));
        },
        getTypesDay() {
            http.get('/tipos-dia', { params: { orgid: this.orgid } })
                .then(res => {
                    this.tipos_dia = res.data;
                }).catch(err => console.error(err));

        },
        saveConfigSection() {
            this.TimeConflictErrorID = [];
            const [hours, minutes] = this.selectedTime.split(":");
            const time = parseInt(hours) * 60 + parseInt(minutes);
            http.post('trechos/faixa-horaria', {
                orgid: this.orgid,
                trecho_id: this.trecho.id,
                tipo_dia_id: this.selectedTypeDay,
                hora_inicio: this.selectedInitialHour, hora_fim: this.selectedFinalHour, tempo_sugerido: time,
                tolerancia_adiantamento: Math.abs(this.selectedAdvance), tolerancia_atraso: Math.abs(this.selectedDelay)
            })
                .then(res => {
                    this.getSection();
                    this.$toast.success(`Registro salvo com Sucesso!`);
                })
                .catch(error => {
                    if (error.response && error.response.status === 422) {
                        this.$toast.error(`ERRO: O intervalo de tempo especificado se sobrepõe a um intervalo existente`);
                        this.getSection();
                        this.TimeConflictErrorID = error.response.data.errors.conflicting_records[0].map(record => parseInt(record));
                    }
                });
        },
        deleteConfigSection(configTrecho) {
            http.delete('trechos/faixa-horaria/' + configTrecho.id, {
                params: { orgid: this.orgid }
            })
                .then(res => {
                    this.getSection();
                }).catch(err => console.error(err));
        },
        formatarHora(hora) {
            const data = new Date(`2000-01-01T${hora}`);
            return data.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        intToHour(tempo) {
            const horas = Math.floor(tempo / 60);
            const minutos = tempo % 60;
            return `${horas}:${minutos.toString().padStart(2, '0')}`;
        },
        cleanModal() {
            this.configs = [];
            this.tipos_dia = [];
            this.selectedTypeDay = null;
            this.selectedInitialHour = null;
            this.selectedFinalHour = null;
            this.selectedTime = null;
            this.selectedAdvance = null;
            this.selectedDelay = null;
        },

    }
}
</script>
