<template>
  <div style="display: flex;" v-if="valid">
    <!-- modals -->
      <garagem />
      <terminal />
      <replicarConfirm />
      <replicarList />
      <adicionarEvento />
      <incluirViagem />
      <!-- <painelDeEventos /> -->
      <filtro />
      <AlterarViagem />
      <filtroLinhas />
      <viagensLinhas />
      <NovaEscala />
      <AdicionarEventoEscala />
      <IncluirViagemEscala />
      <CadastroDeTrechos />
      <CumprimentodeTrechosList />
      <CadastroEEdicaoDeTrechos />
      <ConfiguracaoDeTrecho />
      <MenuContexto />
      <Replay />
      <ForaDeEscala />
    <!-- fim modals -->
    <div class="col-md-2 responsive-sidebar" style="height: 100vh; width:17.6%">
      <sidebar />
    </div>
    <div class="col-md-9 responsive-sidebar-2" style="height: 100vh;background: #D8E1ED; width: 82.4%" >
      <div class="responsive-topo">
        <top @dataUser="dataUser" />
      </div>
      <div class="responsive-panel">
        <panel />
      </div>
    </div>
  </div>
</template>


<script>
  import top from './Top';
  import sidebar from './SideBar';
  import panel from './Panel';
  import filtro from './modals/Filtro';
  import garagem from './modals/Garagem';
  import terminal from './modals/Terminal';
  import ForaDeEscala from './modals/ForaDeEscala';
  import replicarConfirm from './modals/ReplicarConfirm';
  import replicarList from './modals/ReplicarList';
  import adicionarEvento from './modals/AdicionarEvento';
  import incluirViagem from './modals/IncluirViagem';
  import filtroLinhas from './modals/edicao-escalas/FiltroLinhas';
  import viagensLinhas from './modals/edicao-escalas/ViagensLinhas';
  import AlterarViagem from './modals/edicao-escalas/AlterarViagem';
  import NovaEscala from './modals/escala-padrao/NovaEscala';
  import AdicionarEventoEscala from './modals/edicao-escalas/AdicionarEventoEscala';
  import IncluirViagemEscala from './modals/edicao-escalas/IncluirViagemEscala';
  import CadastroDeTrechos from './modals/cadastro-de-trechos/CadastroDeTrechos';
  import CumprimentodeTrechosList from './modals/cadastro-de-trechos/CumprimentodeTrechosList';
  import CadastroEEdicaoDeTrechos from './modals/cadastro-de-trechos/CadastroEEdicaoDeTrechos';
  import ConfiguracaoDeTrecho from './modals/cadastro-de-trechos/ConfiguracaoDeTrecho';
  import MenuContexto from './modals/aovivo-replay/MenuContexto';
  import Replay from './modals/aovivo-replay/Replay';
  // import painelDeEventos from './modals/PainelDeEventos';
  import http from './../../services/http.js';

  import  './../../assets/css/global.css';

  export default {
    name: 'Index',
    components: {
      top,
      sidebar,
      panel,
      garagem,
      filtro,
      incluirViagem,
      replicarConfirm,
      replicarList,
      adicionarEvento,
      filtroLinhas,
      viagensLinhas,
      AlterarViagem,
      terminal,
      NovaEscala,
      AdicionarEventoEscala,
      IncluirViagemEscala,
      CadastroDeTrechos,
      CumprimentodeTrechosList,
      CadastroEEdicaoDeTrechos,
      ConfiguracaoDeTrecho,
      MenuContexto,
      Replay,
      ForaDeEscala
      // painelDeEventos,
    },  
    data() {
      return {
          user: {
              email: null,
              id: null,
              group_id: null
          },
          valid: true
      }
    },
    mounted() {
      this.checkUser();
    },
    methods: {
      checkUser() {
        // let token = localStorage.getItem('token');
        // if (token == undefined || token == null) {
        //   window.location.href = 'https://telaoperacoes.azurewebsites.net/login';
        // }

        http.get('/me')
          .then(res => {    
            if (res.data.status === 'success') {
              this.user.email = res.data.user.email
              this.user.id = res.data.user.id
              this.user.group_id = res.data.user.group_id

              // var token = localStorage.getItem('token')
              // if (token) {
              //   // config.headers.Authorization = `Bearer ${token}`
              //   let jwtData = token.split('.')[1];
              //   let decodedJwtJson = window.atob(jwtData);
              //   let decodedJwt = JSON.parse(decodedJwtJson);              
              
              //   if (!(Date.now() >= decodedJwt.exp * 1000)) {
              //     this.valid = true;
              //   }
              // }

              // DATA USER É O ENVIO DE DADOS DO USER PARA OUTROS COMPONENTES
              // A FIM DE USAR O ID DE GROUP
              this.emitter.emit('dataUser', this.user) 
            }  
          }).catch(err => console.log(err));
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style> -->
