<template>
    <div class="modal fade modal-custom" id="evento" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
      ">
            <div class="modal-content" style="width: 75%; max-height: 120%">
                <div class="modal-header modal-header-shadow">
                        <h5 class="modal-title title-font-white" id="exampleModalLabel" style="margin-left: 200px">
                            Viagem Não Realizada
                        </h5>
                    <div style="display: inline-table; margin-left:10px" v-if="checkEdit">
                        <button @click='changeEdit()' style="background-color: #292994; border: none; margin-left: 20px; padding-right: 20px;">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2656 3.85938L13.7422 5.38281L10.6172 2.25781L12.1406 0.734375C12.2969 0.578125 12.4922 0.5 12.7266 0.5C12.9609 0.5 13.1562 0.578125 13.3125 0.734375L15.2656 2.6875C15.4219 2.84375 15.5 3.03906 15.5 3.27344C15.5 3.50781 15.4219 3.70312 15.2656 3.85938ZM0.5 12.375L9.71875 3.15625L12.8438 6.28125L3.625 15.5H0.5V12.375Z" fill="white"/>
                            </svg>

                        </button>
                        <button @click='Apagar()' style="background-color: #292994; border: none;">
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.8203 1.32031V3H0.179688V1.32031H3.07031L3.92969 0.5H8.07031L8.92969 1.32031H11.8203ZM1 13.8203V3.82031H11V13.8203C11 14.263 10.8307 14.6536 10.4922 14.9922C10.1536 15.3307 9.76302 15.5 9.32031 15.5H2.67969C2.23698 15.5 1.84635 15.3307 1.50781 14.9922C1.16927 14.6536 1 14.263 1 13.8203Z" fill="white"/>
                            </svg>

                        </button>
                    </div>
                    
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form action="">
                        <div class="row">
                            <div class="container-disabled-group">
                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Veículo</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{
                                            objectEvento.registration_number
                                        }}</label>
                                    </div>
                                </div>
                                
                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Motorista</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{
                                            objectEvento.employee_number_driver
                                        }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Cobrador</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{
                                            objectEvento.employee_number_cobrador
                                        }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Itinerário</p>
                                    <div class="container-disabled shadow-light label-events"
                                        style="padding-left: 3px; width: 175px;">
                                        {{ objectEvento.itinerario }}
                                    </div>
                                </div>
                            </div>

                            <div class="container-disabled-group" style="margin-top: 20px">
                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Posição</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ objectEvento.posicao }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Linha</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ objectEvento.linha }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Viagem</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{ objectEvento.viagem }}</label>
                                    </div>
                                </div>

                                <div class="col-md-2 divs-containers">
                                    <p class="text-font-white">Saída Prevista</p>
                                    <div class="container-disabled shadow-light">
                                        <label class="label-events">{{
                                            objectEvento.saida_prevista
                                        }}</label>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: left; margin-top: 20px">
                                <p style="font-weight: bold">Motivo</p>
                                <select :disabled="isDisable" class="select-evento" id="tipoevento" @change="selectEvento($event)">
                                    <option value="">Selecione Motivo</option>
                                    <option v-for="motivo in motives" :key="motivo.id" :value="motivo.id">{{ motivo.nome }}</option>
                                </select>
                            </div>

                            <div style="text-align: left; margin-top: 10px">
                                <p style="font-weight: bold">Sentido</p>
                                <select :disabled="isDisableSense" class="select-evento" name="" id="tiposentido" @change="selectSense($event)">
                                    <option value="">Selecione Sentido</option>
                                    <option v-for="sentido in objectEvento.sentidos" :key="sentido" :value="sentido.id">
                                        {{ sentido.alias }}
                                    </option>
                                </select>
                            </div>
                            <div class="div-campoTexto-Evento">
                                <p style="font-weight: bold">Descrever Justificativa</p>
                                <textarea :disabled="isDisable" class="campoTexto-evento" id="justificativa" type="text" v-model="justificativa" :placeholder="placeholder"></textarea>
                                <!-- <textarea class="campoTexto-evento" type="text" :value="text" @input="updateText"
                                    :placeholder="placeholder"></textarea> -->
                            </div>
                            <div class="modal-footer" style="text-align: center; display: inline">
                                <!-- <button type="button" class="btn btngreen" style="font-weight: bold"
                                    @click="Apagar()">
                                    Apagar
                                </button> -->
                                <button type="button" class="btn btngreen" style="font-weight: bold"
                                    @click="EventoSave()">
                                    Salvar
                                </button>                               
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import "./../../../assets/css/operacoes/adicionar-evento.css";
import http from "../../../services/http.js";

export default {
    name: "adicionarEvento",
    data: () => {
        return {
            text: "",
            placeholder: "Descreva a justificar e motivo para realização do evento gerado",
            motives: [],
            objectEvento: {
                registration_number: null,
                employee_number_driver: null,
                employee_number_cobrador: null,
                itinerario: null,
                posicao: null,
                linha: null,
                viagem: null,
                saida_prevista: null,
                item_id: null,
                id_empresa: null,
                sentidos: null,
                assetId: null,
                driver_id: null,
                linhaId: null,
                emailUser: null
                // quadro_horario_id: null
            },
            selectedEvento: null,
            selectedSense: null,
            justificativa: null,
            checkEdit: false,
            isDisable: false,
            isDisableSense: false,
            idItemEdit: null,
        };
    },
    mounted() {
        this.emitter.on("adicionarEvento", (data) => {
            $("#evento").modal("show");
            this.setData(data);
        });

        this.clear();
        // this.checkEdit = false;
    },
    methods: {
        updateText(event) {
            this.text = event.target.value;
        },
        setData(data) {
            this.objectEvento = data;

            // carregando motivos
            http.get("v1/quadros-horarios/motivos/" + this.objectEvento.id_empresa)
                .then((res) => {
                    this.motives = res.data.dados;
                    // this.$forceUpdate();
                }).catch((err) => console.error(err));

                if (this.idItemEdit != this.objectEvento.item_id) {
                    this.clear();
                }

            // realizando consulta para saber se modal deve ser de edição
            http.get("operation/list/motive/trips/"+this.objectEvento.id_empresa+"/"+this.objectEvento.item_id)
                .then((res) => {
                    if (res.data.status) {
                        this.checkEdit = res.data.status;
                        this.isDisable = true;
                        this.isDisableSense = true;

                        $('#tipoevento').val(res.data.dados.motivo);
                        $('#tiposentido').val(res.data.dados.sentido);
                        this.justificativa = res.data.dados.descricaotratativa;

                        this.selectedEvento = res.data.dados.motivo;
                        this.selectedSense = res.data.dados.sentido;

                        this.idItemEdit = this.objectEvento.item_id;                        
                    }
                }).catch((err) => console.error(err));
        },
        EventoSave() {

            if (this.selectedEvento == '' || this.justificativa == '' || this.selectedSense == '') {
                this.$toast.error(`Você precisa preencher todos os campos para salvar.`); 
                return;
            }

            var object = {
                id_empresa: this.objectEvento.id_empresa,
                id : this.objectEvento.item_id,
                motivo : this.selectedEvento,
                justificativa : this.justificativa,
                status: null,
                sentido: this.selectedSense,
                sentido_default: this.objectEvento.sentido_default,
                viagem: this.objectEvento.viagem,
                data: this.objectEvento.data,
                posicao: this.objectEvento.posicao,
                quadro_horario_id: this.objectEvento.quadro_horario_id,
                assetId: this.objectEvento.assetId,
                driverid: this.objectEvento.driverid,
                linhaId: this.objectEvento.linhaId,
                emailUser: this.objectEvento.emailUser,
                class: null
            };

            // http.post('v1/quadros-horarios/motivo/viagem/', object)
            http.post('operation/motive/trips', object)
                .then(res => {

                    if (res.data.status) {
                        this.$toast.success(`Salvo com sucesso!`); 
                        object.id = res.data.dados[0];
                        object.class = res.data.dados[1];                       
                        this.emitter.emit('atualizaEvento', object);
                    }else {
                        this.$toast.error(`Dados não salvos.`);
                    }

                    $('#evento').modal('hide');
                    this.clear();
                    
                    // this.isDisable = false;
                    // this.isDisableSense = false;

                    this.objectEvento = {
                            registration_number: null,
                            employee_number_driver: null,
                            employee_number_cobrador: null,
                            itinerario: null,
                            posicao: null,
                            linha: null,
                            viagem: null,
                            saida_prevista: null,
                            item_id: null,
                            id_empresa: null,
                            sentidos: null,
                            quadro_horario_id: null,
                        };

                }).catch(err => console.error(err));
        },
        Apagar() {
            var object = {
                id_empresa: this.objectEvento.id_empresa,
                id : this.objectEvento.item_id,
                motivo : this.selectedEvento,
                justificativa :  this.justificativa,
                status: 1,
                sentido: this.selectedSense,
                sentido_default: this.objectEvento.sentido_default,
                viagem: this.objectEvento.viagem,
                data: this.objectEvento.data,
                posicao: this.objectEvento.posicao, 
                quadro_horario_id: this.objectEvento.quadro_horario_id,
                assetId: this.objectEvento.assetId,
                driverid: this.objectEvento.driverid,
                linhaId: this.objectEvento.linhaId,
                emailUser: this.objectEvento.emailUser
            };
            http.post('operation/motive/trips', object)
                .then(res => {

                    this.$toast.success(`Salvo com sucesso!`); 
                    object.id = res.data.dados[0];
                    object.class = res.data.dados[1];
                    this.emitter.emit('atualizaEvento', object);
                    $('#evento').modal('hide');

                    this.clear();                    

                }).catch(err => console.error(err));
        },
        changeEdit() {
            this.isDisable = !this.isDisable;
        },
        clear() {
            this.justificativa = '';
            
            $('#tipoevento').val('')
            $('#tiposentido').val('')

            this.selectedEvento = '';
            this.justificativa = '';
            this.selectedSense = '';

            this.checkEdit = false;
            this.isDisableSense = false;
            this.isDisable = false;
        },
        EventoUpdate() {
            $("#evento").modal("hide");
            // Editar a viagem editada
        },
        EventoDelete() {
            $("#evento").modal("hide");
            // Excluir a viagem editada
        },
        selectEvento(e) {
            this.selectedEvento = e.target.value;
        },
        selectSense(e) {
            this.selectedSense = e.target.value;
        }
    },
};
</script>