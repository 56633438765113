<template>
    <div class="modal fade " id="filtroLinhas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="z-index: 9999">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content custom-background-blue-light">
                <div class="modal-header" style="border: none; text-align: center; display: flow-root; height: 125px;">
                    <h5 class="modal-title title-font" id="exampleModalLabel" style="color: white; margin-bottom: 1rem">
                        Seleção de Linhas
                    </h5>
                    <div style="display: flex;justify-content: center;align-items: center;flex-direction: column;">
                        <div class="input-group mb-3" style="width: 60%">
                            <div class="input-group-prepend">
                                <span class="input-group-text span-header-filtrolinhas">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control form-header-filtrolinhas" placeholder="Pesquisar"
                                aria-label="Pesquisar" aria-describedby="button-addon2" v-model="search" 
                                @keyup.enter="filterLines()"/>
                            <div class="input-group-append">
                                <button class="btn btn-header-filtrolinhas" type="button" id="button-addon2"
                                @click="filterLines">
                                    Buscar
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="offset-md-4">
                            <h5 class="modal-title title-font" id="exampleModalLabel" style="color:white;">Seleção de Linhas</h5>
                        </div>
                    </div> -->
                    
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="padding:none; background-color: #17175F;">
                    <div class="col-md-10 card-replicar-trip card-custom-filtro" id="style-scroll-filtro-linha">
                        <div class="row custom-row-filtro">
                            <div class="col" v-for="line in filteredLines" :key="line.id">
                                <div class="form-check" style="width: 130px !important; diplay: block; padding: 10px;">
                                    <input class="form-check-input CheckboxFilterLines" type="checkbox"  :value="line.id" :id="line.id"
                                        @click="lineSelected(line.id)">
                                    <label class="form-check-label text-white" for="flexCheckDefault">
                                        {{ line.codigo }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="padding:0 !important">
                    <div style="margin: auto">
                        <button @click="filterColumns()" type="button"
                            class="btn custom-background-green-light custom-background-blue-dark text-weight btn-custom-list shadow-light btngreen"
                            data-bs-dismiss="modal" aria-label="Close">Aplicar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '../../../../assets/css/operacoes/edicao-escalas/filtro-linhas.css';
// import './../../../assets/css/operacoes/edicao-escalas/filtro-linhas.css';
import http from '../../../../services/http.js';
export default {
    name: 'filtroLinhas',
    data: () => {
        return {
            data: null,
            orgid: null,
            lines: [],
            linesObject: [],
            filteredLines: [],
            search: ''
        }
    },
    mounted() {
        this.emitter.on('filtroLinhas', (data) => {
            this.data = data.data;
            this.orgid = data.orgid;
            this.search = '';
            this.filteredLines = [];
            $('#filtroLinhas').modal('show');
            this.filtroLinhas();
        })
    },
    methods: {
        filtroLinhas() {
            http.get('operation/filter/lines', {
                params: {
                    orgid: this.orgid,
                    data: this.data
                }
            }).then(res => {
                this.lines = res.data.dados;
                this.filterLines();
            }).catch(err => console.log(err));
        },
        lineSelected(line_id) {
            if ($('#' + line_id).is(':checked')) {
                if (!this.linesObject.includes(line_id)) {
                    if (this.linesObject.length < 10) this.linesObject.push(line_id);
                    else {
                        this.$toast.error(`A quantidade de linhas permitida são 10 linhas.`);
                        $("#" + line_id).prop('checked', false);
                    }
                    // console.log("check is true", this.linesObject)
                }
            } else {
                var num = 0;
                this.linesObject.forEach(e => {
                    if (e == line_id) this.linesObject.splice(num, 1);
                    num += 1;
                })
            }
        },
        filterColumns() {
            if (this.linesObject.length == 0) return;

            http.get('operation/selected/lines', {
                params: {
                    orgid: this.orgid,
                    data: this.data,
                    lines: this.linesObject
                }
            }).then(response => {
                this.emitter.emit('ViagensLinhas', [response.data, this.orgid, this.data, this.linesObject]);

                this.linesObject.forEach(e => {
                    $('#' + e).prop('checked', false);
                })

                this.linesObject = [];
            }).catch(error => console.log(error));

            // this.linesObject.forEach(e => {
            //     $('#'+e).prop('checked', false); 
            // })

            // this.linesObject = [];
        },
        filterLines() {
            if (this.search !== '') {
                this.filteredLines = this.lines.filter(line => {
                    return line.codigo?line.codigo.toLowerCase().includes(this.search.toLowerCase()):0
                })
            } else {
                this.filteredLines = this.lines;
            }
        },
    }
}
</script>