<template>
  <router-view />
  <Login v-if="validToken" />
</template>

<script>
  import Login from './views/login/Login.vue';

  // import Operacoes from './views/operacoes/SideBar.vue';

  export default {
    name: 'App',
    components: {
      Login,
      // Operacoes,
    },
    data: () => {
      return {
        // validToken: false
      }
    },
    mounted() {
    },
    methods: {
      
    },
  }
</script>

<!-- <style scoped>
  @import '../public/css/global.css';
</style> -->

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>
