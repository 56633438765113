import io from 'socket.io-client';

export default {
    data() {
        return {
            // socket: io('https://socket.operacoes.mobs2system.com/'),
            socket_dev: io('https://socket.operacoes.mobs2system.com/'),
            day_trips: [],
            day_totals: [],
            filters: {},
            objectDayTrips: {
                date: null,
                orgid: null,             
                rangetime: null,
                line_group: null, 
                terminal: null, 
                line: null, 
                sense: null, 
                position: null,  
                vehicle: null,                                                
                type : null,
            },
        }
    }, mounted() {
        var self = this
        self.socket_dev.emit('/', {})

        // self.socket_dev.on('/', (data) => console.log(data))

        self.socket_dev.on('day-trips', (data) => {
            self.day_trips = data
            this.emitter.emit('socketDayTrips', data);
            // console.log(self.day_trips)
        })
        self.socket_dev.on('day-totals', (data) => {
            self.day_totals = data
            this.emitter.emit('socketTotals', data);
        })
        self.socket_dev.on('filters', (data) => {
            self.filters = data
            this.emitter.emit('socketFilters', data);            
        })

    }, methods: {
        getData(data) {
            var self = this

            var check = this.checkAuth();
            if (!check) return;

            if (data != undefined){
                this.objectDayTrips = data;
            }
            console.log('socket connected', self.objectDayTrips.orgid)

            // if (self.objectDayTrips.orgid != null) {
            //     self.socket_dev.emit('day-trips', self.objectDayTrips)
            //     self.socket_dev.emit('day-totals', self.objectDayTrips)
            //     self.socket_dev.emit('filters', self.objectDayTrips)
            // }

            self.socket_dev.emit('day-trips', self.objectDayTrips)
            self.socket_dev.emit('day-totals', self.objectDayTrips)
            self.socket_dev.emit('filters', self.objectDayTrips)

            this.emitter.emit('sockectDateTimeNow', moment().format('DD/MM/YYYY HH:mm:ss'));

            setTimeout(() => self.getData(), 30000)
        },
        updateObject(data) {
            this.objectDayTrips = data;
        },
        checkAuth() {
            var token = localStorage.getItem('token');
            if (token) {
                // config.headers.Authorization = `Bearer ${token}`
                let jwtData = token.split('.')[1];
                let decodedJwtJson = window.atob(jwtData);
                let decodedJwt = JSON.parse(decodedJwtJson);
              
                if (!(Date.now() >= decodedJwt.exp * 1000)) {
                  return true;
                }
              } else {
                localStorage.removeItem('token');
                return false;
            }
        }
    }
}


