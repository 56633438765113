<template>
    <div class="modal fade " id="modalReplay" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="background-color: #292994; border-radius: 20px;">
                <div class="modal-header" style="border-color: #292994 !important">
                    <div class="col-6">
                        <div class="position-relative">
                            <v-select v-if="Adress" class="select-menu-contexto" placeholder="Buscar Endereço"
                                :options="Adress" align="start" label="description" id="endereco" v-model="selectedAdress"
                                style="max-width: 90%; width: 90%; border-radius: 5px; height: 40px; z-index: 998;"
                                @input="searchAdress" @update:modelValue="filterAdress()">
                            </v-select>
                            <span class="position-absolute top-50 translate-middle-y"
                                style="right: 14% !important; z-index: 999;">
                                <svg width="20" height="20" viewBox="0 0 27 27" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.49219 15.0547C6.80469 16.3672 8.39844 17.0234 10.2734 17.0234C12.1484 17.0234 13.7422 16.3672 15.0547 15.0547C16.3672 13.7422 17.0234 12.1484 17.0234 10.2734C17.0234 8.39844 16.3672 6.80469 15.0547 5.49219C13.7422 4.17969 12.1484 3.52344 10.2734 3.52344C8.39844 3.52344 6.80469 4.17969 5.49219 5.49219C4.17969 6.80469 3.52344 8.39844 3.52344 10.2734C3.52344 12.1484 4.17969 13.7422 5.49219 15.0547ZM19.2734 17.0234L26.7266 24.4766L24.4766 26.7266L17.0234 19.2734V18.0781L16.6016 17.6562C14.8203 19.2031 12.7109 19.9766 10.2734 19.9766C7.55469 19.9766 5.23438 19.0391 3.3125 17.1641C1.4375 15.2891 0.5 12.9922 0.5 10.2734C0.5 7.55469 1.4375 5.25781 3.3125 3.38281C5.23438 1.46094 7.55469 0.5 10.2734 0.5C12.9922 0.5 15.2891 1.46094 17.1641 3.38281C19.0391 5.25781 19.9766 7.55469 19.9766 10.2734C19.9766 11.2578 19.7422 12.3828 19.2734 13.6484C18.8047 14.8672 18.2656 15.8516 17.6562 16.6016L18.0781 17.0234H19.2734Z"
                                        fill="#292994" />
                                </svg>

                            </span>
                        </div>
                    </div>

                    <div class="col-5">
                        <div class="position-relative">
                            <input type="text" class="form-control input-menu-contexto" placeholder="Pesquisar Veículo">
                            <span class="position-absolute top-50 translate-middle-y"
                                style="right: 4% !important; z-index: 999;">
                                <svg width="20" height="20" viewBox="0 0 27 27" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.49219 15.0547C6.80469 16.3672 8.39844 17.0234 10.2734 17.0234C12.1484 17.0234 13.7422 16.3672 15.0547 15.0547C16.3672 13.7422 17.0234 12.1484 17.0234 10.2734C17.0234 8.39844 16.3672 6.80469 15.0547 5.49219C13.7422 4.17969 12.1484 3.52344 10.2734 3.52344C8.39844 3.52344 6.80469 4.17969 5.49219 5.49219C4.17969 6.80469 3.52344 8.39844 3.52344 10.2734C3.52344 12.1484 4.17969 13.7422 5.49219 15.0547ZM19.2734 17.0234L26.7266 24.4766L24.4766 26.7266L17.0234 19.2734V18.0781L16.6016 17.6562C14.8203 19.2031 12.7109 19.9766 10.2734 19.9766C7.55469 19.9766 5.23438 19.0391 3.3125 17.1641C1.4375 15.2891 0.5 12.9922 0.5 10.2734C0.5 7.55469 1.4375 5.25781 3.3125 3.38281C5.23438 1.46094 7.55469 0.5 10.2734 0.5C12.9922 0.5 15.2891 1.46094 17.1641 3.38281C19.0391 5.25781 19.9766 7.55469 19.9766 10.2734C19.9766 11.2578 19.7422 12.3828 19.2734 13.6484C18.8047 14.8672 18.2656 15.8516 17.6562 16.6016L18.0781 17.0234H19.2734Z"
                                        fill="#292994" />
                                </svg>

                            </span>
                        </div>
                    </div>
                    <div class="col-1">
                        <button type="button" class="btn-close btn-close-menu-contexto" data-bs-dismiss="modal"
                            aria-label="Close">x</button>
                    </div>
                </div>
                <div class="modal-body" style="padding:none; overflow: hidden; margin-top: -2%;" v-if="noLoading">
                    <div class="col-md-4"
                        style="background-color: #292994; color: #fff; z-index: 10000; margin-top: 10px; position: absolute; padding: 4px 0px 4px 10px; font-size: 11px; 
                                   border-bottom-right-radius: 10px; border-top-right-radius: 10px; margin-left: -2.1%;">
                        Atualizando a informação em 00 segundos
                        <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25.0434 19.456C24.241 21.0559 23.0505 22.3116 21.4718 23.223C19.2617 24.4991 16.8866 24.7968 14.3467 24.1162C11.8565 23.449 9.9734 22.0103 8.69736 19.8001L5.66627 21.5501L7.41508 15.1729L13.765 16.8744L10.7339 18.6244C11.7 20.2978 13.1044 21.3814 14.9472 21.8751C16.8397 22.3822 18.6226 22.1527 20.2961 21.1865C21.2433 20.6397 22.0538 19.856 22.7275 18.8355L25.0434 19.456ZM22.8582 11.6244C21.8921 9.95094 20.4628 8.86069 18.5702 8.35359C16.7275 7.85984 14.9695 8.09603 13.2961 9.06218C12.2857 9.64551 11.4752 10.4292 10.8646 11.4132L8.54872 10.7927C9.35106 9.1928 10.5416 7.93712 12.1203 7.02566C14.3304 5.74962 16.6806 5.44522 19.1708 6.11245C21.7107 6.79303 23.6187 8.23841 24.8947 10.4486L27.9258 8.69857L26.177 15.0758L19.8271 13.3744L22.8582 11.6244Z"
                                fill="white" />
                        </svg>
                    </div>
                    <GMapMap :click="true" :center="center" :zoom="14" map-type-id="terrain"
                        style="width: 104.5%; height: 300px; margin-left: -2.2%;">
                        <GMapPolyline :path="itinerario_ida" :editable="false" :options="{ strokeColor: 'green' }"
                            ref="mapPolygon" />
                        <GMapPolyline :path="itinerario_volta" :editable="false" :options="{ strokeColor: 'blue' }"
                            ref="mapPolygon" @click="handleClick" />
                        <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true"
                            @click="openMarker()" style="max-height: 1000px;">
                            <GMapInfoWindow class="contexto-GMapInfoWindow" :closeclick="true"
                                @closeclick="closeInfoWindow()" :opened="showInfoWindow">
                                <div class="gmap-marker-header shadow-light">
                                    <p>MP - 1624 - VW 17.230 OD / EURO 5</p>
                                    <label>LIGADO</label>
                                </div>
                                <div class="gmap-marker-body">
                                    <p class="text-gmap-maker"><strong>Data:</strong><br>{{ position.Timestamp }}</p>
                                    <p class="text-gmap-maker"><strong>Latitude:</strong><br>{{ position.Latitude }}</p>
                                    <p class="text-gmap-maker"><strong>Longitude:</strong><br>{{ position.Longitude }}</p>
                                    <p class="text-gmap-maker"><strong>Odometer:</strong><br>{{ position.Odometer }}</p>
                                    <p class="text-gmap-maker"><strong>Endereço:</strong><br>{{ endereco }}</p>
                                </div>
                            </GMapInfoWindow>
                            <GMapInfoWindow :closeclick="true" @closeclick="closeInfoWindow2()" :opened="showInfoWindow2">
                            </GMapInfoWindow>
                        </GMapMarker>
                        <GMapMarker :key="index" :position="marker_endereco[0].position" :clickable="true"
                            @click="openMarker()">
                        </GMapMarker>
                    </GMapMap>
                    <div class="container-itinerarios shadow-light">
                        <button class="toggle-button" @click="toggleColumn = !toggleColumn"
                            :class="{ 'toggle-buttom-pressionado': toggleColumn }">
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.8"
                                    d="M6.79688 9.15625L5.34375 10.5625C5 10.2188 4.67188 9.8125 4.35938 9.34375C4.04688 8.875 3.78125 8.3125 3.5625 7.65625L5.53125 7.14062C5.6875 7.57812 5.875 7.96875 6.09375 8.3125C6.3125 8.625 6.54688 8.90625 6.79688 9.15625ZM8.01562 4L3.98438 0.015625L0 4H3C3.03125 4.84375 3.09375 5.5625 3.1875 6.15625L5.15625 5.6875C5.125 5.4375 5.09375 5.17188 5.0625 4.89062C5.03125 4.60938 5.01562 4.3125 5.01562 4H8.01562ZM18 4L14.0156 0.015625L9.98438 4H12.9844C12.9531 5.21875 12.8125 6.1875 12.5625 6.90625C12.3125 7.625 12 8.20312 11.625 8.64062C11.25 9.07812 10.8594 9.48438 10.4531 9.85938C10.2031 10.0781 9.95312 10.3125 9.70312 10.5625C9.45312 10.8125 9.21875 11.1094 9 11.4531C8.84375 11.2031 8.65625 10.9844 8.4375 10.7969C8.25 10.5781 8.0625 10.375 7.875 10.1875L6.46875 11.5938C6.78125 11.875 7.04688 12.1562 7.26562 12.4375C7.51562 12.7188 7.70312 13.0625 7.82812 13.4688C7.95312 13.875 8.01562 14.3906 8.01562 15.0156V19.9844H9.98438V15.0156C9.98438 14.3281 10.0625 13.7812 10.2188 13.375C10.375 12.9688 10.5781 12.625 10.8281 12.3438C11.1094 12.0312 11.4375 11.7031 11.8125 11.3594C12.1562 11.0469 12.5 10.7031 12.8438 10.3281C13.2188 9.95312 13.5625 9.5 13.875 8.96875C14.1875 8.40625 14.4375 7.73438 14.625 6.95312C14.8438 6.14062 14.9688 5.15625 15 4H18Z"
                                    fill="#A2D624" />
                            </svg>

                        </button>
                        <div class="column" v-if="toggleColumn" :style="{ height: columnHeight }">
                            <div v-for="itinerario, index in itinerarios" :key="itinerario" class="item"
                                :class="{ 'item-active': itinerario.showItinerary, 'first-item': index === 0 }" 
                                @mouseover="itinerario.showItinerary = true"
                                @mouseleave="itinerario.showItinerary = false" 
                                @click="filterPolylines(itinerario)">
                                <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg"
                                    class="svg-contexto"
                                    :class="{ 'svg-contexto-active': itinerario.showItinerary}" >
                                    <path opacity="0.8"
                                        d="M6.79688 9.15625L5.34375 10.5625C5 10.2188 4.67188 9.8125 4.35938 9.34375C4.04688 8.875 3.78125 8.3125 3.5625 7.65625L5.53125 7.14062C5.6875 7.57812 5.875 7.96875 6.09375 8.3125C6.3125 8.625 6.54688 8.90625 6.79688 9.15625ZM8.01562 4L3.98438 0.015625L0 4H3C3.03125 4.84375 3.09375 5.5625 3.1875 6.15625L5.15625 5.6875C5.125 5.4375 5.09375 5.17188 5.0625 4.89062C5.03125 4.60938 5.01562 4.3125 5.01562 4H8.01562ZM18 4L14.0156 0.015625L9.98438 4H12.9844C12.9531 5.21875 12.8125 6.1875 12.5625 6.90625C12.3125 7.625 12 8.20312 11.625 8.64062C11.25 9.07812 10.8594 9.48438 10.4531 9.85938C10.2031 10.0781 9.95312 10.3125 9.70312 10.5625C9.45312 10.8125 9.21875 11.1094 9 11.4531C8.84375 11.2031 8.65625 10.9844 8.4375 10.7969C8.25 10.5781 8.0625 10.375 7.875 10.1875L6.46875 11.5938C6.78125 11.875 7.04688 12.1562 7.26562 12.4375C7.51562 12.7188 7.70312 13.0625 7.82812 13.4688C7.95312 13.875 8.01562 14.3906 8.01562 15.0156V19.9844H9.98438V15.0156C9.98438 14.3281 10.0625 13.7812 10.2188 13.375C10.375 12.9688 10.5781 12.625 10.8281 12.3438C11.1094 12.0312 11.4375 11.7031 11.8125 11.3594C12.1562 11.0469 12.5 10.7031 12.8438 10.3281C13.2188 9.95312 13.5625 9.5 13.875 8.96875C14.1875 8.40625 14.4375 7.73438 14.625 6.95312C14.8438 6.14062 14.9688 5.15625 15 4H18Z"
                                        fill="#292994"
                                        v-bind:class="{ 'filteredItinerary': !itinerario.filterItinerary }" />
                                </svg>
                                <h5 v-if="itinerario.showItinerary" class="itinerary-contexto"
                                    v-bind:class="{ 'filteredItinerary': !itinerario.filterItinerary }">
                                    {{ itinerario.nome }}
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <h4 class="text-white title-menu-contexto" style="margin-top: 30px">Filtro Ao Vivo
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.5 9.5V0.5H21.5234V3.52344H27.5V6.47656H21.5234V9.5H18.5ZM27.5 15.4766H12.5234V12.5234H27.5V15.4766ZM6.47656 9.5H9.5V18.5H6.47656V15.4766H0.5V12.5234H6.47656V9.5ZM15.4766 27.5H12.5234V18.5H15.4766V21.5234H27.5V24.4766H15.4766V27.5ZM0.5 3.52344H15.4766V6.47656H0.5V3.52344ZM0.5 21.5234H9.5V24.4766H0.5V21.5234Z"
                                        fill="white" />
                                </svg>
                            </h4>

                            <select name="" id="" class="form-control input-menu-contexto"
                                style="margin-left: 4%; width: 80%; font-weight: bold; margin-top: 10%;">
                                <option value="0">Todos os Grupos</option>
                                <option :value="grupo.id" v-for="grupo in grupos" :key="grupo.id">{{ grupo.name }}</option>
                            </select>

                        </div>
                        <div class="col-md-7">
                            <div style="margin-top: 30px; background: #fff; border-radius: 15px; width: 95%;">
                                <div class="row" style="margin-top: 10px; margin-bottom: 2%;">
                                    <div class="col-md-10">
                                        <h4 class="title-menu-contexto">Lista de Linhas: </h4>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="pull-right" style="width: 80px; margin-top: 30%; margin-right: 40%;">
                                            <div class="svg-menu-contexto-div shadow-light" @click="showVehicles = true"
                                                v-bind:class="{ 'active-svg-lines': showVehicles }"
                                                style="border-bottom-left-radius: 10px; border-top-left-radius: 10px;">
                                                <svg width="13" height="18" viewBox="0 0 18 23" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M9 0.351562L17.75 21.6797L16.9297 22.5L9 19L1.07031 22.5L0.25 21.6797L9 0.351562Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                            <div class="svg-menu-contexto-div shadow-light" @click="showVehicles = false"
                                                v-bind:class="{ 'active-svg-vehicles': !showVehicles }"
                                                style="border-bottom-right-radius: 10px; border-top-right-radius: 10px;">
                                                <svg width="15" height="18" viewBox="0 0 20 23" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17 10.8516V5H3V10.8516H17ZM13.9922 17.3594C14.3203 17.6875 14.7396 17.8516 15.25 17.8516C15.7604 17.8516 16.1797 17.6875 16.5078 17.3594C16.8359 16.9948 17 16.5755 17 16.1016C17 15.6276 16.8359 15.2266 16.5078 14.8984C16.1797 14.5339 15.7604 14.3516 15.25 14.3516C14.7396 14.3516 14.3203 14.5339 13.9922 14.8984C13.6641 15.2266 13.5 15.6276 13.5 16.1016C13.5 16.5755 13.6641 16.9948 13.9922 17.3594ZM3.49219 17.3594C3.82031 17.6875 4.23958 17.8516 4.75 17.8516C5.26042 17.8516 5.67969 17.6875 6.00781 17.3594C6.33594 16.9948 6.5 16.5755 6.5 16.1016C6.5 15.6276 6.33594 15.2266 6.00781 14.8984C5.67969 14.5339 5.26042 14.3516 4.75 14.3516C4.23958 14.3516 3.82031 14.5339 3.49219 14.8984C3.16406 15.2266 3 15.6276 3 16.1016C3 16.5755 3.16406 16.9948 3.49219 17.3594ZM0.648438 16.6484V5C0.648438 3.14062 1.45052 1.90104 3.05469 1.28125C4.65885 0.661458 6.97396 0.351562 10 0.351562C13.026 0.351562 15.3411 0.661458 16.9453 1.28125C18.5495 1.90104 19.3516 3.14062 19.3516 5V16.6484C19.3516 17.6693 18.9505 18.5443 18.1484 19.2734V21.3516C18.1484 21.6797 18.0391 21.9531 17.8203 22.1719C17.6016 22.3906 17.3281 22.5 17 22.5H15.8516C15.5234 22.5 15.2318 22.3906 14.9766 22.1719C14.7578 21.9531 14.6484 21.6797 14.6484 21.3516V20.1484H5.35156V21.3516C5.35156 21.6797 5.22396 21.9531 4.96875 22.1719C4.75 22.3906 4.47656 22.5 4.14844 22.5H3C2.67188 22.5 2.39844 22.3906 2.17969 22.1719C1.96094 21.9531 1.85156 21.6797 1.85156 21.3516V19.2734C1.04948 18.5443 0.648438 17.6693 0.648438 16.6484Z"
                                                        fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="showVehicles" style="height: 200px;">
                                    <div style="overflow:auto; height: 195px; width: 90%; margin-left: 4%;">
                                        <div v-for="line in lines" :key="line.id"
                                            class="lines-div-menu-contexto shadow-light">
                                            <h5 class="title-lines-div-menu-contexto">{{ line.nome_linha }}</h5>
                                            <input type="checkbox"
                                                class="pull-right checkbox-menu-contexto form-check-input"
                                                @click="line.showVehicles = !line.showVehicles">
                                            <div v-if="line.showVehicles" class="vehicles-div-menu-contexto">
                                                <div v-for="(veiculo, index) in line.veiculos" :key="veiculo.id"
                                                    :class="{ 'last-veiculo': index === line.veiculos.length - 1 }"
                                                    style="margin: 2%;">
                                                    <h5 class="title-lines-div-menu-contexto">{{ veiculo }}</h5>
                                                    <input type="checkbox" checked
                                                        class="pull-right checkbox-menu-contexto form-check-input">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else style="height: 200px;">
                                    <div style="overflow:auto; height: 195px; width: 90%; margin-left: 4%;">
                                        <div v-for="veiculo in veiculos" :key="veiculo.id"
                                            class="lines-div-menu-contexto shadow-light">
                                            <h5 class="title-lines-div-menu-contexto">{{ veiculo }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="text-align: center; display: inline"></div>
        </div>
    </div>
</template>

<script>
import './../../../../assets/css/operacoes/aovivo-replay/aovivo.css';
import http from '../../../../services/http.js';
import { Loader } from '@googlemaps/js-api-loader';

const loader = new Loader({
    apiKey: "AIzaSyCmerR3fHTFT8ExWzu_TCmLouIW4vJKBEc",
    version: "weekly",
    //   libraries: ["places"]
});

import { ref, onMounted } from "vue";

const myMapRef = ref();
const mapPolygon = ref();


export default {
    name: 'MenuContexto',
    data() {
        return {
            orgid: null,
            date: null,
            itinerario: [],
            center: {
                lat: '',
                lng: '',
                // lat: -8.057655393429243, lng: -34.87229640503695
            },
            class_color: null,
            showInfoWindow: false,
            showInfoWindow2: false,
            markers: [
                {
                    position: {
                        lat: '', lng: ''
                        // lat: -8.057655393429243, lng: -34.87229640503695
                    },
                }
            ],
            position: [],
            noLoading: false,
            showInfoWindow: false,
            trip: [],
            endereco: null,
            class: null,
            grupos: [],
            itinerario_ida_aux: [],
            itinerario_volta_aux: [],
            itinerario_ida: [],
            itinerario_volta: [],
            lines: [],
            veiculos: [],
            change_list: [],
            itinerarios: [],
            search_adress: '',
            google: '',
            validateSelection: null,
            Adress: [],
            marker_endereco: [
                {
                    position: {
                        lat: '', lng: ''
                        // lat: -8.057655393429243, lng: -34.87229640503695
                    },
                }
            ],
            showVehicles: null,
            toggleColumn: false
        };
    },
    mounted() {

        this.emitter.on('menuContexto', data => {
            this.orgid = data[0];
            this.trip = data[1];
            //    this.itinerario = data[2];
            this.center = data[2];
            this.date = data[3];
            this.position = data[4];
            this.class = data[5];
            this.itinerarios = data[6];
            this.showVehicles = true;
            this.class = this.class == 'v' ? 'blue' : 'green';
            this.toggleColumn = false;
            var lat = null;
            var lng = null;

            lat = parseFloat(this.position.Latitude);
            lng = parseFloat(this.position.Longitude);

            this.getAdress(lat, lng, null);

            //    console.log('itinerario', this.itinerario)

            // var localizacao = { lat: parseFloat(lat), lng: parseFloat(lng) };

            //    this.markers.push(localizacao);
            //    this.markers.position.lng = lng;
            this.markers[0].position.lat = lat;
            this.markers[0].position.lng = lng;

            this.noLoading = true;

            $('#modalReplay').modal('show');
            this.filteredPolylines()
            this.grupoLinhas();
            this.itinerarioLines();
        })

        $('#lista').on('click', function () {
            this.filterAdress(2)
        });

        // const autocomplete = new google.maps.places.Autocomplete(this.$refs["endereco"]);

        // let map = new VueGoogleMaps.gmapApi.maps.places.Autocomplete(this.$refs["endereco"]); 

        // var autocomplete;
        // autocomplete = new google.maps.places.Autocomplete(this.searchAdress, option);
        // autocomplete.addListener('place_changed', this.searchAdress());

    },
    methods: {
        filteredPolylines() {
            this.itinerarios.forEach(item => {
                item.showItinerary = false;
                item.filterItinerary = true;
                if (item.sentido == 'i') {
                    var polyline = item.polyline;
                    polyline.forEach(e => {
                        this.itinerario_ida.push({ lat: parseFloat(e.lat), lng: parseFloat(e.lng), id_itinerario: item.id });
                    })
                }

                if (item.sentido == 'v') {
                    var polyline = item.polyline;
                    polyline.forEach(e => {
                        this.itinerario_volta.push({ lat: parseFloat(e.lat), lng: parseFloat(e.lng), id_itinerario: item.id });
                    })
                }
            });
            this.itinerario_ida_aux = this.itinerario_ida;
            this.itinerario_volta_aux = this.itinerario_volta;
        },
        handleClick(event) {
            if (event.latLng?.lat) {
                this.markers[0].position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
                this.showInfoWindow = true;

                this.getAdress(event.latLng.lat(), event.latLng.lng(), 'mapa');
            }
        },
        openMarker(id) {
            this.showInfoWindow = true;
        },
        closeInfoWindow() {
            this.showInfoWindow = false;
        },
        closeInfoWindow2() {
            this.showInfoWindow2 = false;
        },
        getAdress(lat, lng, tipo) {
            http.get('events/adress', {
                params: {
                    orgid: this.orgid,
                    lat: lat,
                    lng: lng,
                }
            }).then(response => {

                this.endereco = response.data.dados;
            }).catch(error => console.log(error));
        },
        grupoLinhas() {
            http.get('aovivo/group/lines', {
                params: {
                    orgid: this.orgid
                }
            }).then(response => {
                this.grupos = response.data.dados;
            }).catch(error => console.log(error));
        },
        itinerarioLines() {
            http.get('aovivo/itinerary/lines', {
                params: {
                    orgid: this.orgid,
                }
            }).then(response => {
                this.lines = response.data.linhas;
                this.lines.forEach(line => {
                    line.showVechiles = false;
                    this.veiculos = this.veiculos.concat(line.veiculos);
                });
            }).catch(error => console.log(error));
        },
        changeList(linha_id) {
            $('#linha_' + linha_id).hide();
        },
        searchAdress(value) {

            var busca = value.target.value;

            this.search_adress = busca;

            this.Adress = [];

            let self = this;
            loader
                .load()
                .then((google) => {

                    var geocoder;
                    geocoder = new window.google.maps.Geocoder();

                    var autocomplete;
                    var location;
                    var description;
                    var listAdress = [];

                    // $("#autocomplete li").remove();

                    autocomplete = new window.google.maps.places.AutocompleteService();

                    autocomplete.getPlacePredictions({ input: this.search_adress }, function (predictions, status) {
                        if (status == google.maps.places.PlacesServiceStatus.OK) {
                            var matchAddress = predictions[0].structured_formatting.secondary_text;
                            // console.log('matchAddress', matchAddress, 'predictions', predictions[0].reference);

                            var num = 0;
                            // var element = document.getElementById('autocomplete');
                            // var lista = "";
                            predictions.forEach(e => {
                                if (e.structured_formatting.secondary_text != undefined) {
                                    // listAdress.push({ id: num, description: e.description });
                                    listAdress.push({ id: num, description: e.description, reference: predictions[0].reference });

                                    // lista += "<li id='lista' :value='"+num+"' class='list-group-item' v-on:click='filterAdress("+num+")'>"+e.description+"</li>";
                                    num += 1;
                                }
                            });

                            // $('#autocomplete').append(lista);

                            self.Adress = listAdress;

                            // var googlePlacesService = new window.google.maps.places.PlacesService(document.getElementById("endereco"));
                            // googlePlacesService.getDetails({
                            //     reference: predictions[0].reference
                            // }, function(details, status){
                            //     if(details){
                            //         location = details.geometry.location.toString();
                            //     }
                            // });
                            // description = predictions[0].description;
                        }
                    });


                }).catch(error => console.log(error));
        },
        filterAdress() {
            console.log("teste")
            // console.log("teste", this.selectedAdress.description, document.getElementById("endereco"))


            // var location;
            var lat;
            var lng;

            let self = this;
            loader
                .load()
                .then((google) => {


                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ 'address': self.selectedAdress.description }, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            // console.log("results", results[0].geometry.location.toString(), results[0].geometry.location.lat());
                            // location = results[0].geometry.location.toString();
                            lat = results[0].geometry.location.lat();
                            lng = results[0].geometry.location.lng();

                            self.marker_endereco[0].position = { lat: lat, lng: lng };

                            self.center.lat = parseFloat(lat);
                            self.center.lng = parseFloat(lng);
                            self.showInfoWindow2 = true;

                            console.log('coords', lat, lng)
                        } else {
                            console.log("nao encontrou");
                        }
                    });

                    // var googlePlacesService = new window.google.maps.places.PlacesService(self.selectedAdress.description);
                    // googlePlacesService.getDetails({
                    //     reference: self.selectedAdress.reference
                    //     // reference: predictions[0].reference
                    // }, function(details, status){
                    //     if(details){
                    //         location = details.geometry.location.toString();
                    //         arr = location.split(location, ',');
                    //         console.log('arr', arr)
                    //         lat = arr[0];
                    //         lng = arr[1];
                    //     }
                    // });
                    // description = predictions[0].description;




                }).catch(error => console.log(error));
        },
        filterPolylines(itinerary) {
            itinerary.filterItinerary = !itinerary.filterItinerary;
            const selectedItineraries = this.itinerarios
                .filter((itine) => itine.filterItinerary)
                .map((itine) => itine.id);

            this.itinerario_ida = this.itinerario_ida_aux
                .filter((itinerario_ida) => selectedItineraries.includes(itinerario_ida.id_itinerario))
                .map(({ lat, lng, id_itinerario }) => ({ lat, lng, id_itinerario }));

            this.itinerario_volta = this.itinerario_volta_aux
                .filter((itinerario_volta) => selectedItineraries.includes(itinerario_volta.id_itinerario))
                .map(({ lat, lng, id_itinerario }) => ({ lat, lng, id_itinerario }));
        }
    },
}
</script>