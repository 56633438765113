<template>
    <div class="modal fade " id="novaEscala" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <!-- <div class="modal-dialog modal-dialog-centered"></div> -->
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered ">
            <div class="modal-content" style="background-color: #292994; border-radius: 20px;">
                <div class="modal-header custom-background-green-light" align="center">
                    <h5 class="title-font-white custom-color-text-blue" style="margin: auto">Edição de Escala Padrão</h5>
                </div>
                <div class="modal-body" style="padding:none; overflow: hidden;">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-8">
                            <label class="custom-label-nova-escala w-100">
                                <input type="search" name="buscar" id="buscar" class="form-control w-100">
                            </label>
                        </div>
                        <div class="col-2">
                            <button class="btn btn-light pull-left" style="color: grey; font-weight: bold;">Buscar</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <hr style="width: 100%; background: #17175F;">
                </div>
                <div class="row" id="style-scroll-escala-padrao"
                    style="max-height: 600px; overflow-y: auto; margin-left: 1%; width: 97%; margin-bottom: 1%;">
                    <div class="col-md-10 offset-md-1">
                        <table class="table custom-table-padrao">
                            <thead style="border: none">
                                <tr style="border: none; font-size: 20px">
                                    <th class="title-font text-weight" scope="col">Nome da Linha</th>
                                    <th class="title-font text-weight" scope="col">Tipo de Dia</th>
                                    <th class="title-font text-weight" scope="col">Status</th>
                                    <th class="title-font text-weight" scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="escalas in escalasPadrao" :key="escalas.id">
                                    <th scope="row" class="text-font td-custom-nova-escala">{{ escalas.nome_fantasia }}</th>
                                    <td class="text-font td-custom-nova-escala">{{ escalas.tipo_dia }}</td>
                                    <td class="text-font td-custom-nova-escala" @click="updateTrip(escalas)">{{
                                        escalas.status == 1 ? 'Ativo' : 'Inativo'
                                    }}</td>
                                    <td class="td-custom-nova-escala">
                                        <button class="btn-panel-sgv-edit" @click="editTrip(escalas)">
                                            <svg width="16" height="16" viewBox="0 0 36 36" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M35.4375 8.0625L31.7812 11.7188L24.2812 4.21875L27.9375 0.5625C28.3125 0.1875 28.7812 0 29.3438 0C29.9062 0 30.375 0.1875 30.75 0.5625L35.4375 5.25C35.8125 5.625 36 6.09375 36 6.65625C36 7.21875 35.8125 7.6875 35.4375 8.0625ZM0 28.5L22.125 6.375L29.625 13.875L7.5 36H0V28.5Z"
                                                    fill="#E4EBF3" />
                                            </svg>
                                        </button>
                                        <button class="btn-panel-sgv-edit" @click="excluirTrip(escalas)">
                                            <svg width="13" height="16" viewBox="0 0 13 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.3203 1.32031V3H0.679688V1.32031H3.57031L4.42969 0.5H8.57031L9.42969 1.32031H12.3203ZM1.5 13.8203V3.82031H11.5V13.8203C11.5 14.263 11.3307 14.6536 10.9922 14.9922C10.6536 15.3307 10.263 15.5 9.82031 15.5H3.17969C2.73698 15.5 2.34635 15.3307 2.00781 14.9922C1.66927 14.6536 1.5 14.263 1.5 13.8203Z"
                                                    fill="white" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './../../../../assets/css/operacoes/escala-padrao/nova-escala.css';
import http from '../../../../services/http.js';

import { ref, onMounted } from "vue";

const myMapRef = ref();
const mapPolygon = ref();

export default {
    name: 'NovaEscala',
    data() {
        return {
            orgid: null,
            escalasPadrao: []
        };
    },
    mounted() {

        this.emitter.on('openNovaEscala', data => {
            this.orgid = data.orgid;
            $('#novaEscala').modal('show');
            this.getEscalasPadrao();
        })
    },
    methods: {
        getEscalasPadrao() {
            http.get('/scales', {
                params: {
                    orgid: this.orgid
                }
            }).then(res => {
                this.escalasPadrao = res.data.escalas;
            }).catch(err => console.error(err));
        },
        editTrip(objectTrip) {
            var linhas = [];
            linhas.push(objectTrip.linha_id);

            http.get('scales/selected/lines/standard', {
                params: {
                    orgid: this.orgid,
                    data: this.data,
                    id: objectTrip.id
                }
            }).then(response => {
                this.emitter.emit('ViagensLinhas', [response.data, this.orgid, this.data, linhas, true]);
            }).catch(error => console.log(error));
        },
        excluirTrip(objectTrip) {
            console.log("objectTrip", this.orgid)
            http.post('scales/delete/', { orgid: this.orgid, escala_id: objectTrip.id }
            ).then(response => {
                if (response.data.status) {
                    var num = 0;
                    this.escalasPadrao.forEach(e => {
                        if (e.id == objectTrip.id) this.escalasPadrao.splice(num, 1);
                        num += 1;
                    });
                    this.getEscalasPadrao()
                    this.$toast.success(`Excluído com sucesso!`);
                } else this.$toast.error(`Não foi possível excluir a escala padrão.`);
            }).catch(error => console.log(error));
        },
        updateTrip(escala) {
            let status;
            if (escala.status == 0){status = true}else{status = false}
            http.post('scales/update', { orgid: this.orgid, escala_id: escala.id, status: status }
            ).then(response => {
                this.getEscalasPadrao()
                this.$toast.success(`Status editado com sucesso!`);
                console.log("response", response.data);
            }).catch(error => console.log(error));
        }
    },
}
</script>